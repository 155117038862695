import { Form, Select } from "antd";

type OptionProps = {
  label: string;
  value: string;
};

type Props = {
  onChange?: any;
  label: string;
  name: string;
  disabled?: boolean;
  children?: React.ReactNode;
  option?: OptionProps[];
  mode?: "multiple" | "tags";
  required?: boolean;
};

export const SelectContainer = (props: Props) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 24 }}
        label={props?.label}
        name={props?.name}
        rules={[
          {
            required: props?.required,
            message: `${props?.label} is required`,
          },
        ]}
      >
        <Select
          className="w-full"
          showSearch
          mode={props?.mode}
          placeholder={props?.label}
          onChange={props?.onChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          options={props.option || []}
          disabled={props?.disabled}
        ></Select>
      </Form.Item>
    </>
  );
};
