import { Bg, ButtonIcon, CSVModal, TablePagination } from "@components";
import { IoReturnUpBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { NoData, Separator, Span } from "@common";
import { TotalList } from "./TotalList";
import { IoMdDownload } from "@icons";
import { useEffect } from "react";

type Props = {
  columns: any;
  title: string;
  tableName: string;
  handleBack: () => void;
  handleOpenCSV?: () => void;
  isModalCSV: boolean;
  setIsModalCSV: Function;
  headers: any;
  csvDetails?: any;
};

export const ReportList = (prop: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const data = useSelector(
    (state: RootState) => state.Table.tables[prop?.tableName].data
  );

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: prop?.tableName,
      value: 1,
    });
  }, []);

  return (
    <>
      <div className="main">
        <div className="flex flex-col item-center justify-between">
          <Span weight="extrabold" size="4xl">
            {prop?.title}
          </Span>
          <Separator orientation="horizontal" />

          <div className="flex flex-col">
            <div className="w-full flex justify-end gap-4">
              <ButtonIcon
                label="Return to Selection"
                icon={<IoReturnUpBack size={20} />}
                onClick={prop?.handleBack}
              />

              <ButtonIcon
                label="Export CSV"
                icon={<IoMdDownload size={20} />}
                onClick={prop?.handleOpenCSV}
              />
            </div>

            <TotalList tableName={prop?.tableName} />

            {data?.results?.length > 0 ? (
              <TablePagination
                columns={prop?.columns}
                data={data?.results}
                dataType={prop?.tableName}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <CSVModal
        title={prop?.title}
        modalOpen={prop?.isModalCSV}
        cancelEvent={() => prop?.setIsModalCSV(false)}
        headers={prop?.headers}
        csvDetails={prop?.csvDetails}
        subtitle={prop?.title}
      />
    </>
  );
};
