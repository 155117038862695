import {
    BiSortDown,
    BiSortUp, 
  } from "@icons";
  import { useTable, useSortBy, usePagination } from "react-table";
  import "./Table.scss"; 
  import { Dispatch } from "@store";
  import { useDispatch } from "react-redux";
  import { useEffect } from "react"; 
  
  type Props = {
    data: any;
    columns: any; 
  };
  
  export const NoPaginationTable = ({
    columns,
    data, 
  }: Props) => { 
    const dispatch = useDispatch<Dispatch>();
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups, 
      page, 
      prepareRow, 
    } = tableInstance; 
  
    useEffect(() => {
      dispatch.Table.updateState({ isLoading: false });
    }, [data]);
   
  
    return (
      <>
        <div className="table-content">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div className="flex whitespace-nowrap font-semibold font-bold">
                        <span>{column.render("Header")}</span>
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BiSortDown className="text-lg mx-2" />
                            ) : (
                              <BiSortUp className="text-lg mx-2" />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
  
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row); 
                return (
                  <tr {...row.getRowProps()} className="cursor-pointer">
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="text-start text-sm p-4"
                        >
                          <span>{cell.render("Cell")}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> 
      </>
    );
  };
  