import { Modal } from "antd"; 

type Props = {
    title: string
    modalOpen: boolean
    clickEvent?: () => void
    cancelEvent?: () => void
    loading?: boolean 
    children: React.ReactNode;
}

export const ModalContent = (props: Props) => {

  return (  
    <Modal 
      title={props.title}
      open={props.modalOpen}
      onCancel={props.cancelEvent} 
      footer={null} 
      width={900}
    > 
      {props.children} 
    </Modal>
  );
}