export const COLUMNS = [
  {
    accessor: "reference_number",
    Header: "Reference Number",
  },
  {
    accessor: "account_number",
    Header: "Account Number",
  },
  {
    accessor: "pos_code",
    Header: "POS Code",
  },
  {
    accessor: "branch_code",
    Header: "Branch Code",
  },
  {
    accessor: "company_code",
    Header: "Company Code",
  }, 
  {
    accessor: "tier_id",
    Header: "Tier id",
  },
  
];
