import axios from "axios";
import { store } from "@store";

const logout = async () => {
  store.dispatch.User.logout();
  store.dispatch.UI.setAlert({ message: "Session expired.", type: "error" });
};

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401) {
      logout();
      throw err;
    }

    throw err?.response?.data?.error ?? "error";
  }
);

const getUrl = (endpoint) => {
  const url =
    endpoint.indexOf("http://") === 0 || endpoint.indexOf("https://") === 0
      ? endpoint
      : process.env.REACT_APP_API_BASE_URL + endpoint;

  return url;
};

export const constructHeader = (token = store.getState().User.userToken) => {
  return token
    ? { Authorization: "Bearer " + token, "x-auth-token": `${token}` }
    : {};
};

const coreFunc = (verb, endpoint, config = {}, data = null) => {
  config.headers = {
    ...config.headers,
    ...constructHeader(),
  };

  if (data !== null) {
    return axios[verb](getUrl(endpoint), data, config);
  }
  return axios[verb](getUrl(endpoint), config);
};

export const post = async (endpoint, data, headers = {}) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const patch = async (endpoint, data, headers = {}) =>
  coreFunc(
    "patch",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const put = async (endpoint, data, headers = {}) =>
  coreFunc(
    "put",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const get = async (endpoint, headers = {}) =>
  coreFunc("get", endpoint, {
    headers: { "Content-Type": "application/json", ...headers },
  });

export const remove = async (endpoint, data, headers = {}) =>
  coreFunc(
    "delete",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const postMultipart = async (endpoint, data) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "multipart/form-data" } },
    data
  );

export const putMultipart = async (endpoint, data) =>
  coreFunc(
    "put",
    endpoint,
    { headers: { "Content-Type": "multipart/form-data" } },
    data
  );
