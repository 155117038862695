import {
  FormInput,
  FormNumber,
  SelectBusinessUnit,
  UploadPhoto,
} from "@components";
import { useState } from "react";
import { Button, Drawer, Form, Popconfirm, Space, Switch } from "antd";

import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import TextArea from "antd/es/input/TextArea";
import { formatDateDash } from "@constant";
import { IContentVoucher } from "src/types/Content";
import { addContent } from "@api";
import { Span } from "@common";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const VoucherAdd = ({ openModal, setOpenModal, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    title: "",
    description: "",
    points: 0,
    company_code: "",
    banner_type: "vouchers",
    is_active: true,
  };

  const [voucherDetails, setVoucherDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);
  const [file, setFile] = useState() as any;

  const handleClose = () => {
    setOpenModal(false);
    setisloading(false);
    setVoucherDetails(initialState);
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setVoucherDetails({
      ...voucherDetails,
      [name]: value,
    });
  };

  const handeAddVoucher = async () => {
    try {
      setisloading(true);

      const formData = await handleFormData(voucherDetails, file);

      const res = await addContent(formData);
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new business!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new promo!",
        type: "Error",
      });
      setisloading(false);
    }
    handleClose();
  };

  const handleFile = async (data: any) => {
    setFile(data);
  };

  const handleFormData = async (
    voucherDetails: IContentVoucher,
    file: Blob
  ) => {
    const formData = new FormData();

    formData.append("file", file);
    const offerItem = {
      ...voucherDetails,
    };

    for (let key in offerItem) {
      formData.append(`${key}`, offerItem[key]);
    }
    return formData;
  };

  return (
    <Drawer
      title="Add Voucher"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in point of sales?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeAddVoucher();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <UploadPhoto setFile={handleFile} openModal={openModal} />

        <FormInput
          label="Title"
          name="title"
          required={true}
          onChange={onChange}
        />

        <Form.Item
          name="description"
          label="Description"
          className="w-full"
          rules={[
            {
              required: true,
              message: "Description Type is required",
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            onChange={(v: any) =>
              setVoucherDetails({
                ...voucherDetails,
                description: v.target.value.trim(),
              })
            }
          />
        </Form.Item>

        <SelectBusinessUnit
          onChange={(v: any) =>
            setVoucherDetails({
              ...voucherDetails,
              company_code: v,
            })
          }
          all={false}
          required={false}
        />

        <FormNumber
          label="Points"
          name="points"
          required={true}
          wrapperCol={{ span: 24 }}
          maxLength={3}
          onChange={(v: number) => {
            setVoucherDetails({ ...voucherDetails, points: v });
          }}
        />

<div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
          <Span weight="semi" margin="y-auto">
            Status
          </Span>
          <Form.Item
            style={{margin: 0}}
            name="is_active"
            valuePropName={voucherDetails?.is_active ? "checked" : ""}
          >
             <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={voucherDetails.is_active}
              onChange={(v) =>
                setVoucherDetails({ ...voucherDetails, is_active: v })
            }/> 
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
