import { Form, Input } from "antd";

type Props = { 
  onChange?: any;
  name?: string;
  label?: string;
  required?: boolean; 
  type?: string;
  addonBefore?: string;
  wrapperCol?: any;
  maxLength?: any;
  onKeyPress?: any;
  disabled?: boolean
};

export const FormInput = ({   
  onChange,
  name,
  label,
  required, 
  type,
  addonBefore,
  wrapperCol,
  maxLength,
  onKeyPress,
  disabled
}: Props) => {
  return (
    <>
    <Form.Item 
      label={label} 
      name={name} 
      rules={[{ 
        required: required,
        message: `${label} is required`,
      }]}
      wrapperCol={wrapperCol}
    >
      <Input 
        addonBefore={addonBefore}
        placeholder={label} 
        name={name} 
        onChange={onChange} 
        type={type}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
    </Form.Item></>
  );
};