import { Span } from "@common";
import { DeleteModal, FormInput, Trees } from "@components";
import {
  Button,
  Drawer,
  Form,
  Popconfirm,
  Space,
  Switch,
  TreeProps,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { deleteRole, getRoleById, updateRole } from "@api";
import { ArrayToObject } from "@constant";
import { Permission } from "@types";

type Props = {
  id: string;
  getData: Function;
};

export const RoleEdit = ({ id, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const userInfo = useSelector((state: RootState) => state.User.userInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [accessData, setAccessData] = useState([] as any);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

  const [roleDetails, setRoleDetails] = useState<any>({});

  const [isModalDelete, setIsModalDelete] = useState(false);

  const handleClose = () => {
    setIsLoading(false);
    dispatch.UI.updateFormId({ target: "editRole", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setRoleDetails({
      ...roleDetails,
      [name]: value,
    });
  };

  const getRoleData = async () => {
    setIsLoading(true);
    try {
      const res = await getRoleById(id);
      const details = {
        role_name: res?.role_name,
        role_description: res?.role_description,
        role_status: res?.role_status,
      };
      setRoleDetails(details);
      const access_group = res?.access_group_id ? res.access_group_id : [];
      setPermissions(access_group);
      form.setFieldsValue(details);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRoleData();
  }, [id]);

  const onCheck: TreeProps["onCheck"] = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue as React.Key[]);
  };

  useEffect(() => {
    const keysWithTrue = Object.keys(permissions).filter(
      (key) => permissions[key] === true
    );
    setCheckedKeys(keysWithTrue);
  }, [permissions]);

  useEffect(() => {
    const accessArray = Object.entries(permissions).map(([key, value]) => ({
      [key]: value,
    }));
    const updatedPermissions = accessArray.map((permission) => {
      const key = Object.keys(permission)[0];
      return { [key]: checkedKeys.includes(key) };
    });

    setAccessData(updatedPermissions);
  }, [checkedKeys]);

  const handeUpdateRole = async () => {
    try {
      setIsLoading(true);
      const filteredAccess = accessData.filter(
        (obj) => !obj.hasOwnProperty("id")
      );
      const permissionsObject = ArrayToObject(filteredAccess);
      const res = await updateRole(id, {
        ...roleDetails,
        access: permissionsObject,
        adminId: userInfo?.id,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const handleDelete = async () => {
    try {
      const result = await deleteRole(id, userInfo?.id);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delet!",
        type: "Error",
      });
    }
    handleClose();
  };
  const readOnly = !userInfo?.role_name?.access_group_id?.role_update;

  return (
    <>
      <Drawer
        title={readOnly ? "View Role" : "Edit Role"}
        placement="right"
        width={500}
        onClose={handleClose}
        open={id ? true : false}
        footer={
          readOnly ? null : (
            <div className="flex justify-center">
              <Space>
                <Popconfirm
                  placement="top"
                  title="Discard changes made in user administration?"
                  onConfirm={handleClose}
                  okText="Discard"
                  cancelText="Cancel"
                >
                  <Button loading={isLoading}>Cancel</Button>
                </Popconfirm>
                {userInfo?.role_name?.access_group_id?.role_update && (
                  <Button
                    onClick={form.submit}
                    type="primary"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                )}
              </Space>
            </div>
          )
        }
      >
        <Form
          onFinish={() => {
            form.resetFields();
            handeUpdateRole();
          }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Role Name"
            name="role_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Description"
            name="role_description"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />

          <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
            <Span weight="semi" margin="y-auto">
              Status
            </Span>

            <Form.Item
              style={{ margin: 0 }}
              name="role_status"
              valuePropName={roleDetails?.role_status ? "checked" : ""}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={(v) =>
                  setRoleDetails({
                    ...roleDetails,
                    role_status: v,
                  })
                }
                disabled={readOnly}
              />
            </Form.Item>
          </div>

          <Trees
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            disabled={readOnly}
          />

          {userInfo?.role_name?.access_group_id?.role_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Role"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={isLoading}
        okLabel="Delete"
        subtitle={roleDetails?.role_name}
      />
    </>
  );
};
