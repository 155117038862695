import { GridContainer, Separator, Span } from "@common";
import { Loader, TransactionLabel } from "@components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Collapse } from "@mui/material";
import { formatDateTime } from "@constant";
import { useState } from "react";
import React from "react";

type Props = {
  title: string;
  data: any;
  isLoading: boolean;
};

export const TransactionDetails = ({ title, data, isLoading }: Props) => {
  const [transaction, setTransaction] = useState(true);
  const [additional, setAdditional] = useState(true);
  const [item, setItem] = useState(true);

  const toggleTransaction = (e: any) => {
    e.preventDefault();
    setTransaction(!transaction);
  };

  const toggleAdditional = (e: any) => {
    e.preventDefault();
    setAdditional(!additional);
  };

  const toggleItem = (e: any) => {
    e.preventDefault();
    setItem(!item);
  };

  const earn = data?.type === "earn"
  const redeem = data?.type === "redeem"
  const refund = data?.type === "refund"
  const adjustment = data?.type === "adjustment"
  const redeem_earn = earn || redeem;
  const store = title === "Store" 

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <Span weight="bold" size="2xl">
          {title} Details
        </Span>
        <div className="my-auto">
          <Span weight="semi" size="lg" color="gray-primary">
            Reference Number:
          </Span>
          <Span weight="semi" size="xl">
            &nbsp; {data?.reference_number}
          </Span>
        </div>

        <Separator orientation="horizontal" />

        <div>
          <div
            className="flex justify-between py-4 cursor-pointer"
            onClick={toggleTransaction}
          >
            <div className="flex my-auto">
              {transaction ? (
                <IoIosArrowUp className="mr-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowDown className="mr-4 my-auto text-2xl" />
              )}
              <Span weight="semi" size="xl">
                Transaction Details
              </Span>
            </div>
          </div>

          <Collapse in={transaction} className="py-2">
            <div className="pb-4">
              <GridContainer grid="five">
                <TransactionLabel
                  title="Reference Number"
                  label={data?.reference_number}
                />
                <TransactionLabel
                  title="Account Number"
                  label={data?.account_number}
                />
                {title !== "Store" && <TransactionLabel title="Type" label={data?.type} /> }
                <TransactionLabel
                  title="Transaction Date"
                  label={formatDateTime(data?.timestamp)}
                />
                <TransactionLabel
                  title="Tier Name"
                  label={data?.tier?.tier_group_name}
                />
                <TransactionLabel
                  title="POS Code"
                  label={data?.pos?.pos_code}
                />
                <TransactionLabel
                  title="Company Name"
                  label={data?.company?.company_name}
                />
                <TransactionLabel
                  title="Branch Name"
                  label={data?.branch?.branch_name}
                />
                <TransactionLabel
                  title="Branch Address"
                  label={data?.branch?.address}
                  classes="flex grid grid-cols-2"
                />
              </GridContainer>
            </div>
          </Collapse>
        </div>

        <div>
          <div
            className="flex justify-between py-4 cursor-pointer"
            onClick={toggleItem}
          >
            <div className="flex my-auto">
              {item ? (
                <IoIosArrowUp className="mr-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowDown className="mr-4 my-auto text-2xl" />
              )}
              <Span weight="semi" size="xl">
                Item Details
              </Span>
            </div>
          </div>

          <Collapse in={item} className="py-2">
            <div className="pb-4">
              <table className="transaction-table">
                <tr>
                  <th>
                    <Span
                      weight="semi"
                      color="gray-primary"
                      transform="uppercase"
                    >
                      Item
                    </Span>
                  </th>
                  <th>
                    {" "}
                    <Span
                      weight="semi"
                      color="gray-primary"
                      transform="uppercase"
                    >
                      SKU
                    </Span>
                  </th>
                  <th>
                    <Span
                      weight="semi"
                      color="gray-primary"
                      transform="uppercase"
                    >
                      Item Quantity
                    </Span>
                  </th>
                  <th>
                    <Span
                      weight="semi"
                      color="gray-primary"
                      transform="uppercase"
                    >
                      Item Price
                    </Span>
                  </th>
                  <th>
                    <Span
                      weight="semi"
                      color="gray-primary"
                      transform="uppercase"
                    >
                      Item Total Price
                    </Span>
                  </th>
                </tr>

                {data?.items?.map((a: any) => (
                  <tr>
                    <td>
                      <Span weight="semi" transform="capitalize">
                        {a?.item_brand}
                      </Span>
                    </td>
                    <td>
                      <Span weight="semi" transform="capitalize">
                        {a?.item_sku}
                      </Span>
                    </td>
                    <td>
                      <Span weight="semi" transform="capitalize">
                        {a?.item_quantity}
                      </Span>
                    </td>
                    <td>
                      <Span weight="semi" transform="capitalize">
                        {a?.item_price}
                      </Span>
                    </td>
                    <td>
                      <Span weight="semi" transform="capitalize">
                        {a?.item_total}
                      </Span>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Collapse>
        </div>

        <div className="mb-8">
          <div
            className="flex justify-between py-4 cursor-pointer"
            onClick={toggleAdditional}
          >
            <div className="flex my-auto">
              {additional ? (
                <IoIosArrowUp className="mr-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowDown className="mr-4 my-auto text-2xl" />
              )}
              <Span weight="semi" size="xl">
                Additional Details
              </Span>
            </div>
          </div>

          <Collapse in={additional} className="py-2">
            <div className="pb-4">
              <GridContainer grid="five">
                <TransactionLabel
                  title="Total Amount"
                  label={data?.total_amount}
                />
                <TransactionLabel
                  title="Total Item Quantity"
                  label={data?.items?.length}
                />

                {earn || adjustment ? (
                  <TransactionLabel
                    title="Points Earned"
                    label={data?.points_earned}
                  />
                ) : (
                  <></>
                )}

                {earn || store ? (
                  <TransactionLabel
                    title="Promo Code"
                    label={data?.promo_code}
                  />
                ): <></>}

                {redeem || refund || store ? (
                  <>
                    <TransactionLabel
                      title="Redeem Code"
                      label={data?.redeem_code}
                    />

                    <TransactionLabel
                      title="Redeem Amount"
                      label={data?.redeem_amount}
                    />
                  </>
                ) : (
                  <></>
                )}

                {refund && (
                  <TransactionLabel title="Refund" label={data?.refund} />
                )}

                {redeem_earn || store ? (
                  <>
                    <div className="flex flex-col w-max my-2">
                      <Span
                        weight="semi"
                        color="gray-primary"
                        transform="uppercase"
                      >
                        AP Code
                      </Span>
                      {data?.ap_code?.map((a, index) => (
                        <Span weight="semi" transform="capitalize" key={index}>
                          {a}
                          {data?.ap_code?.length > 1 && ","}
                        </Span>
                      ))}
                    </div>

                    <TransactionLabel
                      title="AP Earned"
                      label={data?.ap_earned}
                    />
                  </>
                ): <></>}

                {adjustment && (
                  <TransactionLabel
                    title="Adjustment"
                    label={data?.adjustment}
                  />
                )}

                {redeem_earn || store ? (
                  <TransactionLabel title="Status" label={data?.status} />
                ): <></>}

                <TransactionLabel title="Remarks" label={data?.remarks} />
              </GridContainer>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};
