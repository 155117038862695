import { CenterContent, Separator, Button, GridContainer, Span } from "@common";
import { 
  FormInput,
  Loader,
  SelectBranch,
  SelectBusinessUnit,
} from "@components";
import React from "react";
import { DatePicker, Form } from "antd"; 

type Props = {
  handleReport: () => void;
  isLoading: boolean;
  setReportsDetails: any;
  reportsDetails: any;
  title: string;
  IsAccountNumber?: boolean;
};

export const ReportForm = ({
  title,
  handleReport,
  isLoading,
  setReportsDetails,
  reportsDetails,
  IsAccountNumber
}: Props) => {
  const [form]: any = Form.useForm();
  const valid =
    !reportsDetails?.company_code ||
    !reportsDetails?.start_date ||
    !reportsDetails?.end_date;

  const handleBusiness = (v: any) => {
    if (v === "all") {
      form.setFieldsValue({ branch_code: ["all"] });
      setReportsDetails({
        ...reportsDetails,
        company_code: v,
        branch_code: ["all"],
      });
    } else {
      form.setFieldsValue({ branch_code: [] });
      setReportsDetails({ ...reportsDetails, company_code: v });
    }
  };

  const handleBranch = (v: any) => {
    const include = v.includes("all");
    if (include) {
      form.setFieldsValue({ branch_code: ["all"] });
      setReportsDetails({ ...reportsDetails, branch_code: ["all"] });
    } else {
      setReportsDetails({ ...reportsDetails, branch_code: v });
    }
  };

  return (
    <React.Fragment>
      <div className="main">
        <div className="flex flex-col item-center justify-between">
          <Span weight="extrabold" size="4xl">
            {title} Report
          </Span>
          <Separator orientation="horizontal" classname="my-6" />
          <Loader isLoading={isLoading} />
          <div className="flex flex-col p-4">
            <span className="flex flex-col gap-3">
              <h5 className="text-lg font-bold tracking-wide">
                Report Selection
              </h5>
              <p className="text-sm font-normal tracking-wide">
                Set the report to export.
              </p>
            </span>
            <div className="mt-5">
              <CenterContent bg="lightblue">
                <div className="pt-10 px-8 pb-4 w-full">
                  <div className="flex flex-col items-center">
                    <h5 className="text-xl font-semibold">
                      {title} Report Form
                    </h5>
                    <p>Set the value on each fields to get the desired data.</p>
                  </div>
                  <Separator orientation="horizontal" />
                  <Form
                    onFinish={() => {
                      form.resetFields();
                      handleReport();
                    }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    form={form}
                  >
                    <GridContainer
                      grid={IsAccountNumber ? "three" : "four"}
                      gap="gapFour"
                    >
                      {IsAccountNumber ? (
                         <>
                          <FormInput
                            label="Account Number"
                            name="account_number"
                            required={true}
                            type="email"
                            onChange={(e: any) =>
                              setReportsDetails({
                                ...reportsDetails,
                                account_number: e.target.value,
                              })
                            }
                          />
                        </> 
                      ) : (
                       <>
                          <SelectBusinessUnit
                            onChange={(v: any) => handleBusiness(v)}
                            all={true}
                            required={true}
                          />

                          <SelectBranch
                            onChange={(v: any) => handleBranch(v)}
                            companyCode={reportsDetails?.company_code}
                            disabled={
                              reportsDetails?.company_code === "all" ||
                              !reportsDetails?.company_code
                            }
                          />
                        </>
                      )}
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Start Date"
                        name="start_date"
                        rules={[
                          { required: true, message: `Start Date is required` },
                        ]}
                      >
                        <DatePicker
                          inputReadOnly={true}
                          className="w-full"
                          onChange={(e: any) =>
                            setReportsDetails({
                              ...reportsDetails,
                              start_date: e,
                            })
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="End Date"
                        name="end_date"
                        rules={[
                          { required: true, message: `End Date is required` },
                        ]}
                      >
                        <DatePicker
                          name="date"
                          inputReadOnly={true}
                          className="w-full"
                          onChange={(e: any) =>
                            setReportsDetails({
                              ...reportsDetails,
                              end_date: e,
                            })
                          }
                        />
                      </Form.Item>
                    </GridContainer>
                  </Form>

                  <div className="w-full flex items-center justify-center pt-2">
                    <Button
                      width="none"
                      padding="px-6"
                      onClick={form.submit}
                      // disabled={valid}
                    >
                      Show Report
                    </Button>
                  </div>
                </div>
              </CenterContent>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
