import { HHMMA } from "@constant";
import { DatePicker, Form } from "antd";

type Props = { 
  onChange?: any;
  name?: string;
  label?: string;
  required?: boolean;
  picker?: any;
  format?: string;
  value?: any;
  defaultValue?: any;
  disabled?: boolean;
};

export const FormDatePicker = (props: Props) => {
  return (
    <> 
    <Form.Item  
      label={props?.label} 
      name={props?.name} 
      rules={[{ 
        required: props?.required,
        message: `${props?.label} is required`,
      }]
    }>
        <DatePicker
            value={props?.value}
            defaultValue={props?.defaultValue}
            name={props?.name}  
            onChange={props?.onChange}
            className="w-full"
            placeholder={props?.label} 
            picker={props?.picker}
            format={props?.format} 
            disabled={props?.disabled}
        />
    </Form.Item>
    </>
  );
};