import { ReportList } from "@components";
import { COLUMNS, HEADERS_ACOUNT_REPORT } from "../Account/Table/columns";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { getAccountReportsCSV, getAccountReportsData } from "@api";
import { formatDateTime, formatDecimal } from "@constant";

export const AccountReportsListPage = () => {
  const column = useMemo(() => COLUMNS, []);
  const tableName = "account_reports";
  const history = useHistory();
  const [csvDetails, setCSVDetails] = useState([] as any);
  const [isModalCSV, setIsModalCSV] = useState(false);

  const handleBack = () => {
    history.push(`/reports/account`);
  };

  const handleOpenCSV = () => {
    setIsModalCSV(true);
    // getCSV();
  };

  const keyword = useSelector(
    (state: RootState) => state.Table.tables[tableName].keyword
  );

  const getCSV = async () => {
    try {
      const res = await getAccountReportsCSV(keyword); 
      const newData = res.results?.map((data) => {
        return {
          ...data,
          transaction_id: `${data?.transaction_id}`,
          transaction_action: `${data?.transaction_action}`,
          transaction_points: `${data?.transaction_points}`,
          transaction_branch: `${data?.transaction_branch}`,
          transaction_transaction_amount: `${formatDecimal(
            data?.transaction_transaction_amount
          )}`,
          transaction_timestamp: `${formatDateTime(
            data?.transaction_timestamp
          )}`,
        };
      });
      setCSVDetails(newData);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCSV();
  }, []);

  return (
    <>
      <ReportList
        tableName={tableName}
        handleBack={handleBack}
        columns={column}
        title="Account Transaction Report"
        headers={HEADERS_ACOUNT_REPORT}
        csvDetails={csvDetails}
        handleOpenCSV={handleOpenCSV}
        isModalCSV={isModalCSV}
        setIsModalCSV={setIsModalCSV}
      />
    </>
  );
};
