import { MEMBER_STATUS_TYPE } from "@constant"

export const MemberStatus = ({ member_status }: any) => { 
    const renderDesign = () => {
        switch (member_status) {
            case 'verified': 
                return (
                    <div className='bg-[#f6ffed] border-[#b7eb8f] status-style'>
                       <span className='text-[#389e0d] status-label'>{MEMBER_STATUS_TYPE[member_status]}</span>
                    </div>
                )   
            case 'for_verification': 
                return (
                    <div className='bg-[#feb95725] border-[#f69203] status-style'>
                        <span className='text-[#d67e00] status-label'>{MEMBER_STATUS_TYPE[member_status]}</span>
                    </div>
                )   
            default:
                return (
                    <div className='bg-[#f6ffed] border-[#b7eb8f] status-style'>
                        <span className='text-[#389e0d] status-label'>{MEMBER_STATUS_TYPE[member_status]}</span>
                    </div>
                )
        }
    }
    return (
        renderDesign()
    )
}