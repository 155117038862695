import { NoData, Separator, Span } from "@common";
import {
  ButtonIcon,
  Loader,
  SearchContent,
  TablePagination,
} from "@components";
import { COLUMNS } from "./Table/columnTransaction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { IoReturnUpBack } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { formatDateSlash } from "@constant";

export const ListTransactionPage = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const tableName = "listtransaction";
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
 
  const data = useSelector(
    (state: RootState) => state.Table.tables.listtransaction
  );

  const getData = () => {
    dispatch.Table.getListTransactionByAccount({
      table: tableName,
      id: id,
    });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
  };

  useEffect(() => {
    dispatch.Table.updateKeyword({ table: tableName, value: "" });
    getData();
  }, []);

  const handleBack = () => {
    history.push(`/transaction/account`);
  };

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      `${searchData?.filter}` === "date"
        ? `&start_date=${formatDateSlash(startDate)}&end_date=${formatDateSlash(endDate)}`
        : searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getData();
  };

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "action",
      label: "Type",
    },
    {
      value: "reference_number",
      label: "Reference Number",
    },
    {
      value: "status",
      label: "Status",
    },
    {
      value: "date",
      label: "Date",
    },
  ];

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []); 

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <Span weight="bold" size="2xl">
          List of Transaction
        </Span>

        <div className="my-auto">
          <Span weight="semi" size="lg" color="gray-primary">
            Account Number:
          </Span>
          <Span weight="semi" size="xl">
            &nbsp; {id}
          </Span>
        </div>

        <Separator orientation="horizontal" />

        <div className="flex justify-between">
          <ButtonIcon
            label="Return to Account"
            icon={<IoReturnUpBack size={20} />}
            onClick={handleBack}
          />
          <div className="flex justify-end w-3/6">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>

        {data?.totalRecords > 0 ? (
          <TablePagination
            columns={COLUMNS}
            data={data?.data}
            dataType={tableName}
            accountNumber={id}
          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};
