import { getBranchesByCompany } from "@api";
import { Form, Select } from "antd";
import { useEffect, useState } from "react";

type Props = {
  onChange?: any;
  companyCode: string;
  disabled?: boolean;
};

export const SelectBranch = ({ onChange, companyCode, disabled }: Props) => {
  const [branchCodeDetails, setBranchCodeDetails] = useState([] as any);
  const getBranchCodeData = async () => {
    try {
      const res = await getBranchesByCompany(companyCode);
      setBranchCodeDetails(res?.results);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (companyCode) {
      getBranchCodeData();
    }
  }, [companyCode]);

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      label="Branch Code"
      name="branch_code"
      rules={[{ required: true, message: `Branch Code is required` }]}
    >
      <Select
        className="w-full"
        showSearch
        mode="multiple"
        placeholder={companyCode === "all" ? "All" : "Branch Code"}
        onChange={onChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={disabled}
      >
        <Select.Option value="all">All</Select.Option>
        {branchCodeDetails.map((type, index) => (
          <Select.Option key={index} value={type.branch_code}>
            {type.branch_code} - {type.branch_name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
