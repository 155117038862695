import * as React from "react";
import classnames from "classnames";

type Props = React.HTMLProps<HTMLInputElement> & {
  border?: "none" | "bottom";
  padding?: "pr-4" | "pl-4" | "px-2";
  margin?: "none";
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "none":
      return "border-none";
    default:
      return "border border-gray-light focus:border-blue-primary";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "px-2":
      return "px-2 py-3";
    case "pl-4":
      return "pr-2 pl-9 py-3";
    case "pr-4":
      return "pl-2 pr-9 py-3";
    default:
      return "px-2 py-2.5";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "none":
      return "m-0";
    default:
      return "mt-2";
  }
};

export const Inputs = ({ border, padding, margin, ...props }: Props) => {
  return (
    <>
      <input
        className={classnames(
          "focus:outline-none text-xs w-full rounded",
          getBorder(border),
          getPadding(padding),
          getMargin(margin)
        )}
        {...props}
      />
    </>
  );
};
