import { SearchContainer } from "@common";
import {
  ACTION_TYPE,
  MEMBER_STATUS,
  STATUS_BOOLEAN,
  TRANSACTION_STATUS,
} from "@constant";
import { Button, DatePicker, Select } from "antd";
import { IoSearchOutline } from "react-icons/io5";

type Props = {
  searchData: any;
  setSearchData: any;
  setFilters: any;
  searchOptions: any;
  setStartDate?: any;
  setEndDate?: any;
};

const { Option } = Select;

export const SearchContent = ({
  searchData,
  setSearchData,
  setFilters,
  searchOptions,
  setStartDate,
  setEndDate,
}: Props) => {
  const onChange = (e) => {
    setSearchData({ ...searchData, value: e.target.value });
  };

  const renderInput = () => {
    const inputProps = {
      className: "w-full text-sm focus:outline-none",
      type: "text",
      value: searchData?.value,
      onChange: (e: any) => onChange(e),
    };

    switch (searchData?.filter) {
      case "keyword":
        return <input {...inputProps} placeholder="Keyword" name="keyword" />;
      case "username":
        return <input {...inputProps} placeholder="Username" name="username" />;
      case "first_name":
        return (
          <input {...inputProps} placeholder="First Name" name="first_name" />
        );
      case "last_name":
        return (
          <input {...inputProps} placeholder="Last Name" name="last_name" />
        );
      case "email":
        return <input {...inputProps} placeholder="Email" name="email" />;
      case "branch_code":
        return (
          <input {...inputProps} placeholder="Branch Code" name="branch_code" />
        );
      case "branch_name":
        return (
          <input {...inputProps} placeholder="Branch Name" name="branch_name" />
        );
      case "createdBy":
        return (
          <input {...inputProps} placeholder="Created By" name="createdBy" />
        );
      case "company_code":
        return (
          <input
            {...inputProps}
            placeholder="Company Code"
            name="company_code"
          />
        );
      case "company_name":
        return (
          <input
            {...inputProps}
            placeholder="Company Name"
            name="company_name"
          />
        );
      case "company_address":
        return (
          <input {...inputProps} placeholder="Address" name="company_address" />
        );
      case "pos_code":
        return <input {...inputProps} placeholder="POS Code" name="pos_code" />;
      case "pos_name":
        return <input {...inputProps} placeholder="POS Name" name="pos_name" />;
      case "branch_code":
        return (
          <input {...inputProps} placeholder="Branch Code" name="branch_code" />
        );
      case "title":
        return <input {...inputProps} placeholder="Title" name="title" />;
      case "promo_code":
        return (
          <input {...inputProps} placeholder="Promo Code" name="promo_code" />
        );
      case "tier":
        return <input {...inputProps} placeholder="Tier" name="tier" />;
      case "tier_group_code":
        return (
          <input
            {...inputProps}
            placeholder="Tier Group Code"
            name="tier_group_code"
          />
        );
      case "group_sequence":
        return (
          <input
            {...inputProps}
            placeholder="Group Sequence"
            name="group_sequence"
          />
        );
      case "reference_number":
        return (
          <input
            {...inputProps}
            placeholder="Reference Number"
            name="reference_number"
          />
        );
      case "account_number":
        return (
          <input
            {...inputProps}
            placeholder="Account Number"
            name="account_number"
          />
        );
      case "is_active":
        return (
          <SelectSearch
            data={STATUS_BOOLEAN}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        );
      case "role_status":
        return (
          <SelectSearch
            data={STATUS_BOOLEAN}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        ); 
      case "role_name":
        return (
          <input {...inputProps} placeholder="Role Name" name="role_name" />
        );
      case "role_status":
        return (
          <SelectSearch
            data={STATUS_BOOLEAN}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        );
      case "member_status":
        return (
          <SelectSearch
            data={MEMBER_STATUS}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        );
      case "status":
        return (
          <SelectSearch
            data={TRANSACTION_STATUS}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        );
      case "action":
        return (
          <SelectSearch
            data={ACTION_TYPE}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        );
      case "date":
        return (
          <>
            <DatePicker
              name="end_date"
              suffixIcon={null}
              inputReadOnly={true}
              placeholder="End Date"
              className="w-full"
              style={{ borderWidth: 0, outline: 0 }}
              onChange={(e: any) => setEndDate(e)}
            />
            <span className="mx-2"> - </span>
            <DatePicker
              name="start_date"
              suffixIcon={null}
              inputReadOnly={true}
              placeholder="Start Date"
              className="w-full "
              style={{ borderWidth: 0 }}
              onChange={(e: any) => setStartDate(e)}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <SearchContainer>
        {renderInput()}

        <Select
          style={{ width: "100%" }}
          bordered={false}
          value={searchData.filter}
          onChange={(v: any) => setSearchData({ filter: v, value: "" })}
        >
          {searchOptions?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </SearchContainer>

      <div className="px-2">
        <Button onClick={setFilters} type="primary" size="large">
          <div className="flex items-center">
            <IoSearchOutline className="my-auto mx-2 text-md items-center inline-flex" />
            Search
          </div>
        </Button>
      </div>
    </>
  );
};

export const SelectSearch = ({ searchData, setSearchData, data }: any) => {
  return (
    <Select
      style={{ width: "100%" }}
      bordered={false}
      value={searchData?.value ? searchData?.value : "Please select"}
      onChange={(v: any) => setSearchData({ ...searchData, value: v })}
    >
      {data?.map((item) => (
        <Option key={item?.value} value={item?.value}>
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};
