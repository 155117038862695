import moment from "moment";
import { useLocation } from "react-router-dom";

export const onlyNumberKey = (evt: any) => {
  const ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    return evt.preventDefault();
  }
  return true;
};

export const formatDateOnly = (date) => {
  const formattedDate = moment(date)
    .set({ hour: 16, minute: 0, second: 0, millisecond: 0 })
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return formattedDate;
};

export const formatTimeOnly = (time) => {
  const startDate = moment(new Date(time));
  const formattedTime = startDate.format("HH:mm");
  return formattedTime;
};

export const formatDate = (time) => {
  const date = formatDateOnly(time);
  const formatDate = date.split("T")[0];
  return formatDate;
};

export const formatDateDash = (d) => {
  const formatDateMM = moment(new Date(d)).format("YYYY-MM-DD");
  return formatDateMM;
};

export const formatDateSlash = (d) => {
  const formatDateS = moment(new Date(d)).format("MM/DD/YYYY");
  return formatDateS;
};

export const formatDateTime = (date, seconds = false) => {
  const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
  const formattedDate = moment(d).format(
    `MM-DD-YYYY, h:mm${seconds ? ":ss" : ""} a`
  );
  return formattedDate;
};

export const formatDecimal = (value = 0) =>
  value ? Number(parseFloat(`${value}`).toFixed(2)).toLocaleString("en") : 0;

export const generateKeyword = (reportsDetails) => {
  const branch = reportsDetails.branch_code;
  let branchOption = "";

  for (let index = 0; index < branch?.length; index++) {
    branchOption += `&branch_code[${index}]=${branch[index]}`;
  }

  let company = `&company_code=${reportsDetails.company_code}`;
  let allBranch = "&branch_code=all";
  let companyBranch = "";

  if (reportsDetails?.account_number) {
    company = `&account_number=${reportsDetails.account_number}`;
  } else {
    if (reportsDetails?.company_code === "all") {
      companyBranch = company + allBranch;
    } else {
      const cb =
        reportsDetails?.branch_code[0] === "all" ? allBranch : branchOption;
      companyBranch = company + cb;
    }
  }

  const key = reportsDetails?.account_number
    ? `&account_number=${reportsDetails.account_number}`
    : companyBranch;

  const keyword = `start_date=${formatDateSlash(
    reportsDetails?.start_date
  )}&end_date=${formatDateSlash(reportsDetails?.end_date)}${key}`;

  return keyword;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation()?.search);
};

export const ArrayToObject = (array) => {
  return array.reduce((acc, v) => {
    const key = Object.keys(v)[0];
    const value = Object.values(v)[0];
    acc[key] = value;
    return acc;
  }, {});
};
