import { RoleManagementPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const RoleManagement = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Promotion");
  });

  return (
    <>
      <RoleManagementPage />
    </>
  );
};
