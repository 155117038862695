import { getCitizenship } from "@api";
import { Form, Select } from "antd";
import { useEffect, useState } from "react";

type Props = { 
  onChange?: any; 
  disabled?: boolean;
};  

export const SelectNationality = (props: Props) => { 
    
  const [citizenshipDetails, setCitizenshipDetails] = useState([] as any); 
  const getCitizenshipData = async () => {
    try { 
      const res = await getCitizenship();   
      setCitizenshipDetails(res?.results); 
    } catch (error) {
      throw error; 
    }
  };  

  useEffect(() => { 
    getCitizenshipData();
  }, []);  
  
  return (  

    <Form.Item
        label="Citizenship" 
        name="citizenship"
        rules={[{ required: true, message: `Citizenship is required` }]}
    >
    <Select
        className="w-full"
        showSearch 
        placeholder="Citizenship" 
        onChange={props?.onChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={props?.disabled}
    >
        {citizenshipDetails.map((type, index) => (
        <Select.Option key={index} value={type.citizenship_name}>
            {type.citizenship_name}
        </Select.Option>
        ))}
    </Select> 
</Form.Item>
  );
};