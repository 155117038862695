import { Input, Space } from "antd";

type Props = {
  value?: string;
  placeholder?: string;
  handleSearch?: any;
  setSearch: Function;
};

const { Search } = Input;

export const SearchInput = ({
  value,
  placeholder,
  handleSearch,
  setSearch,
}: Props) => {
  return (
    <>  
      <Space.Compact size="large">
        <Search
          value={value}
          placeholder={placeholder}
          onSearch={handleSearch}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
          allowClear
          style={{ width: 300 }}
          className="mx-2"
        />
      </Space.Compact>
    </>
  );
};
