import { Strings } from "@constant";
import { EmailNotifPage } from "@containers";
import { useEffect } from "react";

export const EmailNotif = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Account Verification")
  })
  
  return (
    <>
      <EmailNotifPage />
    </>
  );
};
