import { createModel } from "@rematch/core";
import { RootModel } from "."; 
import {getUserById, loginRequest} from "@api";

export interface State {
  customToken: string;
  isUserLoggedIn: boolean;  
  userInfo: any; 
  access: any;
  userToken: string;
  shouldRedirectToLogin: boolean;
}

const initialState = {
  customToken: "",
  isUserLoggedIn: false, 
  shouldRedirectToLogin: false,
  userInfo: null,
  userSignUp: null,
  userToken: "",
  access: null,
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState}
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({ 
    async login({ username, password }) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      try { 
        let res = await loginRequest({
            username,
            password,
          }
        );
 
        const token = res.refreshToken
        const userCredId = res.id

        dispatch.User.updateState({
          userToken: token,
        });
    
        if (token) { 
            const userCredentials = await getUserById(userCredId);  

            dispatch.User.updateState({
                userInfo: userCredentials, 
                access: userCredentials.role_name?.access_group_id
            }); 

            dispatch.User.updateState({
                isUserLoggedIn: true
              });
    
              dispatch.UI.setErrorMsg({
                target: "login",
                value: "",
              });
    
              dispatch.UI.resetForm("login");
              return { isSuccess: true }
        }

      } catch (err: any) { 
        const errMsg = err ?? 'Invalid credentials';
 
        dispatch.UI.setAlert({ message: errMsg, type: 'Error' })

        dispatch.UI.setErrorMsg({
          target: "login",
          value: errMsg,
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    async logout() {
      dispatch.User.resetState();
      dispatch.UI.resetState();  
      dispatch.Table.resetState();  
      dispatch.User.updateState({ shouldRedirectToLogin: true }); 
      localStorage.removeItem('userToken')  
    },
  }),
});
        