import { get, put, post, remove } from "@api";  
import { TiersInfo } from "src/types/TiersInfo";

export const getTiers = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {
    let res = await get(`/account/tiers?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);       
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getTiersSelect = async () => { 
  try {
    let res = await get(`/account/tiers`);       
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const addTiers = async (tiers: TiersInfo) => { 
  try {
    let res = await post(`/account/create-tier`, tiers);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};    

export const updateTiers = async (id: string, tiers: TiersInfo) => { 
  try {
    let res = await put(`/account/update-tier/${id}`, tiers);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const deleteTier = async (tier_id: string) => {
  try{     
    const res = await remove(`/account/delete-tier/${tier_id}`,); 
    return res.data;
  }catch(err){
    throw err;
  }
}   