import {
  BiSortDown,
  BiSortUp,
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronLeft,
  CgPushChevronRight,
} from "@icons";
import { useTable, useSortBy, usePagination } from "react-table";
import "./Table.scss";
import { Button, Span } from "@common";
import { Dispatch, RootState } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

type Props = {
  data: any;
  columns: any;
  dataType?: string;
  accountNumber?: any;
  activeTab?: string;
};

export const TablePagination = ({
  columns,
  data,
  dataType,
  accountNumber,
  activeTab,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    setPageSize,
    state,
    prepareRow,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  const table =
    dataType === "user-profile"
      ? "user"
      : dataType === "business-unit"
      ? "business"
      : dataType === "branch-config"
      ? "branch"
      : dataType === "pos"
      ? "pos"
      : dataType === "promo"
      ? "promo"
      : dataType === "tiers"
      ? "tiers"
      : dataType === "member"
      ? "member"
      : dataType === "store"
      ? "store"
      : dataType === "points"
      ? "points"
      : dataType === "redeem"
      ? "redeem"
      : dataType === "account_reports"
      ? "account_reports"
      : dataType === "account_transaction"
      ? "account_transaction"
      : dataType === "listtransaction"
      ? "listtransaction"
      : dataType === "store_transaction"
      ? "store_transaction"
      : dataType === "manual_adjustment"
      ? "manual_adjustment"
      : dataType === "role"
      ? "role"
      : "";

  const {
    totalRecords,
    page: pageindex,
    limit: pagesize,
  } = useSelector((state: RootState) => state.Table.tables[table]) || {};

  useEffect(() => {
    if (["user"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getUserAdmin({ table });
      }
    }
    if (["business"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getBusinessCompanies({ table });
      }
    }
    if (["branch"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getBranch({ table });
      }
    }
    if (["pos"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getPOS({ table });
      }
    }
    if (["promo"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getPromoList({ table });
      }
    }
    if (["tiers"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getTiersList({ table });
      }
    }
    if (["member"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getMember({ table });
      }
    }
    if (["store"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getStore({ table });
      }
    }
    if (["points"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getPoints({ table });
      }
    }
    if (["redeem"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getRedeemPoints({ table });
      }
    }
    if (["account_reports"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getAccountReports({ table });
      }
    }
    if (["account_transaction"].includes(table)) {
      if (totalRecords) {
        dispatch.Table.getAccountTransaction({ table });
      }
    }
    if (["listtransaction"].includes(table)) {
      // if (totalRecords) {
      dispatch.Table.getListTransactionByAccount({ table, id: accountNumber });
      // }
    }
    if (["store_transaction"].includes(table)) {
      // if (totalRecords) {
      dispatch.Table.getStoreTransaction({ table });
      // }
    }
    if (["manual_adjustment"].includes(table)) {
      if (activeTab) {
        dispatch.Table.getManualAdjustment({ table, activeTab });
      }
    }
    if (["role"].includes(table)) {
      // if (totalRecords) {
      dispatch.Table.getRoleList({ table });
      // }
    }
  }, [totalRecords, pageindex, pagesize]);

  const handleRowClick = (row: any) => {
    switch (dataType) {
      case "user-profile":
        history.push(`/userprofile/${row?.original?.id}`);
        break;
      case "business-unit":
        dispatch.UI.updateFormId({
          target: "editBusinessUnit",
          value: row?.original?.company_code,
        });
        break;
      case "branch-config":
        dispatch.UI.updateFormId({
          target: "editBranch",
          value: row?.original?.branch_code,
        });
        break;
      case "pos":
        dispatch.UI.updateFormId({
          target: "editPOS",
          value: row?.original?.pos_code,
        });
        break;
      case "promo":
        dispatch.UI.updateState({
          promoCode: row?.original?.promo_code,
          promoModal: true,
        });
        break;
      case "tiers":
        dispatch.UI.updateFormId({
          target: "editTiers",
          value: row?.original?.tier_id,
        });
        break;
      case "member":
        dispatch.UI.updateFormId({
          target: "editMember",
          value: row?.original?.user_id,
        });
        break;
      case "account_transaction":
        history.push(`/transaction/accounts/${row?.original?.account_number}`);
        break;
      case "listtransaction":
        dispatch.UI.updateState({
          action: row?.original?.action,
        });
        history.push(`/transaction/details/${row?.original?.reference_number}`);
        break;
      case "store_transaction":
        history.push(
          `/transaction/stores/details/${row?.original?.reference_number}`
        );
        break;
      case "role":
        dispatch.UI.updateFormId({
          target: "editRole",
          value: row?.original?.id,
        });
        break;
      default:
        return "";
    }
  };

  useEffect(() => {
    dispatch.Table.updateState({ isLoading: false });
  }, [data]);

  const page_count = Math.ceil(totalRecords / pageSize);
  const page_index = pageindex;

  useEffect(() => {
    if (page_index > page_count) {
      dispatch.Table.updatePageIndex({ table, value: 1 });
    }
  }, [page_count]);

  const disabled = page_count === 0 || page_index === page_count;

  return (
    <>
      <div className="table-content">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex whitespace-nowrap font-semibold font-bold">
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiSortDown className="text-lg mx-2" />
                          ) : (
                            <BiSortUp className="text-lg mx-2" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const lastRow = row?.cells?.length - 1;
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        onClick={() =>
                          lastRow !== index ? handleRowClick(row) : ""
                        }
                        {...cell.getCellProps()}
                        className="text-start text-sm p-4"
                      >
                        <span>{cell.render("Cell")}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-pagination">
        <div className="mx-2 flex rounded-md">
          <Span margin="auto">Items per page:</Span>
          <select
            className="table-rowsperpage border border-grey-4"
            value={pageSize}
            onChange={(e) => {
              dispatch.Table.updatePageLimit({ table, value: e.target.value });
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div className="flex px-2 ">
          <Button
            bg="white"
            color="black"
            margin="mx-2"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: 1,
              })
            }
            disabled={page_index === 1}
          >
            <CgPushChevronLeft size={16} />
          </Button>

          <Button
            bg="white"
            color="black"
            margin="none"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: pageindex === 1 ? 1 : pageindex - 1,
              })
            }
            disabled={page_index === 1}
          >
            <CgChevronLeft size={16} />
          </Button>
          <div className="my-auto mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
            <Span margin="auto">Page </Span>
            <Span margin="auto" padding="px-2">
              {pageindex} - {pageSize}
            </Span>
            <Span margin="auto"> of {page_count}</Span>
          </div>
          <Button
            bg="white"
            margin="none"
            width="none"
            color="black"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: pageindex + 1,
              })
            }
            disabled={disabled}
          >
            <CgChevronRight size={16} />
          </Button>

          <Button
            bg="white"
            color="black"
            margin="mx-2"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: page_count,
              })
            }
            disabled={page_index === page_count}
          >
            <CgPushChevronRight size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};
