import {
  FormInput,
  FormNumber,
  SelectBusinessUnit,
  UploadPhoto,
} from "@components";
import { useEffect, useState } from "react";
import { Button, Drawer, Form, Popconfirm, Space, Switch } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import TextArea from "antd/es/input/TextArea";
import { formatDateDash } from "@constant";
import { IContentVoucher } from "src/types/Content";
import { addContent, getContentById, updateContent } from "@api";
import { Span } from "@common";

type Props = {
  content_id: string;
  isModalVoucherEdit: boolean;
  setIsModalVoucherEdit: Function;
  getData: Function;
};

export const VoucherEdit = ({
  isModalVoucherEdit,
  setIsModalVoucherEdit,
  getData,
  content_id,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [voucherDetails, setVoucherDetails] = useState({} as IContentVoucher);
  const [isLoading, setisloading] = useState(false);
  const [file, setFile] = useState() as any;

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setIsModalVoucherEdit(false);
    setisloading(false);
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setVoucherDetails({
      ...voucherDetails,
      [name]: value,
    });
  };

  const handleUpdateOffer = async () => {
    try {
      const formData = await handleFormData(voucherDetails, file);
      const res = await updateContent(content_id, formData);
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully updated!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable update offer!",
        type: "Error",
      });
    }
    handleClose();
  };

  const handleFile = async (data: any) => {
    setFile(data);
  };

  const handleFormData = async (
    voucherDetails: IContentVoucher,
    file: Blob
  ) => {
    const formData = new FormData();

    formData.append("file", file);
    const offerItem = {
      ...voucherDetails,
      banner_type: "vouchers",
    };

    for (let key in offerItem) {
      formData.append(`${key}`, offerItem[key]);
    }
    return formData;
  };

  const getOfferData = async () => {
    setisloading(true);
    try {
      const res = await getContentById(content_id);
      const details = {
        title: res?.title,
        description: res?.description,
        company_code: res?.company_code,
        image_path: res?.image_path,
        points: res?.points,
        is_active: res?.is_active,
      };
      setVoucherDetails(details);
      form.setFieldsValue(details);
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getOfferData();
  }, [content_id]);
  
  const readOnly = !userAccess?.content_update ;

  return (
    <Drawer
      title={readOnly ? "View Voucher" : "Edit Voucher"}
      placement="right"
      width={500}
      onClose={handleClose}
      open={isModalVoucherEdit}
      footer={readOnly ? null :
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in point of sales?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            {userAccess?.content_update && (
              <Button onClick={form.submit} type="primary" loading={isLoading}>
                Update
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handleUpdateOffer();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <UploadPhoto
          setFile={handleFile}
          openModal={isModalVoucherEdit}
          defaultImageurl={voucherDetails?.image_path}
          disabled={readOnly}
        />

        <FormInput
          label="Title"
          name="title"
          required={true}
          onChange={onChange}
          disabled={readOnly}
        />

        <Form.Item
          name="description"
          label="Description"
          className="w-full"
          rules={[
            {
              required: true,
              message: "Description Type is required",
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            disabled={readOnly}
            onChange={(v: any) =>
              setVoucherDetails({
                ...voucherDetails,
                description: v.target.value.trim(),
              })
            }
          />
        </Form.Item>

        <SelectBusinessUnit
          onChange={(v: any) =>
            setVoucherDetails({
              ...voucherDetails,
              company_code: v,
            })
          }
          all={false}
          required={false}
          disabled={readOnly}
        />

        <FormNumber
          label="Points"
          name="points"
          required={true}
          wrapperCol={{ span: 24 }}
          maxLength={3}
          onChange={(v: number) => {
            setVoucherDetails({ ...voucherDetails, points: v });
          }}
          disabled={readOnly}
        />

        <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
          <Span weight="semi" margin="y-auto">
            Status
          </Span>
          <Form.Item
            style={{margin: 0}}
            name="is_active"
            valuePropName={voucherDetails?.is_active ? "checked" : ""}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(v) =>
                setVoucherDetails({
                  ...voucherDetails,
                  is_active: v,
                })
              }
              disabled={readOnly}
            />
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
