import { ChangePassword, StatusDesign } from "@components"; 

export const COLUMNS = [
  {
    accessor: "username",
    Header: "User Name", 
  },
  {
    accessor: "first_name",
    Header: "First Name", 
  },
  {
    accessor: "last_name",
    Header: "Last Name", 
  },
  {
    accessor: "email",
    Header: "Email", 
  },
  {
    accessor: "role_name",
    Header: "Role",
    width: 100,  
    Cell: (data) => {
      const role = data?.row?.original?.role_name?.role_name; 
      return (
        <div className="truncate max-w-[200px]">
          <span>{role}</span>
        </div>
      );
    },
  },
  {
    accessor: "is_active",
    Header: "Status", 
    Cell: (data) => {
      const d = data?.row?.original; 
      return <StatusDesign is_active={d?.is_active} />;
    },
  },
  {
    accessor: " ",
    Header: " ",
    width: 50,
    Cell: (data) => {
      const d = data?.row?.original; 
      return <ChangePassword email={d?.email} username={d?.username}/>;
    },
  },
];
