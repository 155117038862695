import { FormInput, SelectBusinessUnit, UploadPhoto } from "@components";
import { useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Popconfirm,
  Space,
  Switch,
} from "antd";

import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import TextArea from "antd/es/input/TextArea";
import { GridContainer, Span } from "@common";
import { formatDateDash } from "@constant";
import { IContent } from "src/types/Content";
import { addContent } from "@api";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const OfferAdd = ({ openModal, setOpenModal, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    title: "",
    description: "",
    effective_date: "",
    end_date: "",
    url: "",
    company_code: "",
    banner_type: "offers",
    is_active: true,
  };

  const [offerDetails, setOfferDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);
  const [file, setFile] = useState() as any;

  const handleClose = () => {
    setOpenModal(false);
    setisloading(false);
    setOfferDetails(initialState);
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setOfferDetails({
      ...offerDetails,
      [name]: value,
    });
  };

  const handeAddOffer = async () => {
    try {
      setisloading(true);

      const formData = await handleFormData(offerDetails, file);

      const res = await addContent(formData);
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new business!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new promo!",
        type: "Error",
      });
      setisloading(false);
    }
    handleClose();
  };

  const handleFile = async (data: any) => {
    setFile(data);
  };

  const handleFormData = async (offerDetails: IContent, file: Blob) => {
    const formData = new FormData();

    formData.append("file", file);
    const offerItem = {
      ...offerDetails,
      effective_date: formatDateDash(offerDetails?.effective_date),
      end_date: formatDateDash(offerDetails?.end_date),
    };

    for (let key in offerItem) {
      formData.append(`${key}`, offerItem[key]);
    }
    return formData;
  };

  return (
    <Drawer
      title="Add Offer"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in point of sales?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeAddOffer();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <UploadPhoto setFile={handleFile} openModal={openModal} />

        <FormInput
          label="Title"
          name="title"
          required={true}
          onChange={onChange}
        />

        <Form.Item
          name="description"
          label="Description"
          className="w-full"
          rules={[
            {
              required: true,
              message: "Description Type is required",
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            onChange={(v: any) =>
              setOfferDetails({
                ...offerDetails,
                description: v.target.value.trim(),
              })
            }
          />
        </Form.Item>

        <SelectBusinessUnit
          onChange={(v: any) =>
            setOfferDetails({
              ...offerDetails,
              company_code: v,
            })
          }
          all={false}
          required={false}
        />

        <GridContainer grid="two" gap="gapFour">
          <Form.Item
            label="Effective  Date"
            name="effective_date"
            rules={[{ required: true, message: `Effective Date is required` }]}
          >
            <DatePicker
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setOfferDetails({
                  ...offerDetails,
                  effective_date: e,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: `End Date is required` }]}
          >
            <DatePicker
              name="date"
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setOfferDetails({
                  ...offerDetails,
                  end_date: e,
                })
              }
            />
          </Form.Item>
        </GridContainer>

        <FormInput
          label="Url"
          name="url"
          required={false}
          onChange={onChange}
        />

        <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
          <Span weight="semi" margin="y-auto">
            Status
          </Span>
          <Form.Item
            style={{margin: 0}}
            name="is_active"
            valuePropName={offerDetails?.is_active ? "checked" : ""}
          >
             <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={offerDetails.is_active}
              onChange={(v) =>
                setOfferDetails({ ...offerDetails, is_active: v })
            }/> 
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
