import { GridContainer, ReportLabel, Span } from "@common";
import { formatDecimal } from "@constant";
import { RootState } from "@store";
import { useSelector } from "react-redux";

export const TotalList = ({ tableName }: any) => {
  const data = useSelector(
    (state: RootState) => state.Table.tables[tableName].data
  );

  const renderDesign = () => {
    switch (tableName) {
      case "store":
        return (
          <>
            <ReportLabel
              title="Total Purchases"
              label={formatDecimal(data?.totalTransactionAmount)}
            />

            <ReportLabel
              title="Total Average Spend"
              label={formatDecimal(data?.averageTotalTransaction)}
            />
          </>
        );
      case "points":
        return (
          <>
            <ReportLabel
              title="Total Earned Points"
              label={formatDecimal(data?.totalEarnedPoints)}
            />

            <ReportLabel
              title="Total Count"
              label={formatDecimal(data?.totalCount)}
            />
          </>
        );
      case "redeem":
        return (
          <>
            <ReportLabel
              title="Total Redeem Points"
              label={formatDecimal(data?.totalRedeemPoints)}
            />
            <ReportLabel
              title="Total Count"
              label={formatDecimal(data?.totalCount)}
            />
          </>
        );
      case "account_reports":
        return (
          <>
            <ReportLabel
              title="Total Earned Points"
              label={formatDecimal(data?.totalEarnedPoints)}
            />

            <ReportLabel
              title="Total Redeem Points"
              label={formatDecimal(data?.totalRedeemedPoints)}
            />
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className="mx-auto mt-6 mb-3">
        <GridContainer gap="gapEight">
          <ReportLabel
            title="Total Transaction"
            label={
              data?.totalTransactionCount
                ? data?.totalTransactionCount
                : data?.totalTransaction
            }
          />

          {renderDesign()}
        </GridContainer>
      </div>
    </>
  );
};
