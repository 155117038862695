import { Strings } from "@constant";
import { LoginPage } from "@containers";
import { useEffect } from "react";

export const Login = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Login")
  })
  
  return (
    <>
      <LoginPage />
    </>
  );
};
