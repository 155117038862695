import { Span } from "@common"; 

type Props = {
  title: string;
  label: string;
  classes?: any;
};

export const TransactionLabel = ({ title, label, classes }: Props) => {
  return (
    <> 
        <div className={classes}> 
        <div className="flex flex-col w-max my-2">
          <Span weight="semi" color="gray-primary" transform="uppercase">
            {title}
          </Span>
          <p><Span weight="semi" transform="capitalize">{label}</Span></p>
          </div>
        </div> 
    </>
  );
};
