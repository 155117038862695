import { Strings } from "@constant";
import { MemberManagementPage } from "@containers";
import { useEffect } from "react";

export const MemberManagement = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Member");
  });

  return (
    <>
      <MemberManagementPage />
    </>
  );
};
