import { Button, Span } from "@common"
import { FaUserLock, MdOutlineLockReset } from "@icons" 
import { useState } from 'react';
import { Modal } from 'antd';

import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import { resendEmail, resetPassword } from "@api";

type Props = {
    email: string,
    username: string
}

export const ChangePassword = ({ email, username }: Props) => {   

    const dispatch = useDispatch<Dispatch>(); 

    const [isModalPassword, setIsModalPassword] = useState(false); 

    const passwordModal = () => {
      setIsModalPassword(true);
    }; 
  
    const handleCancel = () => {
      setIsModalPassword(false);
    };

    
    const handlePassword = async () => {
        try {
            const result = username ? await resetPassword(email) : await resendEmail(email);  
            if (result.success) {
                dispatch.UI.setAlert({
                message: result?.message ?? "Successfully resend email!",
                type: "Success",
                }); 
            }
        } catch (err: any) {  
            console.log("err", err)
        }
        setIsModalPassword(false);  
    }
 

    return (<>  
        <div className="flex items-center justify-center"> 
            <Button
                width="none"
                padding="p-1" 
                radius="md"
                bg={username ? "green" : "red" }
                margin="none"
                onClick={passwordModal}
            >       
                {username 
                    ? <FaUserLock className="my-auto mx-2 text-md items-center inline-flex" /> 
                    : <MdOutlineLockReset className="my-auto mx-2 text-lg items-center inline-flex" /> 
                }
            </Button>  
        </div>

        <Modal
          title={username ? "Reset Password" : "Resend Email"}
          open={isModalPassword} 
          onOk={handlePassword} 
          okText={username ? 'Reset' : 'Resend'}
          okType='primary'
          onCancel={handleCancel}
        >
            <p><Span>Are you sure you want to {username ? "change password" : "resend email" }?</Span></p>
            <p><Span>Email will be sent to <b>{email}</b></Span></p> 
        </Modal>
    </>
    )
}