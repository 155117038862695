import { Strings } from "@constant";
import { EarnPointsListPage } from "@containers";
import { useEffect } from "react";

export const EarnList = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Earn Points");
  });

  return (
    <>
      <EarnPointsListPage />
    </>
  );
};
