import { Span } from "@common";
import {
  DeleteModal,
  FormInput,
  FormSwitch,
  SelectBranchCode,
  SelectCompanyCode,
} from "@components";
import { Button, Drawer, Form, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";

import { deletePOS, getPOSById, updatePOS } from "@api";

type Props = {
  id: string;
  getData: Function;
};

export const POSEdit = ({ id, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [posDetails, setPOSDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(false);
    dispatch.UI.updateFormId({ target: "editPOS", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPOSDetails({
      ...posDetails,
      [name]: value,
    });
  };

  const handeEditPOS = async () => {
    try {
      setisloading(true);
      const res = await updatePOS(id, { ...posDetails });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const getPOSData = async () => {
    setisloading(true);
    try {
      const res = await getPOSById(id);
      const details = {
        pos_name: res?.pos_name,
        branch_code: res?.branch_code,
        company_code: res?.company_code,
        is_active: res?.is_active,
      };
      setPOSDetails(details);
      form.setFieldsValue({
        ...details,
        pos_code: res?.pos_code,
      });
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getPOSData();
  }, [id]);

  const handleDelete = async () => {
    setisloading(true);
    try {
      const result = await deletePOS(id);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    setisloading(false);
    setIsModalDelete(false);
    handleClose();
  };
  const readOnly = !userAccess?.pos_update;

  return (
    <>
      <Drawer
        title={readOnly ? "View Point of Sales" : "Edit Point of Sales"}
        placement="right"
        width={500}
        onClose={handleClose}
        open={id ? true : false}
        footer={readOnly ? null :
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in point of sales?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}>Cancel</Button>
              </Popconfirm>
              {userAccess?.pos_update && (
                <Button
                  onClick={form.submit}
                  type="primary"
                  loading={isLoading}
                >
                  Update
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <Form
          onFinish={handeEditPOS}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Code"
            name="pos_code"
            required={true}
            onChange={onChange}
            disabled={true}
          />
          <FormInput
            label="Name"
            name="pos_name"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />

          <SelectCompanyCode
            onChange={(v: any) =>
              setPOSDetails({ ...posDetails, company_code: v })
            }
            disabled={readOnly}
          />
          <SelectBranchCode
            onChange={(v: any) =>
              setPOSDetails({ ...posDetails, branch_code: v })
            }
            companyCode={posDetails?.company_code}
            disabled={readOnly}
          />

          <FormSwitch
            label="Status"
            value={posDetails?.is_active}
            name="is_active"
            checkName="Active"
            uncheckName="Inactive"
            onChange={(v) =>
              setPOSDetails({
                ...posDetails,
                is_active: v,
              })
            }
            disabled={readOnly}
          />

          {userAccess?.pos_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Point of Sale"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={isLoading}
        okLabel="Delete"
        subtitle={posDetails?.pos_name}
      />
    </>
  );
};
