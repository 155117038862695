import {
  DeleteModal,
  FormInput,
  FormSwitch,
  SelectCompanyCode,
} from "@components";
import { Button, Drawer, Form, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";

import { deleteBranch, getBranchById, updateBranch } from "@api";

type Props = {
  id: string;
  getData: Function;
};

export const BranchConfigEdit = ({ id, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [branchDetails, setBranchDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(false);
    dispatch.UI.updateFormId({ target: "editBranch", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setBranchDetails({
      ...branchDetails,
      [name]: value,
    });
  };

  const handeEditBranch = async () => {
    try {
      setisloading(true);
      const res = await updateBranch(id, { ...branchDetails });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const getBranchData = async () => {
    setisloading(true);
    try {
      const res = await getBranchById(id);
      const details = {
        branch_name: res?.branch_name,
        company_code: res?.company_code,
        address: res?.address,
        is_active: res?.is_active,
      };
      setBranchDetails(details);
      form.setFieldsValue({ ...details, branch_code: res?.branch_code });
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getBranchData();
  }, [id]);

  const handleDelete = async () => {
    setisloading(true);
    try {
      const result = await deleteBranch(id);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    setisloading(false);
    setIsModalDelete(false);
    handleClose();
  }; 
  
  const readOnly = !userAccess?.branch_update;

  return (
    <>
      <Drawer
        title={readOnly
            ? "View Branch Configuration"
            : "Edit Branch Configuration"
        }
        placement="right"
        width={500}
        onClose={handleClose}
        open={id ? true : false}
        footer={readOnly ? null :
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in new branch configuration?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}>Cancel</Button>
              </Popconfirm>
              {userAccess?.branch_update && (
                <Button
                  onClick={form.submit}
                  type="primary"
                  loading={isLoading}
                >
                  Update
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <Form
          onFinish={handeEditBranch}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Branch Code"
            name="branch_code"
            required={true}
            onChange={onChange}
            disabled={true}
          />
          <FormInput
            label="Branch Name"
            name="branch_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />

          <SelectCompanyCode
            disabled={readOnly}
            onChange={(v: any) =>
              setBranchDetails({ ...branchDetails, company_code: v })
            }
          />
          <FormInput
            label="Address"
            name="address"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />

          <FormSwitch
            disabled={readOnly}
            label="Status"
            value={branchDetails?.is_active}
            name="is_active"
            checkName="Active"
            uncheckName="Inactive"
            onChange={(v) =>
              setBranchDetails({
                ...branchDetails,
                is_active: v,
              })
            }
          />

          {userAccess?.branch_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
                loading={isLoading}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Branch"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={isLoading}
        okLabel="Delete"
        subtitle={branchDetails?.branch_name}
      />
    </>
  );
};
