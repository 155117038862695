import { CardContainer, Span } from "@common"
import { BRAND_IMAGES } from "@constant";
import './account.scss'; 
import { ContactUs } from "./ContactUs";


type Props = { 
    title?: string;
    subtitle?: string; 
  };  

export const ErrorAccount = ({title, subtitle}: Props) => {   

    return (<>      
        <CardContainer>      
          <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="account-logo"/>
          
            <p className="text-center mt-6">
              <Span size="3xl" weight="bold" font="montserrat" color="green">
              {title}
              </Span> 
            </p> 

            <p className="text-center mt-2 mb-6">
              <Span size="base" color="gray" weight="bold">
                {subtitle}
              </Span>
            </p>

          <ContactUs />
        </CardContainer>
    </>)
}