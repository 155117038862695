import { formatDateTime } from "@constant";

export const COLUMNS_APPROVED = [
  {
    accessor: "reference_number",
    Header: "Refence Number",
  },
  {
    accessor: "account_number",
    Header: "Account Number",
  },
  {
    accessor: "account_name",
    Header: "Name",
  },
  {
    accessor: "type",
    Header: "Type",
    Cell: (data) => {
      const type = data?.row?.original?.type;
      return (
        <>
          <span className="capitaliz
          e">{type} </span>
        </>
      );
    },
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "date_requested",
    Header: "Date Requested",
    Cell: (data) => {
      const date = data?.row?.original?.date_requested;
      return (
        <>
          <span>{formatDateTime(date)} </span>
        </>
      );
    },
  },
  {
    accessor: "date_approved",
    Header: "Date Approved",
    Cell: (data) => {
      const date = data?.row?.original?.date_approved;
      return (
        <>
          <span>{formatDateTime(date)} </span>
        </>
      );
    },
  },
];
