import { GridContainer, Span } from "@common";
import {
  FormDatePicker,
  FormInput,
  FormSelect,
  SelectBranch,
  SelectBusinessUnit,
  SelectTier,
} from "@components";
import { useState } from "react";
import {
  FREQUENCY,
  HHMMA,
  PROMO_CONDITION_TYPE,
  PROMO_TYPE,
  formatDateDash,
  formatTimeOnly,
  onlyNumberKey,
} from "@constant";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Popconfirm,
  Select,
  Space,
  Switch,
} from "antd";

import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import { addPromo } from "@api";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const PromoAdd = ({ openModal, setOpenModal, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    promo_code: "",
    voucher_code: "",
    company_code: "",
    branch_code: "",
    type: "",
    title: "",
    sku: "",
    tiers: [],
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    frequency: [],
    condition: "none",
    condition_value: 0,
    additional_points: 0,
    multiplier: 0,
    is_active: true,
  };

  const [promoDetails, setPromoDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setisloading(false);
    setPromoDetails(initialState);
    getData();
    form.resetFields();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPromoDetails({
      ...promoDetails,
      [name]: value,
    });
  };

  const handeAddPOS = async () => {
    try {
      setisloading(true);
      const res = await addPromo({
        ...promoDetails,
        start_date: formatDateDash(promoDetails?.start_date),
        end_date: formatDateDash(promoDetails?.end_date),
        start_time: promoDetails.start_time
          ? formatTimeOnly(promoDetails.start_time)
          : null,
        end_time: promoDetails.end_time
          ? formatTimeOnly(promoDetails.end_time)
          : null,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new business!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new promo!",
        type: "Error",
      });
      setisloading(false);
    }
    handleClose();
  };

  const handleBusiness = (v: any) => {
    if (v === "all") {
      form.setFieldsValue({ branch_code: "all" });
      setPromoDetails({ ...promoDetails, company_code: v, branch_code: "all" });
    } else {
      form.setFieldsValue({ branch_code: [] });
      setPromoDetails({ ...promoDetails, company_code: v });
    }
  };

  const handleBranch = (v: any) => {
    const include = v.includes("all");
    if (include) {
      form.setFieldsValue({ branch_code: "all" });
      setPromoDetails({ ...promoDetails, branch_code: "all" });
    } else {
      setPromoDetails({ ...promoDetails, branch_code: v });
    }
  };

  return (
    <Drawer
      title="Add Promotions Configuration"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in promotions configuration?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeAddPOS();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <FormInput
          label="Title"
          name="title"
          required={true}
          onChange={onChange}
        />

        <GridContainer grid="two" gap="gapFour">
          <FormInput
            label="Promo Code"
            name="promo_code"
            required={true}
            wrapperCol={{ span: 24 }}
            onChange={onChange}
          />
          <FormInput
            label="Voucher Code"
            name="voucher_code"
            required={false}
            wrapperCol={{ span: 24 }}
            onChange={onChange}
          />
        </GridContainer>

        {/* company_code */}
        <SelectBusinessUnit
          onChange={(v: any) => handleBusiness(v)}
          all={true}
          required={true} 
        />

        {/* branch_code  */}
        <SelectBranch
          onChange={(v: any) => handleBranch(v)}
          companyCode={promoDetails?.company_code}
          disabled={promoDetails?.company_code === "all"}
        />

        <SelectTier
          onChange={(v: any) => setPromoDetails({ ...promoDetails, tiers: v })}
          mode="multiple"
          name="tiers"
        />

        <FormSelect
          label="Frequency"
          name="frequency"
          option={FREQUENCY}
          required={true}
          mode="multiple"
          onChange={(v: any) =>
            setPromoDetails({ ...promoDetails, frequency: v })
          }
        />

        <Form.Item
          label="Condition"
          name="condition"
          rules={[{ required: false }]}
        >
          <Select
            className="w-full"
            placeholder="Condition Type"
            onChange={(v: any) =>
              setPromoDetails({ ...promoDetails, condition: v })
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {PROMO_CONDITION_TYPE.map((d, index) => (
              <Select.Option key={index} value={d.value}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <FormInput
          label="Condition Value"
          name="condition_value"
          required={promoDetails.condition === "none" ? false : true}
          wrapperCol={{ span: 24 }}
          maxLength={6}
          onKeyPress={onlyNumberKey}
          onChange={onChange}
        />

        <Form.Item label="Type" name="type" rules={[{ required: false }]}>
          <Select
            className="w-full"
            placeholder="Type"
            onChange={(v: any) => setPromoDetails({ ...promoDetails, type: v })}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {PROMO_TYPE.map((d, index) => (
              <Select.Option key={index} value={d.value}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <FormInput
          label="SKU"
          name="sku"
          required={false}
          onChange={onChange}
        />

        <GridContainer grid="two" gap="gapFour">
          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[{ required: true, message: `Start Date is required` }]}
          >
            <DatePicker
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setPromoDetails({ ...promoDetails, start_date: e })
              }
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: `End Date is required` }]}
          >
            <DatePicker
              name="date"
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setPromoDetails({ ...promoDetails, end_date: e })
              }
            />
          </Form.Item>

          <FormDatePicker
            label="Start Time"
            name="start_time"
            required={true}
            picker="time"
            format={HHMMA}
            onChange={(e: any) =>
              setPromoDetails({ ...promoDetails, start_time: e })
            }
          />

          <FormDatePicker
            label="End Time"
            name="end_time"
            required={true}
            picker="time"
            format={HHMMA}
            onChange={(v: any) =>
              setPromoDetails({ ...promoDetails, end_time: v })
            }
          />

          <FormInput
            label="Additional Points"
            name="additional_points"
            required={false}
            wrapperCol={{ span: 24 }}
            maxLength={6}
            onKeyPress={onlyNumberKey}
            onChange={onChange}
          />

          <FormInput
            label="Multiplier"
            name="multiplier"
            required={false}
            wrapperCol={{ span: 24 }}
            maxLength={6}
            onKeyPress={onlyNumberKey}
            onChange={onChange}
          />
        </GridContainer>

        <div className="minicard">
          <Span weight="semi">Promo Status</Span>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked={promoDetails.is_active}
            onChange={(v) => setPromoDetails({ ...promoDetails, is_active: v })}
          />
        </div>
      </Form>
    </Drawer>
  );
};
