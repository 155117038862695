import { Button, Span } from "@common";
import { ArrowRightOnRectangleIcon, GiHamburgerMenu } from "@icons";
import { useHistory } from "react-router-dom";
import "./header.scss";
import { useState } from "react";
import { AlertSnackBar } from "@components";

export const Header = () => {
  const history = useHistory();
  
  const [open, setOpen] = useState(false);

  return (
    <header>
      <AlertSnackBar open={open} setOpen={setOpen} />
      <label htmlFor="nav-toggle">
        <GiHamburgerMenu className="cursor-pointer" />
      </label>

      <div className="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 signout">
        <Button
          margin="none"
          bg="grey"
          onClick={() => {
            try {
              sessionStorage.clear();
            } catch(error) {
              console.log(error)
            } finally {
              history.push(`/logout`);
            }
          }}
        >
          <div className="flex">
            <ArrowRightOnRectangleIcon className="logout-icon" />
            <Span whitespace="nowrap">Sign out</Span>
          </div>
        </Button>
      </div>
    </header>
  );
};
