import { Span } from "@common";
import { CSVLinkContent } from "@components";
import { Button, Modal } from "antd"; 
import moment from "moment";  

type Props = {
    title: string;
    modalOpen: boolean;
    clickEvent?: () => void;
    cancelEvent?: () => void;
    loading?: boolean; 
    subtitle: string;
    csvDetails?: any;
    headers: any;
}

export const CSVModal = (props: Props) => {
    const now = moment();   

  return (  
    <Modal 
      title="Export CSV"
      open={props.modalOpen}
      onCancel={props.cancelEvent} 
      footer={[<>
        <Button onClick={props.cancelEvent} loading={props.loading}>
          Close
        </Button> 

        <CSVLinkContent
          data={props.csvDetails}
          headers={props.headers}
          filename={`${props.title}_${now.format("MM/DD/YYYY")}`}
          onCancel={props.cancelEvent}  
        /> 
      </>]}  
    > 
      <p><Span>Are you sure you want to export <b>{props.subtitle}</b> ?</Span></p>     
    </Modal>
  );
}