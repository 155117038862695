import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchContent, TablePagination } from "@components";
import { Button } from "antd";
import { FaPlus } from "@icons";
import { useEffect, useMemo, useState } from "react";

import { COLUMNS } from "./Table/columns";

import { UserAdminAdd } from "./Add/UserAdminAdd";
import { UserAdminEdit } from "./Edit/UserAdminEdit";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

export const UserProfilePage = ({ id }: any) => {
  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );
  const dispatch = useDispatch<Dispatch>();
  const column = useMemo(() => COLUMNS, []);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const tableName = "user-profile";

  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const userData: any[] = useSelector(
    (state: RootState) => state.Table.tables.user.data
  );

  const getUser = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getUserAdmin({
        table: "user",
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.UI.updateFormId({
      target: "editUser",
      value: null,
    });
    dispatch.Table.updateKeyword({ table: "user", value: "" });
    getUser();
  }, []);

  const showDrawer = () => {
    setAddUserModal(true);
  };

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: "user", value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: "user",
      value: 1,
    });
    setIsLoading(false);
    getUser();
  };

  useEffect(() => { 
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "username",
      label: "Username",
    },
    {
      value: "first_name",
      label: "First Name",
    },
    {
      value: "last_name",
      label: "Last Name",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "is_active",
      label: "Status",
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            User Administration
          </Span>
          <div className="search-bar">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
            />

            {userAccess?.user_administration_add && (
              <Button onClick={showDrawer} type="primary" size="large">
                <div className="flex pr-2 items-center">
                  <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
                  Add
                </div>
              </Button>
            )}
          </div>
        </GridContainer>

        {userData?.length > 0 ? (
          <TablePagination
            columns={column}
            data={userData}
            dataType={tableName}
          />
        ) : (
          <NoData />
        )}

        {/* Add */}
        <UserAdminAdd
          addUserModal={addUserModal}
          setAddUserModal={setAddUserModal}
          getUser={getUser}
        />

        {/* Edit */}
        {id && <UserAdminEdit getData={getUser} id={id} />}
      </div>
    </>
  );
};
