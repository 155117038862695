import { Empty } from 'antd'; 
import { Span } from "@common";

export const NoData = () => {   

    return (<>      
        <div
            className='flex text-center py-10 h-96 justify-center'
                style={{ 
                flexDirection: "column",
                alignItems: "center", 
                }}
        > 
            <Empty />  
        </div>
    </>)
}