import { BusinessPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Business = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Enrollment");
  });

  return (
    <>
      <BusinessPage />
    </>
  );
};
