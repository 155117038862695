import { Button } from "antd";
import { CSVLink } from "react-csv";  

type Props = {
  data: any;
  headers: any;
  filename: string;
  onCancel?: () => void;
};

export const CSVLinkContent = ({ data, headers, filename, onCancel }: Props) => {
  return (
    <>  
      <Button type="primary" onClick={onCancel}>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        className="w-full text-white font-normal whitespace-normal normal-case" 
      >
        Export
      </CSVLink>  
     </Button>
    </>
  );
};
