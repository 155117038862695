import { TiersPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Tiers = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Tiers");
  });

  return (
    <>
      <TiersPage />
    </>
  );
};
