import { IoIosArrowUp } from "@icons";
import { RootState } from "@store";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export const SidebarItem = ({ item }) => {
  const roleModules = useSelector((state: RootState) => state.User.access); 
  const [open, setOpen] = useState(false);

  const handleOpenSubNav = (e: any) => {
    e.preventDefault();
    setOpen(!open)
  }

  useEffect(() => {}, [open]) 

  return (
    <>
      {item.type === 'list' ? (
        <>
          <li
            className={classNames(
              'sidebar-item',
              open && 'open'
            )}
            onClick={handleOpenSubNav}
          >
            <div className="sidebar-title">
              <div className="flex items-center">
                <span className="sidebar-icon">{item.icon}</span>
                <span className="sidebar-subtitle">{item.title}</span>
              </div>
                <span className="toggle-btn">
                  <IoIosArrowUp />
                </span>
            </div>
          </li>
          <div className={classNames(
            "w-full flex flex-col gap-2 rounded-md",
            open ? "h-fit pl-4" : "h-0 hidden"
          )}>
            {item.subNav.map((child, index) => (
               roleModules[child?.permission] || child?.permission === "" ?
              <CustomNavLink key={index} item={child}/> : <></>
            ))}
          </div>
        </>
      ) : (
        <li>
          <CustomNavLink item={item}/>
        </li>
      )}
    </>
  );
};

const CustomNavLink = ({ item }) => {
  return (
    <NavLink className="sidebar-item" to={item.path} activeClassName={"active"}>
        <div className="sidebar-title">
          <div className="flex items-center">
            <span className="sidebar-icon">{item.icon}</span>
            <span className="sidebar-subtitle">{item.title}</span>
          </div>
        </div>
    </NavLink>
  );
}
