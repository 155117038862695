import { Span } from "@common";
import { Button, Modal } from "antd"; 

type Props = { 
    modalOpen: boolean
    clickEvent?: () => void
    cancelEvent?: () => void
    loading?: boolean  
}

export const ApprovedModal = (props: Props) => {

  return (  
    <Modal 
      title="Manual Approved"
      open={props.modalOpen}
      onCancel={props.cancelEvent} 
      footer={[<>
        <Button onClick={props.cancelEvent} loading={props.loading}>
          Close
        </Button>

        <Button type="primary" onClick={props.clickEvent} loading={props.loading}>
            Submit
        </Button>
      </>]}  
    > 
      <p><Span>Are you sure you want to approved?</Span></p>     
    </Modal>
  );
}