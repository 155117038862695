import { getCompanies } from "@api";
import { SelectContainer } from "@components"; 
import { useEffect, useState } from "react";

type Props = {
  onChange?: any;
  disabled?: boolean;
};

export const SelectCompanyCode = (props: Props) => {
  const [companyCodeDetails, setCompanyCodeDetails] = useState([] as any);
  const getCompanyCodeData = async () => {
    try {
      const res = await getCompanies();
      setCompanyCodeDetails(res.results);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCompanyCodeData();
  }, []);

  return (
    <SelectContainer
      label="Company Code"
      name="company_code"
      onChange={props?.onChange}
      option={companyCodeDetails.map((item) => ({
        value: item?.company_code,
        label: `${item?.company_code} - ${item?.company_name}`,
      }))}
      disabled={props?.disabled}
      required={true}
    />
  );
};
