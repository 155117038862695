import BRAND_LOGO from "../assets/logo-allrewards.png";
import BG_CIRCLE_UP from "../assets/circle-up.png";
import BG_CIRCLE_DOWN from "../assets/circle-down.png";
import BG_CIRCLE_RED from "../assets/circle-up-red.png";
import BG_CIRCLE_GREEN from "../assets/circle-down-green.png";
import BG_DOTS from "../assets/dots.png";
import { env } from "src/utils/env";
import { Permission } from "@types";

export const BRAND_IMAGES = {
  BRAND_LOGO,
};

export const BG = {
  BG_CIRCLE_DOWN,
  BG_CIRCLE_UP,
  BG_CIRCLE_RED,
  BG_CIRCLE_GREEN,
  BG_DOTS,
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const HHMMA = "hh:mm A";
export const HHMM = "HH:mm";

export const STATUS = [
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Inactive",
    value: "inactive",
  },
];

export const STATUS_BOOLEAN = [
  {
    name: "Active",
    value: "true",
  },
  {
    name: "Inactive",
    value: "false",
  },
];

export const FREQUENCY = [
  {
    name: "Monday",
    value: "Monday",
  },
  {
    name: "Tuesday",
    value: "Tuesday",
  },
  {
    name: "Wednesday",
    value: "Wednesday",
  },
  {
    name: "Thursday",
    value: "Thursday",
  },
  {
    name: "Friday",
    value: "Friday",
  },
  {
    name: "Saturday",
    value: "Saturday",
  },
  {
    name: "Sunday",
    value: "Sunday",
  },
];

export const CONDITION_TYPE = [
  {
    name: "Points",
    value: "points",
  },
  {
    name: "Currency",
    value: "currency",
  },
];

export const PROMO_CONDITION_TYPE = [
  {
    name: "None",
    value: "none",
  },
  {
    name: "Minimum Purchase",
    value: "minimum_purchase",
  },
  {
    name: "Special Date",
    value: "special_date",
  },
];

export const PROMO_TYPE = [
  {
    name: "Additional",
    value: "additional",
  },
  {
    name: "Regular",
    value: "regular",
  },
];

export const PREFIXES = [
  {
    name: "MR",
    value: "Mr.",
  },
  {
    name: "MS",
    value: "Ms.",
  },
  {
    name: "MRS",
    value: "Mrs.",
  },
];

export const MEMBER_STATUS = [
  {
    name: "For Verification",
    value: "for_verification",
  },
  {
    name: "Verified",
    value: "verified",
  },
];

export const TRANSACTION_STATUS = [
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Done",
    value: "done",
  },
  {
    name: "Cancelled",
    value: "cancelled",
  },
];

export const ACTION_TYPE = [
  {
    name: "Earn",
    value: "earn",
  },
  {
    name: "Redeem",
    value: "redeem",
  },
  {
    name: "Refund",
    value: "refund",
  },
  {
    name: "Adjustment",
    value: "adjustment",
  },
];

export const MEMBER_STATUS_TYPE = {
  for_verification: "For Verification",
  verified: "Verified",
};

export const Strings = {
  pageTitle(...titles: string[]): string {
    let pageTitle = "";
    titles.forEach((title) => {
      pageTitle = pageTitle.concat(`${title} • `);
    });
    pageTitle = pageTitle.concat(env.app.name);
    return pageTitle;
  },
};

export const MANUAL_TAB = {
  PENDING: "pending",
  APPROVE: "approved",
  DECLINE: "declined",
};

export const REMARKS = [
  {
    name: "System Downtime",
    value: "System Downtime",
  },
  {
    name: "Software Updates",
    value: "Software Updates",
  },
  {
    name: "Promotional Points",
    value: "Promotional Points",
  },
  {
    name: "Transfer of Points",
    value: "Transfer of Points",
  },
  {
    name: "Account Merges",
    value: "Account Merges",
  },
  {
    name: "Transaction Errors",
    value: "Transaction Errors",
  },
  {
    name: "Entry Mistakes",
    value: "Entry Mistakes",
  },
  {
    name: "Resolving Customer Complaints",
    value: "Resolving Customer Complaints",
  },
  {
    name: "Goodwill Adjustments",
    value: "Goodwill Adjustments",
  },
];

export const initialPermissions: Permission[] = [
  { user_administration_view: false },
  { user_administration_add: false },
  { user_administration_update: false },
  { user_administration_delete: false },
  { business_unit_view: false },
  { business_unit_add: false },
  { business_unit_update: false },
  { business_unit_delete: false },
  { branch_view: false },
  { branch_add: false },
  { branch_update: false },
  { branch_delete: false },
  { pos_view: false },
  { pos_add: false },
  { pos_update: false },
  { pos_delete: false },
  { promotion_view: false },
  { promotion_add: false },
  { promotion_update: false },
  { promotion_delete: false },
  { tier_view: false },
  { tier_add: false },
  { tier_update: false },
  { tier_delete: false },
  { member_view: false }, 
  { member_update: false }, 
  { content_view: false },
  { content_add: false },
  { content_update: false },
  { content_delete: false },
  { store_transaction_view: false },
  { account_transaction_view: false },
  { manual_adjustment_view: false },
  { manual_adjustment_add: false },
  { manual_adjustment_update: false },
  { manual_adjustment_delete: false },
  { role_view: false },
  { role_add: false },
  { role_update: false },
  { role_delete: false },
  { reports_view: false },
];
