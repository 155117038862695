import { MemberStatus, StatusDesign } from "@components";

export const COLUMNS = [ 
      {
        accessor: "first_name",
        Header: "First Name",
      },
      {
        accessor: "last_name",
        Header: "Last Name",
      },
      {
        accessor: "email",
        Header: "Email",
      }, 
      {
        Header: "Member Status",
        accessor: "member_status",
        Cell: (data) => {
          const d = data?.row?.original; 
          return <MemberStatus member_status={d?.member_status} />;
        },
      },
      {
        accessor: "is_active",
        Header: "Status",
        Cell: (data) => {
          const d = data?.row?.original; 
          return <StatusDesign is_active={d?.is_active} />;
        },
      },
];