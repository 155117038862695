import { GridContainer, Separator, Span } from "@common";
import { Loader, TransactionDetails, TransactionLabel } from "@components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { getStoreById } from "@api";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Collapse } from "@mui/material";
import { formatDateTime } from "@constant";

export const StoreDetailsPage = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();

  const [storeDetails, setStoreDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);

  const [transaction, setTransaction] = useState(true);

  const togglePickUp = (e: any) => {
    e.preventDefault();
    setTransaction(!transaction);
  };

  const getBusinessData = async () => {
    setisloading(true);
    try {
      const res = await getStoreById(id);
      setStoreDetails(res);
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };
 
  useEffect(() => {
    getBusinessData();
  }, [id]);

  return (
    <>
      <TransactionDetails data={storeDetails} isLoading={isLoading} title="Store"/>
    </>
  );
};
