import { formatDecimal } from "@constant";

export const COLUMNS = [
    {
      accessor: "transaction_account_number",
      Header: "Account Number",
    }, 
    {
      accessor: "total_earned_points",
      Header: "Total Earned Points",  
      Cell: (data) => {
        const points = data?.row?.original?.total_earned_points; 
        return (<>
         <span>{formatDecimal(points)} </span>
        </>) 
      },
    },
    {
      accessor: "transaction_count",
      Header: "Transaction Count",
    }, 
];


export const HEADERS_EARN_POINTS = [
  { label: "Account Number", key: "transaction_account_number" }, 
  { label: "Total Earned Points", key: "total_earned_points" },
  { label: "Transaction Count", key: "transaction_count" }, 
];