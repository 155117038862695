import { Strings } from "@constant";
import { ManualDetailsPage } from "@containers";
import { useEffect } from "react";

export const ManualAdjustmentDetails = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Manual Adjustment")
  })
  
  return (
    <>
      <ManualDetailsPage />
    </>
  );
};
