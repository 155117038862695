import { get, put, post } from "@api";  
import { PromoInfo } from "src/types/PromoInfo"; 

export const getPromo  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {
    let res = await get(`/store/promos?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const addPromo = async (promo: PromoInfo) => { 
  try {
    let res = await post(`/store/create-promo`, promo);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   


export const updatePromo = async (promoCode: string, promo: PromoInfo) => { 
  try {
    let res = await put(`/store/update-promo/${promoCode}`, promo);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getPromoDetails = async (promoCode: string) => {
  try{     
    const res = await get(`/store/promo/${promoCode}`);
    return res.data;
  }catch(err){
    throw err;
  }
}