import { Strings } from "@constant";
import { ResetPasswordPage } from "@containers";
import { useEffect } from "react";

export const ResetPassword = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Reset Password")
  })
  
  return (
    <>
      <ResetPasswordPage />
    </>
  );
};
