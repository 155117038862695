import { FormInput, SelectCompanyCode } from "@components"; 
import { Button, Drawer, Form, Popconfirm, Select, Space, Switch } from "antd"; 
import { useState } from "react";
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { addBranches } from "@api"; 
import { Span } from "@common";

type Props = { 
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const BranchConfigAdd = ({openModal, setOpenModal, getData}: Props) => {  
    const dispatch = useDispatch<Dispatch>();
    const [form]: any = Form.useForm(); 
  
    const initialState = {
        branch_code: "",
        company_code: "",
        branch_name: "",
        address: "",
        is_active: true, 
    };

      
    const [branchDetails, setBranchDetails] = useState(initialState);
    const [isLoading, setisloading] = useState(false);

    const handleClose = () => { 
        setisloading(false); 
        form.resetFields(); 
        setBranchDetails(initialState);
        setOpenModal(false)   
        getData();
    } 

    
  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setBranchDetails({
      ...branchDetails,
      [name]: value,
    });
  };  

  
  const handeAddBranch = async () => {
    try {
      setisloading(true); 
      const res = await addBranches({...branchDetails});  
      if (res.success) { 
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new branch!",
          type: "Success",
        }); 
      }
    } catch (err: any) { 
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new branch!",
        type: "Error",
      });  
    }
    handleClose()
  };   

    return (  
        <Drawer
            title="New Branch Configuration"
            placement="right"
            width={500}
            onClose={handleClose} 
            open={openModal}
            footer={
            <div className="flex justify-center">
                <Space>
                <Popconfirm
                    placement="top"
                    title="Discard changes made in new branch configuration?"
                    onConfirm={handleClose}
                    okText="Discard"
                    cancelText="Cancel"
                >
                    <Button loading={isLoading}>
                    Cancel
                    </Button>
                </Popconfirm>
                <Button onClick={form.submit}
                    type="primary"  loading={isLoading}>
                    Add
                </Button>
                </Space>
            </div>
            }
        >
            <Form
            onFinish={() => {
              form.resetFields();
              handeAddBranch();
            }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            form={form} 
            >  
            <FormInput label="Branch Code" name="branch_code" required={true} onChange={onChange} /> 
            <FormInput label="Branch Name" name="branch_name" required={true} onChange={onChange} />  

           <SelectCompanyCode onChange={(v: any) => setBranchDetails({ ...branchDetails, company_code: v })} /> 

            <FormInput label="Address" name="address" required={false} onChange={onChange} /> 
            <div className='flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded'>
              <Span weight='semi'>Status</Span>  
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                defaultChecked={branchDetails.is_active}
                onChange={(v) =>
                  setBranchDetails({ ...branchDetails, is_active: v })
              }/> 
            </div>
            </Form>
        </Drawer>
    );
};