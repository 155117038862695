import { useState } from "react";

import { Inputs, Span } from "@common";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "@icons";

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: any;
  name?: string;
  label?: string
  required?:boolean
  classNames?: string;
};

export const PasswordInput = ({
  value,
  placeholder,
  onChange,
  name,
  label,
  required,
  classNames
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
    <div className={`my-4 ${classNames}`}>
      {label && <Span size="base">{label}</Span>}
        {required && <Span size="base" color="red"> *</Span>}
        <div className="flex w-full relative">
          <Inputs
            name={name}
            padding="pr-4"
            value={value}
            required 
            onChange={onChange}
            type={isVisible ? "text" : "password"}
            placeholder={placeholder}
          />
          {!isVisible ? (
            <MdOutlineVisibilityOff
              className="w-4 h-4 absolute right-3 top-5 m-auto cursor-pointer"
              onClick={() => setIsVisible(true)}
            />
          ) : (
            <MdOutlineVisibility
              className="w-4 h-4 absolute right-3 top-5 m-auto cursor-pointer"
              onClick={() => setIsVisible(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};
