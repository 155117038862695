import { put } from "@api";
import { UserInfo } from "@types";

export const updateUser = async (id, user: UserInfo) => { 
  try {
    let res = await put(`/users/update/${id}`, user);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 