import { createModel } from "@rematch/core";
import { RootModel } from ".";
import {
  getBranches,
  getRedeemPointsData,
  getCompany,
  getMemberData,
  getPointOfSale,
  getPoints,
  getPromo,
  getStoreTransaction,
  getTiers,
  getUserAdministration,
  getAccountReportsData,
  getAccountTransactionData,
  getListTransactionByAccountNumber,
  getStoreReportsData,
  getManualAdjustmentData,
  getRole,
} from "@api";

interface ITableDataPayload {
  table: string;
  data: any;
  next?: any;
  previous?: any;
  totalRecords?: any;
  currentPage?: any;
  value?: number;
}

export interface IFilterValue {
  field: string;
  operation: string;
  value: any;
  type: string;
}

interface IFilterPayload {
  table: string;
  key: string;
  filter: any;
}

let defaultTableValue = {
  filters: {},
  limit: 10,
  page: 1,
  sort: "",
  unsubscribe: null,
  data: [],
  totalRecords: null,
  keyword: "",
  original_data: null,
};

let manualPayload = {
  reference_number: "",
  id: "",
};

interface ITableUnsubscribePayload {
  table: string;
  data: any;
}

const initialState = {
  tables: {
    user: defaultTableValue,
    business: defaultTableValue,
    branch: defaultTableValue,
    pos: defaultTableValue,
    tiers: defaultTableValue,
    promo: defaultTableValue,
    member: defaultTableValue,
    store: defaultTableValue,
    points: defaultTableValue,
    account_reports: defaultTableValue,
    account_transaction: defaultTableValue,
    listtransaction: defaultTableValue,
    store_transaction: defaultTableValue,
    manual_adjustment: defaultTableValue,
    manual_approve: defaultTableValue,
    manual_pending: defaultTableValue,
    role: defaultTableValue,
  },
  isLoading: true,
  currentRoute: null,
  manualId: manualPayload,
} as any;

export const Table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */
    resetTable(state, table: string) {
      return {
        ...state,
        tables: { ...state.tables, [table]: { ...initialState.tables[table] } },
      };
    },
    setFilterValue(state, payload: IFilterPayload) {
      const { table, key, filter } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            filters: { [key]: filter },
          },
        },
      };
    },
    setTableData(state, payload: ITableDataPayload) {
      const { table, data, totalRecords = null } = payload;

      return {
        ...state,
        isLoading: false,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data,
            totalRecords,
          },
        },
      };
    },

    updatePageIndex(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            page: value,
          },
        },
      };
    },
    updatePageLimit(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            limit: value,
          },
        },
      };
    },
    updateKeyword(state, payload: any) {
      const { table, value } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            keyword: value,
          },
        },
      };
    },
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState,
      };
    },
    setTableUnsubscribe(state, payload: ITableUnsubscribePayload) {
      const { table, data } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            unsubscribe: data,
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async getMemberData(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getMemberData(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.data?.newMembers,
            totalRecords: res?.data?.totalCount,
            currentPage: res?.data?.currentPage,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getUserAdmin(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getUserAdministration(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getBusinessCompanies(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getCompany(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getBranch(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getBranches(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPOS(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getPointOfSale(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPromoList(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getPromo(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getTiersList(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getTiers(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getMember(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getMemberData(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStore(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getStoreTransaction(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPoints(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });
      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getPoints(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRedeemPoints(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getRedeemPointsData(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getAccountReports(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getAccountReportsData(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getAccountTransaction(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getAccountTransactionData(
          pageIndex,
          pageSize,
          keyword
        );
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getListTransactionByAccount(payload: any, rootState) {
      const { table, id } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const tableData = rootState.Table.tables[table];
        const { page: pageIndex, limit: pageSize, keyword } = tableData;
        const res = await getListTransactionByAccountNumber(
          id,
          pageIndex,
          pageSize,
          keyword
        );
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStoreTransaction(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const tableData = rootState.Table.tables[table];

        const { page: pageIndex, limit: pageSize, keyword } = tableData;
        const res = await getStoreReportsData(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getManualAdjustment(payload: any, rootState) {
      const { table, activeTab } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getManualAdjustmentData(
          activeTab,
          pageIndex,
          pageSize,
          keyword
        );
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res?.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRoleList(payload: any, rootState) {
      const { table } = payload;

      dispatch.Table.updateState({ isLoading: true });

      try {
        const {
          page: pageIndex,
          limit: pageSize,
          keyword,
        } = rootState.Table.tables[table];
        const res = await getRole(pageIndex, pageSize, keyword);
        if (res) {
          dispatch.Table.setTableData({
            table: payload?.table,
            data: res.results,
            totalRecords: res.totalCount,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  }),
});
