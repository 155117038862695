import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchContent, TablePagination } from "@components";
import { COLUMNS } from "./Table/columns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { MemberEdit } from "./Edit/MemberEdit";

export const MemberManagementPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const tableName = "member";

  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const dataMember: any[] = useSelector(
    (state: RootState) => state.Table.tables.member?.data
  );

  const memberId: any = useSelector(
    (state: RootState) => state.UI.forms.editMember?.id
  );

  const getMemberData = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getMember({
        table: tableName,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.UI.updateFormId({
      target: "editMember",
      value: null,
    });
    dispatch.Table.updateKeyword({
      table: "member",
      value: "",
    }); 
    getMemberData();
  }, []);

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getMemberData();
  };

  useEffect(() => { 
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "first_name",
      label: "First Name",
    },
    {
      value: "last_name",
      label: "Last Name",
    },
    {
      value: "member_status",
      label: "Member Status",
    },
    {
      value: "is_active",
      label: "Status",
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Member Management
          </Span>
          <div className="search-bar">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
            />
          </div>
        </GridContainer>

        {dataMember?.length > 0 ? (
          <TablePagination
            columns={COLUMNS}
            data={dataMember}
            dataType="member"
          />
        ) : (
          <NoData />
        )}

        {/* Edit */}
        {memberId && <MemberEdit getData={getMemberData} id={memberId} />}
      </div>
    </>
  );
};
