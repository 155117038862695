import { ReportList } from "@components";  
import { COLUMNS, HEADERS_REDEEM_POINTS } from "./Table/columns";  
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";  
import { getRedeemPointsCSV } from "@api";
import { formatDecimal } from "@constant";
import { useSelector } from "react-redux"; 
import { RootState } from "@store";
  
export const RedeemPointsListPage = () => {  
    const column = useMemo(() => COLUMNS, []);   
    const tableName = 'redeem'  
    const history = useHistory();  
    const [csvDetails, setCSVDetails] = useState([] as any);     
    const [isModalCSV, setIsModalCSV] = useState(false); 
  
    const handleBack = () => { 
        history.push(`/reports/redeempoints`);  
    }    

    
    const handleOpenCSV = () => { 
        setIsModalCSV(true) 
        getCSV();
    }       

    const keyword = useSelector(
        (state: RootState) => state.Table.tables[tableName].keyword
    ); 

    const getCSV = async () => {
        try { 
        const res = await getRedeemPointsCSV(keyword);   
        const newData = res.results?.map((data) => {
            return {
              ...data,
              transaction_account_number: `${data?.transaction_account_number}`,
              total_redeem_points: `${formatDecimal(data?.total_redeem_points)}`, 
              transaction_count: `${data?.transaction_count}`, 
            };
          });
        setCSVDetails(newData);
        } catch (error) {
        throw error;
        }
    };

    useEffect(() => {  
        getCSV();
    }, []);

    return (<> 
        <ReportList 
            tableName={tableName} 
            handleBack={handleBack} 
            columns={column}  
            title="Redeem Points Report"
            headers={HEADERS_REDEEM_POINTS} 
            csvDetails={csvDetails}
            handleOpenCSV={handleOpenCSV} 
            isModalCSV={isModalCSV}
            setIsModalCSV={setIsModalCSV}
        />
    </>);
}