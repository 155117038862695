import { Spin } from "antd";
import ReactLoading from "react-loading";

export const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="absolute z-50 items-center flex justify-center w-full h-full overscroll-none bg-[rgba(255,255,255,0.5)]">
             {/* bg-[rgba(255,255,255,0.5)] */} 
          <ReactLoading
            type="bubbles"
            color="#4f46e5"
            height={97}
            width={105}
          />
        </div>
      )}
    </>
  );
};
