import { EditDelete } from "./EditDelete";

export const COLUMNS_PENDING = [ 
  {
    accessor: "type",
    Header: "Type",
    Cell: (data) => {
      const type = data?.row?.original?.type;
      return (
        <>
          <span className="capitalize">{type} </span>
        </>
      );
    },
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "remarks",
    Header: "Remarks",
  },
  {
    accessor: " ",
    Header: " ", 
    Cell: (data) => {
      const d = data?.row?.original; 
      return <EditDelete manualId={d?.id} reference_number={d?.reference_number}/>;
    },
  }, 
];
