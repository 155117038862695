import { get, postMultipart, putMultipart, remove } from "@api";    

export const getContentData = async (banner_type?: string) => { 
  try {
    let res = await get(`/store/contents?banner_type=${banner_type}`);        
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const addContent = async ( formData: FormData) => { 
  try {
    let res = await postMultipart(`/store/create-content`, formData);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   

export const updateContent = async (content_id: string, data: FormData) => {
  try {   

    let res = await putMultipart(`/store/update-content/${content_id}`, data);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const deleteContent = async (content_id: string) => {
  try{     
    const res = await remove(`/store/delete-content/${content_id}`,); 
    return res.data;
  }catch(err){
    throw err;
  }
}   

export const getContentById = async (content_id: string) => { 
  try {
    let res = await get(`/store/content/${content_id}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
};  