import {
  Button,
  CardContent,
  CenterContent,
  GridContainer,
  Span,
} from "@common";
import { AlertSnackBar, InputContent, Loading, PasswordInput } from "@components";
import { BG, BRAND_IMAGES } from "@constant"; 
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; 
import { RootState, Dispatch } from "@store";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";

export const LoginPage = () => {
  const dispatch = useDispatch<Dispatch>(); 
  
  const isUserLoggedIn: any = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );

  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const username: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.username
  );
  const password: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.password
  );

  const [isLoading, setIsLoading] = useState(false);   
  const [open, setOpen] = useState(false); 

  const handleLogin = async () => {
    setIsLoading(true);
    try {   
      dispatch.UI.resetAlert();
      setOpen(false);
      await dispatch.User.login({ username, password });   
    } catch(err: any) {   
      console.log("err", err) 
    }
    setIsLoading(false);
  }   

  useEffect(() => {
    if(isUserLoggedIn) {
      history.push(`/profile`); 
    }
  }, [isUserLoggedIn])

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <>
      <AlertSnackBar open={open} setOpen={setOpen} />
      {loading || isLoading ? (
        <Loading />
      ) : (
        <CenterContent height="screen">
          <CardContent bg="white">
            <GridContainer grid="five">  
              <Form 
              onFinish={() => { 
                handleLogin();
              }}
              className="p-20 col-span-2"
              >
                <img
                  src={BRAND_IMAGES["BRAND_LOGO"]}
                  alt="AllRewards Logo"
                  className="mt-3 w-56 mb-8"
                />
                <Span size="3xl" weight="extrabold">
                  Sign in
                </Span> 

                <InputContent 
                  required={true} 
                  label="Username" 
                  name="username" 
                  type="text" 
                  onChange={(e) => {
                    dispatch.UI.setInputFormFields({
                      target: "login",
                      field: "username",
                      value: e.target.value, 
                    });
                }} />
                <PasswordInput 
                  required={true} 
                  label="Password" 
                  name="password" 
                  onChange={(e) => {
                    dispatch.UI.setInputFormFields({
                      target: "login",
                      field: "password",
                      value: e.target.value,
                    });
                  }}
                />

                <Button
                  margin="my-8"
                  type="submit"
                >
                  Sign in
                </Button>

                <div className="items-center text-center mt-15">
                  <span className="items-center text-center text-gray-700 opacity-50 font-bold">
                    v1.0.25
                  </span>
                </div>
              </Form>

              <div className="col-span-3">
                <div className="relative hidden md:flex flex-auto items-center justify-center h-full lg:px-28 overflow-hidden bg-gray-800 dark:border-l">
                  <img src={BG["BG_DOTS"]} alt="bg dots" className="dots" />
                  <img
                    src={BG["BG_CIRCLE_UP"]}
                    alt="bg circle"
                    className="circle-up"
                  />

                  <img
                    src={BG["BG_CIRCLE_DOWN"]}
                    alt="bg circle"
                    className="circle-down"
                  />

                  <div className="z-1">
                    <img
                      src={BRAND_IMAGES["BRAND_LOGO"]}
                      alt="AllRewards Logo"
                      className="img-logo"
                    />

                    <span className="login-header">
                      <b>Web Admin</b>
                    </span>
                  </div>
                </div>
              </div>
            </GridContainer>
          </CardContent>
        </CenterContent>
      )}
    </>
  );
};
