import { FormInput, SelectBusinessUnit, UploadPhoto } from "@components";
import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Popconfirm,
  Space,
  Switch,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import TextArea from "antd/es/input/TextArea";
import { GridContainer, Span } from "@common";
import dayjs, { Dayjs } from "dayjs";
import { getContentById, updateContent } from "@api";
import { IContent } from "src/types/Content";
import { formatDateDash } from "@constant";

type Props = {
  content_id: string;
  isModalOfferEdit: boolean;
  setIsModalOfferEdit: Function;
  getData: Function;
};

export const OfferEdit = ({
  isModalOfferEdit,
  setIsModalOfferEdit,
  getData,
  content_id,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const [offerDetails, setOfferDetails] = useState({} as IContent);
  const [isLoading, setisloading] = useState(false);
  const [file, setFile] = useState() as any;

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setOfferDetails({
      ...offerDetails,
      [name]: value,
    });
  };

  const getOfferData = async () => {
    setisloading(true);
    try {
      const res = await getContentById(content_id);
      const details = {
        title: res?.title,
        description: res?.description,
        company_code: res?.company_code,
        effective_date: res?.effective_date && dayjs(res?.effective_date),
        end_date: res?.end_date && dayjs(res?.end_date),
        url: res?.url,
        image_path: res?.image_path,
        is_active: res?.is_active,
      };
      setOfferDetails(details);
      form.setFieldsValue(details);
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getOfferData();
  }, [content_id]);

  const handleClose = () => {
    setIsModalOfferEdit(false);
    getData();
  };

  const handleFile = async (data: any) => {
    setFile(data);
  };

  const handleUpdateOffer = async () => {
    try {
      const formData = await handleFormData(offerDetails, file);
      const res = await updateContent(content_id, formData);
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully updated!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable update offer!",
        type: "Error",
      });
    }
    handleClose();
  };

  const handleFormData = async (offerDetails: IContent, file: Blob) => {
    const formData = new FormData();

    formData.append("file", file);
    const offerItem = {
      ...offerDetails,
      effective_date: formatDateDash(offerDetails?.effective_date),
      end_date: formatDateDash(offerDetails?.end_date),
      banner_type: "offers",
    };

    for (let key in offerItem) {
      formData.append(`${key}`, offerItem[key]);
    }
    return formData;
  };

  const readOnly = !userAccess?.content_update;

  return (
    <Drawer
      title={readOnly ? "View Offer" : "Edit Offer"}
      placement="right"
      width={500}
      onClose={handleClose}
      open={isModalOfferEdit}
      footer={readOnly ? null :
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in point of sales?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            {userAccess?.content_update && (
              <Button onClick={form.submit} type="primary" loading={isLoading}>
                Update
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handleUpdateOffer();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <UploadPhoto
          setFile={handleFile}
          openModal={isModalOfferEdit}
          defaultImageurl={offerDetails?.image_path}
          disabled={readOnly}
        />

        <FormInput
          label="Title"
          name="title"
          required={true}
          onChange={onChange}
          disabled={readOnly}
        />

        <Form.Item
          name="description"
          label="Description"
          className="w-full"
          rules={[
            {
              required: true,
              message: "Description Type is required",
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            onChange={(v: any) =>
              setOfferDetails({
                ...offerDetails,
                description: v.target.value.trim(),
              })
            }
            disabled={readOnly}
          />
        </Form.Item>

        <SelectBusinessUnit
          onChange={(v: any) =>
            setOfferDetails({
              ...offerDetails,
              company_code: v,
            })
          }
          all={false}
          required={false}
          disabled={readOnly}
        />

        <GridContainer grid="two" gap="gapFour">
          <Form.Item
            label="Effective  Date"
            name="effective_date"
            rules={[{ required: true, message: `Effective Date is required` }]}
          >
            <DatePicker
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setOfferDetails({
                  ...offerDetails,
                  effective_date: e,
                })
              }
              disabled={readOnly}
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: `End Date is required` }]}
          >
            <DatePicker
              name="date"
              inputReadOnly={true}
              className="w-full"
              onChange={(e: any) =>
                setOfferDetails({
                  ...offerDetails,
                  end_date: e,
                })
              }
              disabled={readOnly}
            />
          </Form.Item>
        </GridContainer>

        <FormInput
          label="Url"
          name="url"
          required={false}
          onChange={onChange}
          disabled={readOnly}
        />

        <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
          <Span weight="semi" margin="y-auto">
            Status
          </Span>
          <Form.Item 
            style={{margin: 0}}
            name="is_active"
            valuePropName={offerDetails?.is_active ? "checked" : ""}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(v) =>
                setOfferDetails({
                  ...offerDetails,
                  is_active: v,
                })
              }
              disabled={readOnly}
            />
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
