import { Models } from "@rematch/core";

import { Table } from "./TableModel";
import { UI } from "./UIModel";

import { User } from "./UserModel"; 


export interface RootModel extends Models<RootModel> {
    Table: typeof Table;
    UI: typeof UI;
    User: typeof User;
}

export const models: RootModel = {
  Table,
  UI,
  User
};
