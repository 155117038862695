import { getCompanies } from "@api"; 
import { Form, Select } from "antd";
import { useEffect, useState } from "react"; 
type Props = { 
  onChange: any; 
  all?: boolean;
  required: boolean;
  disabled?: boolean;
};  

export const SelectBusinessUnit = (props: Props) => {   
    
    const [businessUnitDetails, setBusinessUnitDetails] = useState([] as any); 
    const getBusinessUnitData = async () => {
        try { 
        const res = await getCompanies();  
          setBusinessUnitDetails(res?.results);   
        } catch (error) {
        throw error; 
        }
    };  

    useEffect(() => { 
        getBusinessUnitData();
    }, []);   
  
  return (
    <Form.Item
      labelCol={{ span: 24 }}
      label="Business Unit"
        name="company_code"
        rules={[{ required: props?.required, message: `Business Unit is required` }]}
    >
        <Select
            className="w-full"
            showSearch 
            placeholder="Business Unit" 
            onChange={props?.onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={props?.disabled}
        >
            {props?.all && 
              <Select.Option value="all">
                All
              </Select.Option>
            } 
            {businessUnitDetails.map((type, index) => (
            <Select.Option key={index} value={type.company_code}>
                {type.company_code} - {type.company_name}
            </Select.Option>
            ))}
        </Select> 
    </Form.Item>
  );
};