import { formatDateTime } from "@constant";

export const COLUMNS = [
  {
    accessor: "transaction_id",
    Header: "Id",
  }, 
  {
    accessor: "transaction_action",
    Header: "Type",
    Cell: (data) => {
      const type = data?.row?.original?.transaction_action;
      return (
        <>
          <span className="capitalize">{type} </span>
        </>
      );
    },
  },
  {
    accessor: "transaction_points",
    Header: "Points",
  },
  {
    accessor: "transaction_branch",
    Header: "Branch",
  },
  {
    accessor: "transaction_transaction_amount",
    Header: "Transaction Amount",
  },
  {
    accessor: "transaction_timestamp",
    Header: "Transaction Date",
    Cell: (data) => {
      const date = data?.row?.original?.transaction_timestamp;
      return (
        <>
          <span>{formatDateTime(date)} </span>
        </>
      );
    },
  },
];

export const HEADERS_ACOUNT_REPORT = [
  { label: "Id", key: "transaction_id" },
  { label: "Type", key: "transaction_action" },
  { label: "Point", key: "transaction_points" }, 
  { label: "Branch", key: "transaction_branch" },
  { label: "Total Amount", key: "transaction_transaction_amount" },  
  { label: "Transaction Date", key: "transaction_timestamp" },   
];
