import * as React from 'react';
import classnames from 'classnames'

type Props = React.HTMLProps<HTMLSelectElement> & {
  children?: React.ReactNode
  width?: 'none' |'150',
  border?: 'none',
  radius?: 'none',
  textSize?: 'small',
  padding?: 'none' | '1.5'
}

let getWidth = (width: Props['width']) => {
  switch (width) {
    case '150':
      return 'w-[150px]';
    case 'none':
      return '';
    default:
      return 'w-full';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {
    case 'none':
      return 'border-none';
    default:
      return 'border border-grey-1';
  }
}

let getRadius = (radius: Props['radius']) => {
  switch (radius) {
    case 'none':
      return 'rounded-none';
    default:
      return 'rounded';
  }
}

let getTextSize = (textSize: Props['textSize']) => {
  switch (textSize) {
    case 'small':
      return 'text-sm';
    default:
      return 'text-base';
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) {
    case 'none':
      return 'p-0'
    case '1.5':
      return 'p-1.5'
    default:
      return 'p-2'
  }
}

export const Select = ({ textSize, width, radius, border, children, padding, ...props }: Props) => {
  return <select className={classnames("focus:outline-none text-grey-7 capitalize",
    getBorder(border), getRadius(radius), getWidth(width), getTextSize(textSize), getPadding(padding))}
    {...props}>
    {children}
  </select>
}