import { Span } from "@common";
import { Form, Switch } from "antd";

type Props = {
  name?: string;
  required?: boolean;
  checkName?: string;
  uncheckName?: string;
  value?: string;
  onChange?: any;
  label: string;
  disabled?: boolean;
};

export const FormSwitch = (props: Props) => {
  return (
    <>
      <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
        <Span weight="semi" margin="y-auto">{props?.label}</Span>
        <Form.Item
          valuePropName={props?.value ? "checked" : ""}
          name={props?.name}
          rules={[
            {
              required: props?.required,
            },
          ]}
          style={{margin: 0}}
        >
          <Switch
            checkedChildren={props?.checkName}
            unCheckedChildren={props?.uncheckName}
            onChange={props?.onChange}
            disabled={props?.disabled}
          />
        </Form.Item>
      </div>
    </>
  );
};
