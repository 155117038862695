import { CardContainer, CenterContent, Span } from "@common";
import { BRAND_IMAGES } from "@constant"; 
import { Dispatch } from "@store";
import { useDispatch } from "react-redux";
import './account.scss'; 
import { ContactUs } from "@components"; 
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

type Props = { 
    title?: string;
    subtitle?: string; 
  };  

export const SuccessAccount  = ({title, subtitle}: Props) => {   
  
    const dispatch = useDispatch<Dispatch>();
    const history = useHistory(); 
    const [timer, setTimer] = useState(5);
    const idRef = useRef<NodeJS.Timeout | null>(null);

    const login = () => {  
      dispatch.User.logout();
      history.push(`/`); 
    } 

    useEffect(() => {
        idRef.current = setInterval(() => {
          setTimer((time) => time - 1);
        }, 1000);
    
        return () => {
          if (idRef.current) {
            clearInterval(idRef.current);
          }
        };
    }, []);

    

    useEffect(() => {
        if (timer === 0 && idRef.current) {
            clearInterval(idRef.current); 
            login()
        }
    }, [timer, history]);
    
  return (
    <> 
        <CardContainer>      
        <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="account-logo"/>
        
        <div className="text-center">   
            <p className="my-14">
            <Span size="3xl" weight="bold" font="montserrat" color="green">
                {title}
            </Span>
            </p>  
            <p className="my-14">
            <Span size="base" color="gray"> 
                {subtitle}
            </Span>
            </p> 

            <p><span className="text-blue-primary cursor-pointer" onClick={login}>
                Login
            </span></p>
            <Span size="base">({timer} seconds)</Span>
        </div>
        </CardContainer>

        <div className="flex min-h-screen justify-left items-center font-montserrat account-contact"> 
        <ContactUs />
        </div>  
      </>
    );
  };
  
