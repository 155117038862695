import { GridContainer, Span } from "@common";
import { DeleteModal, FormInput, FormSwitch } from "@components";
import { Button, Drawer, Form, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";

import { onlyNumberKey } from "@constant";
import { deleteBusiness, getBusinessById, updateBusiness } from "@api";

type Props = {
  company_code: string;
  getData: Function;
};

export const BusinessUnitEdit = ({ company_code, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [businessDetails, setBusinessDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(true);
    dispatch.UI.updateFormId({ target: "editBusinessUnit", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setBusinessDetails({
      ...businessDetails,
      [name]: value,
    });
  };

  const handeEditBusiness = async () => {
    try {
      setisloading(true);
      const contactNumber = businessDetails?.contact_number.replace("+63", "");
      const res = await updateBusiness(company_code, {
        ...businessDetails,
        contact_number: `+63${contactNumber}`,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const getBusinessData = async () => {
    setisloading(true);
    try {
      const res = await getBusinessById(company_code);
      const details = {
        company_name: res?.company_name,
        company_address: res?.company_address,
        contact_email: res?.contact_email,
        rate: res?.rate,
        threshold: res?.threshold,
        is_active: res?.is_active,
        contact_number: res?.contact_number,
      };

      setBusinessDetails(details);
      form.setFieldsValue({
        ...details,
        company_code: res?.company_code,
        contact_number: res?.contact_number?.replace("+63", ""),
      });
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getBusinessData();
  }, [company_code]);

  const handleDelete = async () => {
    setisloading(true);
    try {
      const result = await deleteBusiness(company_code);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    setisloading(false);
    setIsModalDelete(false);
    handleClose();
  };
  const readOnly = !userAccess?.business_unit_update;

  return (
    <>
      <Drawer
        title={readOnly ? "View Business Unit" : "Edit Business Unit"}
        placement="right"
        width={500}
        onClose={handleClose}
        open={company_code ? true : false}
        footer={readOnly ? null :
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in new business unit?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}>Cancel</Button>
              </Popconfirm>

              {userAccess?.business_unit_update && (
                <Button
                  onClick={form.submit}
                  type="primary"
                  loading={isLoading}
                >
                  Update
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <Form
          onFinish={handeEditBusiness}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Code"
            name="company_code"
            required={true}
            maxLength={10}
            onChange={onChange}
            disabled={true}
          />
          <FormInput
            label="Name"
            name="company_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />
          {/* Email */}
          <FormInput
            label="Email"
            name="contact_email"
            required={true}
            type="email"
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Contact Number"
            name="contact_number"
            required={false}
            addonBefore="+63"
            maxLength={10}
            onKeyPress={onlyNumberKey}
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Address"
            name="company_address"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />

          <GridContainer grid="two" gap="gapFour">
            <FormInput
              label="Rate"
              name="rate"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onKeyPress={onlyNumberKey}
              onChange={onChange}
              disabled={readOnly}
            />

            <FormInput
              label="Threshold"
              name="threshold"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={7}
              onKeyPress={onlyNumberKey}
              onChange={onChange}
              disabled={readOnly}
            />
          </GridContainer>

          <FormSwitch
            disabled={readOnly}
            label="Status"
            value={businessDetails?.is_active}
            name="is_active"
            checkName="Active"
            uncheckName="Inactive"
            onChange={(v) =>
              setBusinessDetails({
                ...businessDetails,
                is_active: v,
              })
            }
          />

          {userAccess?.business_unit_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Company"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={isLoading}
        okLabel="Delete"
        subtitle={businessDetails?.company_name}
      />
    </>
  );
};
