import {
  FormInput,
  SelectContainer,
  SelectNationality,
  SelectTier,
} from "@components";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Popconfirm,
  Space,
  Switch,
} from "antd";
import { onlyNumberKey, DATE_FORMAT, PREFIXES, MEMBER_STATUS } from "@constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import {
  getCities,
  getMemberById,
  getProvince,
  getProvinceByName,
  updateMember,
} from "@api";
import { GridContainer, Span } from "@common";

import dayjs, { Dayjs } from "dayjs";

type Props = {
  id: string;
  getData: Function;
};

export const MemberEdit = ({ id, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [memberDetails, setMemberDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(false);
    dispatch.UI.updateFormId({ target: "editMember", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setMemberDetails({
      ...memberDetails,
      [name]: value,
    });
  };

  const [cityDetails, setCityDetails] = useState([] as any);
  const [provinceDetails, setProvinceDetails] = useState([] as any);
  const [provinceName, setProvinceName] = useState("");
  const [provinceByName, setProvinceByName] = useState("");

  const handeEditMember = async () => {
    try {
      setisloading(true);
      const res = await updateMember(id, {
        ...memberDetails,
        account: {
          tier: memberDetails?.tier,
        },
        middle_name: memberDetails?.middle_name
          ? memberDetails?.middle_name
          : null,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const getMemberData = async () => {
    setisloading(true);
    try {
      const res = await getMemberById(id);
      const details = {
        prefix: res?.prefix,
        first_name: res?.first_name,
        last_name: res?.last_name,
        middle_name: res?.middle_name,
        email: res?.email,
        birth_date: res?.birth_date && dayjs(res.birth_date),
        citizenship: res?.citizenship,
        physical_address: res?.physical_address,
        province: res?.province,
        city: res?.city,
        contact_number: res?.contact_number,
        ofw_family: res?.ofw_family,
        vista_owner: res?.vista_owner,
        member_status: res?.member_status,
        is_active: res?.is_active,
        tier: res?.account?.tier,
      };
      setMemberDetails(details);
      setProvinceName(res?.province);
      form.setFieldsValue({
        ...details,
        contact_number: res?.contact_number?.replace("+63", ""),
        points: (Math.round(res?.account?.points * 100) / 100).toFixed(2),
        account_number: res?.account?.account_number,
      });
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getMemberData();
  }, [id]);

  const getProvinceData = async () => {
    try {
      const res = await getProvince();
      setProvinceDetails(res?.results);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getProvinceData();
  }, []);

  const getProvinceName = async () => {
    try {
      if (provinceName) {
        const res = await getProvinceByName(provinceName);
        setProvinceByName(res?.province_id);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getProvinceName();
  }, [provinceName]);

  const getCityData = async () => {
    try {
      if (provinceByName) {
        const res = await getCities(provinceByName);
        setCityDetails(res?.results);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCityData();
  }, [provinceByName]);

  const handleProvince = (v: any) => {
    setMemberDetails({ ...memberDetails, province: v });
    setProvinceName(v);
  };

  const readOnly = !userAccess?.member_update;

  return (
    <Drawer
      title={readOnly ? "View Member" : "Edit Member"}
      placement="right"
      width={500}
      onClose={handleClose}
      open={id ? true : false}
      footer={
        readOnly ? null : (
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in new branch configuration?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}>Cancel</Button>
              </Popconfirm>

              {userAccess?.member_update && (
                <Button
                  onClick={form.submit}
                  type="primary"
                  loading={isLoading}
                >
                  Update
                </Button>
              )}
            </Space>
          </div>
        )
      }
    >
      <Form
        onFinish={handeEditMember}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <FormInput label="Account Number" name="account_number" disabled />

        <SelectContainer
          label="Prefix"
          name="prefix"
          onChange={(v: any) =>
            setMemberDetails({ ...memberDetails, prefix: v })
          }
          option={PREFIXES.map((item) => ({
            value: item?.value,
            label: item?.name,
          }))}
          required={true}
          disabled={readOnly}
        />

        <GridContainer gap="gapFour">
          <FormInput
            label="First Name"
            name="first_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Middle Name"
            name="middle_name"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Last Name"
            name="last_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />
        </GridContainer>

        <GridContainer grid="two" gap="gapFour">
          <Form.Item
            label="Birth Date"
            name="birth_date"
            rules={[{ required: true, message: `Birth Date is required` }]}
          >
            <DatePicker
              inputReadOnly={true}
              className="w-full"
              format={DATE_FORMAT}
              onChange={(value: Dayjs | null, dateString?: string) => {
                const selectedDate = dayjs(dateString);
                setMemberDetails({
                  ...memberDetails,
                  birth_date: selectedDate,
                });
              }}
              value={memberDetails?.birth_date || null}
              disabled={readOnly}
            />
          </Form.Item>

          <FormInput
            label="Contact Number"
            name="contact_number"
            required={true}
            addonBefore="+63"
            onChange={onChange}
            maxLength={10}
            disabled={readOnly}
          />
        </GridContainer>

        <FormInput
          label="Email"
          name="email"
          required={true}
          type="email"
          onChange={onChange}
          disabled={readOnly}
        />

        <SelectNationality
          onChange={(v) =>
            setMemberDetails({ ...memberDetails, citizenship: v })
          }
          disabled={readOnly}
        />
        <SelectTier
          onChange={(v: any) => setMemberDetails({ ...memberDetails, tier: v })}
          name="tier"
          disabled={readOnly}
        />

        <FormInput
          label="Physical Address"
          name="physical_address"
          required={false}
          onChange={(e) =>
            setMemberDetails({
              ...memberDetails,
              physical_address: e.target.value,
            })
          }
          disabled={readOnly}
        />

        <SelectContainer
          label="Province"
          name="province"
          onChange={handleProvince}
          option={provinceDetails.map((item) => ({
            value: item?.province_name,
            label: item?.province_name,
          }))}
          disabled={readOnly}
          required={true}
        />

        <SelectContainer
          label="City"
          name="city"
          onChange={(v: any) => setMemberDetails({ ...memberDetails, city: v })}
          option={cityDetails.map((item) => ({
            value: item?.municipality_name,
            label: item?.municipality_name,
          }))}
          disabled={readOnly}
          required={true}
        />

        <GridContainer grid="two" gap="gapFour">
          <FormInput
            label="Member Points"
            name="points"
            required={false}
            maxLength={4}
            onKeyPress={onlyNumberKey}
            disabled={true}
            onChange={(e) =>
              setMemberDetails({
                ...memberDetails,
                MemberPoints: [
                  {
                    ...memberDetails.account,
                    points: e.target.value,
                  },
                ],
              })
            }
          />

          <SelectContainer
            label="Member Status"
            name="member_status"
            onChange={(v: any) =>
              setMemberDetails({ ...memberDetails, member_status: v })
            }
            option={MEMBER_STATUS.map((item) => ({
              value: item?.value,
              label: item?.name,
            }))}
            required={true}
            disabled={readOnly}
          />
        </GridContainer>

        <div className="mb-4 p-4 border-2 border-solid border-slate-100 rounded">
          <div className="flex justify-between mb-2">
            <Span weight="semi" margin="y-auto">
              Status
            </Span>
            <Form.Item
              style={{ margin: 0 }}
              name="is_active"
              valuePropName={memberDetails?.is_active ? "checked" : ""}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={(v) =>
                  setMemberDetails({
                    ...memberDetails,
                    is_active: v,
                  })
                }
                disabled={readOnly}
              />
            </Form.Item>
          </div>

          <div className="flex justify-between mb-2">
            <Span weight="semi" margin="y-auto">
              OFW Family
            </Span>
            <Form.Item
              style={{ margin: 0 }}
              name="ofw_family"
              valuePropName={memberDetails?.ofw_family ? "checked" : ""}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={(v) =>
                  setMemberDetails({
                    ...memberDetails,
                    ofw_family: v,
                  })
                }
                disabled={readOnly}
              />
            </Form.Item>
          </div>

          <div className="flex justify-between mb-2">
            <Span weight="semi" margin="y-auto">
              Vista Owner
            </Span>
            <Form.Item
              style={{ margin: 0 }}
              name="vista_owner"
              valuePropName={memberDetails?.vista_owner ? "checked" : ""}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={(v) =>
                  setMemberDetails({
                    ...memberDetails,
                    vista_owner: v,
                  })
                }
                disabled={readOnly}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
