import {
  Button,
  CardContent,
  CenterContent,
  GridContainer,
  Span,
} from "@common";
import { Bg, InputContent, Loader } from "@components";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { getUserById, updateUser } from "@api"; 

export const ProfilePage = () => {

  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  
  const [userDetails, setUserDetails] = useState({}) as any;
  const [details, setDetails] = useState({}) as any;
  const [isLoading, setIsLoading] = useState(false);
  
  const userInfo: any = useSelector((state: RootState) => state.User.userInfo); 
 
  const getData = async () => {
    try {
      setIsLoading(true);
      const usersData = await getUserById(userInfo?.id);  
      setUserDetails(usersData);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setDetails({
      ...details,
      [name]: value,
    });
  };

  useEffect(() => {
    const details = {
      id: userDetails?.id,
      username: userDetails?.username,
      first_name: userDetails?.first_name, 
      last_name: userDetails?.last_name,
      email: userDetails?.email,
    };
    setUserDetails(details);
    form.setFieldsValue(details);
  }, [userDetails?.id]); 
  
  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const res = await updateUser(userDetails.id, details);
      if (res) {  
        dispatch.UI.setAlert({
          message: res?.message ?? "User Info updated!",
          type: "Success",
      });
    }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable update user!",
        type: "Error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <Span size="3xl" weight="bold">
          Welcome,
        </Span>
        <Span  size="3xl" weight="bold" transform="capitalize"> {userInfo?.username}!</Span>

        <CenterContent bg="transparent" margin="top">
          <CardContent bg="none">
            <div className="p-8 bg-light">
              <div className="mx-2">
                <p>
                  <Span size="xl">Profile</Span>
                </p>
                <Span color="gray">Update your details</Span>
                <hr className="w-full border-t my-6" />
              </div>

              <Form scrollToFirstError layout="vertical" form={form}>
              <GridContainer grid="two">
                <div className="mx-2">
                  <Form.Item
                    name="username"
                    rules={[{ required: true }]}
                    className="w-full"
                  >
                    <InputContent 
                      name="username" 
                      type="text" 
                      label="Username" 
                      required={false}  
                      disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    name="first_name"
                    rules={[{ required: true }]}
                    className="w-full"
                  >
                    <InputContent 
                      name="first_name" 
                      type="text" 
                      label="First name" 
                      required={true}
                      onChange={onChange}
                      />
                      
                  </Form.Item>
                </div>

                <div className="mx-2">  
                  <Form.Item
                    name="email"
                    rules={[{ required: true }]}
                    className="w-full"
                  >
                  <InputContent 
                    name="email" 
                    type="text" 
                    label="Email" 
                    required={false} 
                    disabled={true}
                  />
                  </Form.Item> 

                  <Form.Item
                    name="last_name"
                    rules={[{ required: true }]}
                    className="w-full"
                  >
                    <InputContent 
                      name="last_name" 
                      type="text" 
                      label="Last name" 
                      required={true} 
                      onChange={onChange}
                    />
                  </Form.Item> 
                </div> 
              </GridContainer>
              </Form>

              <div className="flex justify-end">
                <Button
                  width="none"
                  padding="px-6" 
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </div>
            </div>
          </CardContent>
        </CenterContent>
      </div> 
    </> 
  );
};
