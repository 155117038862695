import { ReportForm} from '@components'
import { generateKeyword } from '@constant';
import { useState } from 'react';  
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { useHistory } from "react-router-dom"  

export const StoreTransactionsPage = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false); 
    const dispatch = useDispatch<Dispatch>();
    const initialState = {
      branch_code: "",
      company_code: "",
      branch_name: "",
      address: "",
      is_active: true, 
  };
    const [reportsDetails, setReportsDetails] = useState({
      branch_code: ""
    } as any);  
    const tableName = 'store'  
    const history = useHistory(); 
      
    const handeReport = async () => {
        try { 
            setIsLoading(true); 
            dispatch.Table.updatePageIndex({ table: tableName, value: 1 }); 
            dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
            
            const keyword = generateKeyword(reportsDetails)
 
            dispatch.Table.updateKeyword({ table: tableName, value: keyword });
            dispatch.Table.getStore({
                table: tableName
            }) 
          history.push(`/reports/storeList`); 
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };     
     
  return ( 
    <ReportForm
      title='Store Transactions' 
      handleReport={handeReport} 
      setReportsDetails={setReportsDetails} 
      reportsDetails={reportsDetails}
      isLoading={isLoading}
    /> 
  )
}

