import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchContent, TablePagination } from "@components";
import { Button, } from "antd";
import { FaPlus } from "@icons";
import { useEffect, useMemo, useState } from "react";

import { COLUMNS } from "./Table/columns";  
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { POSAdd } from "./Add/POSAdd";
import { POSEdit } from "./Edit/POSEdit";

export const PointOfSalePage = () => {
  const column = useMemo(() => COLUMNS, []); 
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false); 
  const dispatch = useDispatch<Dispatch>();
  const tableName = 'pos' 
  
  const [searchData, setSearchData] = useState({
    filter: 'keyword',
    value: ''
  })   
  
  const posData: any[] = useSelector(
    (state: RootState) => state.Table.tables.pos.data
  ); 
  
  const id: any = useSelector(
    (state: RootState) => state.UI.forms.editPOS?.id
  );     
  
  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const showDrawer = () => {
    setOpenModal(true);
  }; 

  const getPOSData = async () => {
    try {
      setIsLoading(true); 
      dispatch.Table.getPOS({
        table: tableName
      })
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.UI.updateFormId({
      target: "editPOS",
      value: null,
    });
    dispatch.Table.updateKeyword({ table: tableName, value: '' });
    getPOSData();
  }, []);  

  
  const setFilters = () => {
    setIsLoading(true);
    const keyvalue = searchData?.value === "" ? "" : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    }) 
    setIsLoading(false);
    getPOSData();
  };

  useEffect(() => {   
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    }) 
    setSearchData({ filter: 'keyword', value: '' });
  }, []);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "pos_code",
      label: "POS Code",
    },
    {
      value: "pos_name",
      label: "POS Name",
    },
    {
      value: "branch_code",
      label: "Branch Code",
    },
    {
      value: "company_code",
      label: "Company Code",
    }, 
    {
      value: "is_active",
      label: "Status",
    },
  ]; 

  return (<>
    <Loader isLoading={isLoading} />
    <div className="main">
      <GridContainer grid="two"> 
        <Span weight="bold" size="2xl">
          Point of Sales
        </Span>
        <div className="search-bar"> 
          <SearchContent
            searchOptions={searchOptions} 
            setFilters={setFilters} 
            setSearchData={setSearchData} 
            searchData={searchData}
          />

          {userAccess?.pos_add &&
          <Button 
            onClick={showDrawer}
            type="primary"  
            size="large"
          >
            <div className="flex pr-2 items-center">
              <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
              Add
            </div>
          </Button>
          }
        </div>
      </GridContainer>
 
      {posData?.length > 0 ? (
        <TablePagination columns={column} data={posData} dataType={tableName}/>
      ) : (
        <NoData />
      )}
      {/* Add */}
      <POSAdd openModal={openModal} setOpenModal={setOpenModal} getData={getPOSData}/>
       
      {/* Edit */}
      {id &&  <POSEdit getData={getPOSData} id={id}/>}
    </div>
  </>);
};
