import { Strings } from "@constant";
import { RedeemPointsPage } from "@containers";
import { useEffect } from "react";

export const RedeemPoints = () => { 
  
  useEffect(() => {
    document.title = Strings.pageTitle("Redeem Points")
  })

  return (
    <>
        <RedeemPointsPage />
    </>
  );
};
