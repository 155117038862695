import { CardContainer, CenterContent, Span } from "@common";
import { BRAND_IMAGES } from "@constant"; 
import './setaccount.scss';  
import { ContactUs, ErrorAccount } from "@components"; 
import { useEffect, useState } from "react";
import { postEmailValidation } from "@api";

export const EmailNotifPage = () => {
  const [isLoading, setIsLoading] = useState(false); 
  const [accessValidation, setAccessValidation] = useState(false);  
  
  var t = window?.location?.search?.match(/(\?|&)accessToken=([^&]*)/) ?? [];
  var access_token = t[2];  

  const getAccess = async () => {
    setIsLoading(true);
    try {
      const res = await postEmailValidation(access_token);
      if (!res) {
        setAccessValidation(false);
      }
    } catch {
      setAccessValidation(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccess();
  }, []);
  
  return (
    <>
        <CenterContent height="screen" bg="white"> 
        {!accessValidation ? (<>
            <CardContainer>      
            <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="setaccount-logo"/>
            
            <p className="text-center my-16">
            <Span size="3xl" weight="bold" font="montserrat" color="green">
                Congrats!
            </Span>
            </p>  
            <p className="text-center my-16">
              <Span size="base" color="gray">
                Thank you for choosing AllRewards.
                You are now verified, kindly refresh the app.
              </Span>
            </p> 
            </CardContainer>

            <div className="flex min-h-screen justify-left items-center font-montserrat setaccount-contact"> 
            <ContactUs />
            </div>  ) 
            </>): ( 
          <ErrorAccount
            title="Link Expired" 
            subtitle="Try requesting new one. Please be reminded that it is only valid for 5 minutes."
          />
        )}
  
  
        </CenterContent> 
        
      </>
    );
  };
  
