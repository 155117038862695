export function getEnv(key: string): string {
	return process.env[key] as string;
}

export const env = {
	nodeEnv: getEnv("NODE_ENV"),
	app: {
		name: getEnv("REACT_APP_NAME"),
		version: getEnv("REACT_APP_VERSION"),
	},
	api: {
		baseUrl: getEnv("REACT_APP_API_BASE_URL"),
	},
};