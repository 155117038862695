import { createModel } from "@rematch/core";
import { RootModel } from "./";
import moment from "moment"; 

interface IErrorMessagePayload {
    target: string;
    value: string;
} 

interface IModalUpdateIdPayload {
  target: string;
  value: string | null;
}

  
interface IAlertPayload {
  message: string;
  type: any;
}

interface IModalIsRequestingPayload {
    target: string;
    value: boolean;
  }

interface IFormPayload {
    target: string;
    field: string;
    value: any;
}

const initialState = { 
    forms: {
      login: { 
        errorMessage: "",
        fields: {
            username: "",
            password: "",  
        },
      }, 
      editUser: {
        id: null,
      },
      editBusinessUnit: {
        id: null,
      },
      editBranch: {
        id: null,
      },
      editPOS: {
        id: null,
      }, 
      editTiers: {
        id: null,
      },
      editPromo: {
        id: "",
      },
      editMember: {
        id: ""
      }, 
      editRole: {
        id: ""
      }, 
    },
    alert: {
      message: "",
      type: "",
      alertWatcher: moment.now(),
    },
    isLoading: false, 
    promoCode: "",
    promoModal: false,
    isNewLogin: true,
    isDeleteTransaction: false,
    isEditTransaction: false,
    manualId: "",
    reference_number: "",
    action: "" //type
} as any;

export const UI = createModel<RootModel>()({
    state: initialState,
    reducers: {
      resetState(){
          return { ...initialState }
      },
      updateState(state, newState) {
        return {
          ...state,
          ...newState,
        };
      },
      updateFormId(state, payload: IModalUpdateIdPayload) {
        const { target, value } = payload;
        return {
          ...state,
          forms: {
            ...state.forms,
            [target]: { ...state.forms[target], id: value },
          },
        };
      },
      /**
     * @name resetForm
     * @description resets from by passing target property underform
     * @param target
     */
    resetForm(state, target: string) {
      return {
        ...state,
        forms: { ...state.forms, [target]: { ...initialState.forms[target] } },
      };
    },
    resetAlert(state){
      return{
        ...state,
        alert: {...state.alert, message: "", type: ""}
      }
    },
        setInputFormFields(state, payload: IFormPayload) {
            const { target, field, value } = payload;
    
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [target]: {
                    ...state.forms[target],
                    fields: { ...state.forms[target].fields, [field]: value },
                    },
                },
            };
        },
        setAlert(state, { message, type }: IAlertPayload) {
          return {
            ...state,
            alert: { ...state.alert, message, type, alertWatcher: moment.now() },
          };
        },
        setIsUserLoading(state, payload: IModalIsRequestingPayload) {
            const { target, value } = payload;
      
            return {
              ...state,
              forms: {
                ...state.forms,
                [target]: { ...state.forms[target], isRequesting: value },
              },
            };
        },
        setErrorMsg(state, payload: IErrorMessagePayload) {
        const { target, value } = payload;
    
        return {
            ...state,
            forms: {
            ...state.forms,
            [target]: { ...state.forms[target], errorMessage: value },
            },
        };
        },
    }, 
});