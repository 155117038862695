import * as React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  color?: "default" | "gray" | "gray-primary" | "white" | "green" | "red";
  size?: "xs" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
  weight?: "semi" | "bold" | "extrabold" | "black";
  whitespace?: "nowrap";
  margin?: "auto" | "y-auto";
  padding?: "px-2" | "pr-2";
  transform?: "capitalize" | "uppercase"
  font?: "montserrat"
}; 

let getColor = (color: Props["color"]) => {
  switch (color) {  
    case "red":
      return "text-red-500";
    case "green":
      return "text-green-primary";
    case "white":
      return "text-white";
    case "gray-primary":
      return "text-gray-primary";
    case "gray":
      return "text-gray-700";
    default:
      return "text-black";
  }
};

let getFontSize = (size: Props["size"]) => {
  switch (size) {
    case "5xl":
      return "text-5xl";
    case "4xl":
      return "text-4xl";
    case "3xl":
      return "text-3xl";
    case "2xl":
      return "text-2xl";
    case "xl":
      return "text-xl";
    case "lg":
      return "text-lg";
    case "base":
      return "text-base";
    case "xs":
      return "text-xs";
    default:
      return "text-sm";
  }
};

let getFontWeight = (weight: Props["weight"]) => {
  switch (weight) { 
    case "black":
      return "font-black";
    case "extrabold":
      return "font-extrabold";
    case "bold":
      return "font-bold";
    case "semi":
      return "font-semibold";
    default:
      return "font-normal";
  }
};

let getWhiteSpace = (whitespace: Props["whitespace"]) => {
  switch (whitespace) {
    case "nowrap":
      return "whitespace-nowrap";
    default:
      return "whitespace-normal";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "y-auto":
      return "my-auto";
    case "auto":
      return "m-auto";
    default:
      return "m-0";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "pr-2":
      return "pr-2";
    case "px-2":
      return "px-2";
    default:
      return "p-0";
  }
};

let getTransform = (transform: Props["transform"]) => {
  switch (transform) {
    case "capitalize":
      return "capitalize";
    case "uppercase":
      return "uppercase";
    default:
      return "normal-case";
  }
};


let getFont = (font: Props["font"]) => {
  switch (font) {
    case "montserrat":
      return "font-montserrat"; 
    default:
      return "";
  }
};

export const Span = ({
  color,
  size,
  weight,
  whitespace,
  margin,
  padding,
  transform,
  font,
  children,
}: Props) => {
  return (
    <span
      className={classnames(
        getFont(font),
        getColor(color),
        getFontSize(size),
        getFontWeight(weight),
        getWhiteSpace(whitespace),
        getMargin(margin),
        getPadding(padding),
        getTransform(transform)
      )}
    >
      {children}
    </span>
  );
};
