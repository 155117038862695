import { PromotionPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Promotion = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Promotion");
  });

  return (
    <>
      <PromotionPage />
    </>
  );
};
