import { Separator, Span } from '@common'
import { Bg, ReportForm } from '@components'  
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { useHistory } from "react-router-dom";
import { formatDateSlash, generateKeyword } from "@constant";
import { useState } from "react";

export const EarnPointsPage = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const dispatch = useDispatch<Dispatch>();
  const tableName = 'points'  
  const history = useHistory(); 
  
  const [reportsDetails, setReportsDetails] = useState({} as any); 
  
  const handeReport = async () => {
    try { 
        setIsLoading(true); 
        dispatch.Table.updatePageIndex({ table: tableName, value: 1 }); 
        dispatch.Table.updatePageLimit({ table: tableName, value: 10 });

        const keyword = generateKeyword(reportsDetails)

        dispatch.Table.updateKeyword({ table: tableName, value: keyword });
        dispatch.Table.getPoints({
            table: tableName
        }) 
      history.push(`/reports/pointsList`); 
    } catch (error) {
        throw error;
    } finally {
        setIsLoading(false);
    }
};         


  return ( 
    <ReportForm 
      title='Earn Points'
      handleReport={handeReport} 
      setReportsDetails={setReportsDetails} 
      reportsDetails={reportsDetails}
      isLoading={isLoading}
    /> 
  )
}

