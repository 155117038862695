import { OfferPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Offer = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Offer");
  });

  return (
    <>
      <OfferPage />
    </>
  );
};
