import "./SideBar.scss";
import { BRAND_IMAGES } from "@constant";
import { SidebarItem } from "./SidebarItem";
import { SideBarData } from "./SideBarData";

import { useSelector } from "react-redux";
import { RootState } from "@store";

export const SideBar = () => {
  const roleModules = useSelector((state: RootState) => state.User.access); 

  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      <div className="sidebar">
        <div className="sidebar-brand">
           
          <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="logo" className="logo" /> 
        <span>WEB ADMIN</span>
        </div>

        <ul>
          {SideBarData?.map((item, index) =>
            roleModules[item?.permission] || item?.permission === "" ? (
              <SidebarItem item={item} key={index} />
            ) : <></>
          )}
        </ul>
      </div>
    </>
  );
};
