import { useEffect, useState } from "react"; 

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import './upload.scss'

const getBase64 = async (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

type Props = { 
  setFile: Function;
  openModal: boolean;
  defaultImageurl?: any;
  disabled?: boolean;
};

export const UploadPhoto = ({ 
  setFile,  
  openModal,
  defaultImageurl,
  disabled
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        setFile(info.file.originFileObj);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );  

  useEffect(() => {
    if (!openModal) {
      setImageUrl(null);
    }
  }, [openModal]);

  return (
    <Upload
      className='content-upload text-center'
      name="avatar"
      listType="picture-card" 
      showUploadList={false}
      customRequest={dummyRequest}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      maxCount={1} 
      disabled={disabled}
    >
      {imageUrl || defaultImageurl ? (
        <img
          src={imageUrl ?? defaultImageurl}
          alt="avatar"
          style={{
            width: "100%",
          }}
          onError={() => {
            setImageUrl(null); 
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};
