import { GridContainer, Separator, Span } from "@common";
import { Loader, TransactionDetails, TransactionLabel } from "@components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { getTransactionById } from "@api";
import { Collapse } from "@mui/material";
import { IoIosArrowDown, IoIosArrowUp } from "@icons";
import { formatDateTime } from "@constant";

export const ListTransactionDetailsPage = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();

  const [transactionDetails, setTransactionDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);


  const action = useSelector((state: RootState) => state.UI.action);

  const getBusinessData = async () => {
    setisloading(true);
    try {
      const res = await getTransactionById(action, id);
      setTransactionDetails(res);
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getBusinessData();
  }, [id]); 

  return (
    <>
      <TransactionDetails data={transactionDetails} isLoading={isLoading} title="Transaction"/>
    </>
  );
};
