import { Form, Select } from "antd";

type OptionProps = {
    name: string
    value: string
}

type Props = { 
  onChange?: any;
  name?: string;
  label?: string;
  required?: boolean; 
  option?: OptionProps[];
  otherOption?: any;
  mode?: any;
  onSearch?: any;
}; 


const { Option } = Select;

export const FormSelect = ({   
  onChange,
  name,
  label,
  required, 
  option,
  otherOption,
  mode,
  onSearch
}: Props) => {
  return ( 
    <Form.Item
      label={label}
      name={name}
      rules={[{ 
        required: required,
        message: `${label} is required`,
      }]}
    >
      <Select
        className="w-full"
        placeholder={label}
        onChange={onChange}
        getPopupContainer={(trigger) => trigger.parentNode} 
        mode={mode}
        showSearch
        onSearch={onSearch}
      > 
        {option ? 
          option?.map((type, index) => (
            <Option key={index} value={type.value}>
            {type.name}
            </Option>
          ))
        :
          otherOption
          ?.sort((a: any, b: any) => a.localeCompare(b))
          .map((a: any) => (
            <Option key={a} value={a}>
              {a}
            </Option>
          )) 
        }
      </Select>
    </Form.Item> 
  );
};