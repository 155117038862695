import { BranchPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Branch = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Branch");
  });

  return (
    <>
      <BranchPage />
    </>
  );
};
