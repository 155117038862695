import { Strings } from "@constant";
import { ListManualPage } from "@containers";
import { useEffect } from "react";

export const ManualAdjustment = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Manual Adjustment")
  })
  
  return (
    <>
      <ListManualPage />
    </>
  );
};
