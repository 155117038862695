import { Span } from "@common";
import { FormInput, SelectRole} from "@components";
import { Button, Drawer, Form, Popconfirm, Space, Switch } from "antd"; 
import { useState } from "react";
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { addUserAdmin } from "@api";
import "../index.scss"; 
  
type Props = { 
  addUserModal: boolean;
  setAddUserModal: Function;
  getUser: Function;
};

export const UserAdminAdd = ({addUserModal, setAddUserModal, getUser}: Props) => {  
    const dispatch = useDispatch<Dispatch>();

  const [form]: any = Form.useForm(); 
 
  const initialState = { 
    email: "",
    first_name: "",
    last_name: "",
    is_active: true, 
    role_name: "", 
  };

  const [userDetails, setUserDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);

  const handleClose = () => { 
    setisloading(false); 
    setUserDetails(initialState);
    setAddUserModal(false)   
    getUser();
  } 
  
  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };  
  
  const handeAddUserAdmin = async () => {
    try {
      setisloading(true); 
      const res = await addUserAdmin({
        ...userDetails 
      }); 
      if (res.success) { 
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new user!",
          type: "Success",
        });  
      }
    } catch (err: any) {  
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new user!",
        type: "Error",
      });  
      setisloading(false); 
    }
        handleClose()
  };

  

  return (  
      <Drawer
        title="Add User Administration"
        placement="right"
        width={500}
        onClose={handleClose} 
        open={addUserModal}
        footer={
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in user administration?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}
                >
                Cancel
                </Button>
              </Popconfirm>
              <Button 
              onClick={form.submit} 
              type="primary" 
              loading={isLoading}
              >
                  Add
              </Button>
              </Space>
          </div>
        }
      >
        <Form
        onFinish={() => {
          form.resetFields();
          handeAddUserAdmin();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form} 
        >   
          <FormInput label="First Name" name="first_name" required={true} onChange={onChange} /> 
          <FormInput label="Last Name" name="last_name" required={true} onChange={onChange} /> 
          
          <FormInput label="Email" name="email" required={true} type="email" onChange={onChange} />  
 
          <SelectRole
            onChange={(v: any) => setUserDetails({ ...userDetails, role_name: v })}  
          />   

          <div className='flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded'>
            <Span weight='semi'>User Status</Span>

            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={userDetails.is_active}
              onChange={(v) =>
              setUserDetails({ ...userDetails, is_active: v })
            }/>
          </div>
  
        </Form>
      </Drawer>  
  );
};
