import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchContent, TablePagination } from "@components";
import { Button } from "antd";
import { FaPlus } from "@icons";
import { useEffect, useMemo, useState } from "react";

import { COLUMNS } from "./Table/columns";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { TiersAdd } from "./Add/TiersAdd";
import { TiersEdit } from "./Edit/TiersEdit";

export const TiersPage = () => {
  const column = useMemo(() => COLUMNS, []);

  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const tableName = "tiers";

  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const tiersData = useSelector(
    (state: RootState) => state.Table.tables.tiers.data
  );

  const id: any = useSelector(
    (state: RootState) => state.UI.forms.editTiers?.id
  );

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const getTiersData = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getTiersList({
        table: "tiers",
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.UI.updateFormId({
      target: "editTiers",
      value: null,
    });
    dispatch.Table.updateKeyword({ table: tableName, value: "" });
    getTiersData();
  }, []);

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getTiersData();
  };

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "tier",
      label: "Tier",
    },
    {
      value: "tier_group_code",
      label: "Tier Group Code",
    },
    {
      value: "group_sequence",
      label: "Group Sequence",
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Member Tier Configuration
          </Span>

          <div className="search-bar">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
            />

            {userAccess?.tier_add && (
              <Button
                onClick={() => setOpenModal(true)}
                type="primary"
                size="large"
              >
                <div className="flex pr-2 items-center">
                  <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
                  Add
                </div>
              </Button>
            )}
          </div>
        </GridContainer>

        {tiersData?.length > 0 ? (
          <TablePagination
            columns={column}
            data={tiersData}
            dataType={tableName}
          />
        ) : (
          <NoData />
        )}

        {/* Add */}
        <TiersAdd
          openModal={openModal}
          setOpenModal={setOpenModal}
          getData={getTiersData}
        />

        {/* Edit */}
        <TiersEdit getData={getTiersData} table={tableName} id={id} />
      </div>
    </>
  );
};
