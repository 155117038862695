import { AccountReportsListPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const AccountReportsList = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Account Reports List");
  });

  return (
    <>
      <AccountReportsListPage />
    </>
  );
};
