import {  
    Logout, 
    SetAccount,
    ResetPassword,
    EmailNotif,
    Profile, 
    Branch, 
    Business, 
    PointOfSale, 
    Promotion, 
    Tiers, 
    MemberManagement, 
    StoreTransactions,
    EarnPoints, 
    EarnList,
    RedeemPoints,
    UserProfile,
    RoleManagement,
    StoreList,
    RedeemPointsList,
    Voucher,
    Offer,
    AccountReports,
    AccountReportsList, 
    ListAccount,
    ListTransaction,
    ListTransactionDetails,
    StoreTransaction,
    StoreDetails,
    ManualAdjustment,
    ManualAdjustmentDetails
} from "@pages";

export const Routes = [
    { 
        component: Logout,
        path: '/logout'
    },  
    { 
        component: SetAccount,
        path: '/set-account'
    }, 
    { 
        component: ResetPassword,
        path: '/set-password'
    },  
    { 
        component: EmailNotif,
        path: '/verify-email'
    },
];  
 
export const AuthRoutes = [
    { 
        component: Profile,
        path: '/profile'
    },  
    { 
        component: UserProfile,
        path: '/userprofile/:id?'
    },  
    { 
        component: RoleManagement,
        path: '/role'
    },  
    { 
        component: Business,
        path: '/business/enrollment'
    },  
    { 
        component: Branch,
        path: '/business/branch'
    },
    { 
        component: PointOfSale,
        path: '/business/pos'
    },  
    { 
        component: Promotion,
        path: '/promotion'
    },  
    { 
        component: Tiers,
        path: '/tiers'
    },
    { 
        component: MemberManagement,
        path: '/members'
    },   
    { 
        component: StoreTransactions,
        path: '/reports/storetransactions'
    },  
    { 
        component: StoreList,
        path: '/reports/storeList'
    },
    { 
        component: EarnPoints,
        path: '/reports/earnpoints'
    },
    { 
        component: EarnList,
        path: '/reports/pointsList'
    },  
    { 
        component: RedeemPoints,
        path: '/reports/redeempoints'
    },
    { 
        component: RedeemPointsList,
        path: '/reports/redeemList'
    },
    { 
        component: AccountReports,
        path: '/reports/account'
    },
    { 
        component: AccountReportsList,
        path: '/reports/accountList'
    },
    { 
        component: Voucher,
        path: '/content/voucher'
    },
    { 
        component: Offer,
        path: '/content/offer'
    },
    { 
        component: ListAccount,
        path: '/transaction/account'
    },
    { 
        component: ListTransaction,
        path: '/transaction/accounts/:id?'
    },  
    { 
        component: ListTransactionDetails,
        path: '/transaction/details/:id?'
    },  
    { 
        component: StoreTransaction,
        path: '/transaction/store'
    },  
    { 
        component: StoreDetails,
        path: '/transaction/stores/details/:id?'
    },  
    { 
        component: ManualAdjustment,
        path: '/transaction/manual'
    },  
    { 
        component: ManualAdjustmentDetails,
        path: '/transaction/adjustment'
    },  
];      