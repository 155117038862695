import { Button, CardContainer, CenterContent, Span } from "@common";
import { BRAND_IMAGES } from "@constant";
import { Dispatch } from "@store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; 
import './setaccount.scss'; 
import { Form } from "antd";
import { ContactUs, ErrorAccount, InputContent, Loader, PasswordInput } from "@components";
import { postAccessValidation, postSetAccount } from "@api"; 
import { SuccessAccount } from "@components";

export const SetAccountPage = () => {
  const dispatch = useDispatch<Dispatch>(); 
  const [form]: any = Form.useForm();   
  
  var t = window?.location?.search?.match(/(\?|&)accessToken=([^&]*)/) ?? [];
  var access_token = t[2]; 
  
  const initialState = {  
    username: "",  
    password: "",
    confirm_password: "",
    accessToken: ""
  }

  const [accountDetails, setAccountDetails] = useState(initialState);
  const [accessValidation, setAccessValidation] = useState(false); 
  
  const [isLoading, setIsLoading] = useState(false);  
  const [success, setSuccess] = useState(false);  
  const [error, setError] = useState("");
  const getAccess = async () => {
    setIsLoading(true);
    try {
      const res = await postAccessValidation(access_token);
      if (!res) {
        setAccessValidation(false);
      }
    } catch {
      setAccessValidation(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccess();
  }, []);

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setAccountDetails({
    ...accountDetails,
    [name]: value,
    });
};     

  let handelSetAccount = async () => {
    setIsLoading(true);
    try {
      const result = await postSetAccount({...accountDetails, accessToken: access_token});  
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully set new account!",
          type: "Success",
        });
        setSuccess(true)
      } 
    } catch (err: any) {   
      setError(err ? err : "Invalid credentials!");
    }
    setIsLoading(false);
  }; 
 
  
  const isWithValue = !accountDetails?.password.length || !accountDetails?.confirm_password.length;
  const isPasswordMatch = isWithValue || accountDetails?.password === accountDetails?.confirm_password;
  const validation = !accountDetails?.username || !accountDetails?.password || !accountDetails?.confirm_password; 

  return (
    <> 
      <Loader isLoading={isLoading} />
      <CenterContent height="screen" bg="white">
        {!accessValidation ? 
          !success ? (<>
            <CardContainer>      
            <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="setaccount-logo"/>
            
            <p className="text-center my-6">
            <Span size="3xl" weight="bold" font="montserrat" color="green">
                Set up your AllRewards Admin Password
            </Span>
            </p> 

            {error && <> 
              <p className="text-center my-4">
                <Span size="base" color="red">{error}</Span> 
              </p>
            </>}

            {!isPasswordMatch && 
              <p className="text-center my-4">
                <Span size="base" color="red"><>Mismatch Password!</></Span> 
              </p>
            }

            <Form
              onFinish={handelSetAccount}
              wrapperCol={{ span: 24 }}
              layout="vertical"
              form={form} 
              >  
              <InputContent 
                required={true} 
                label="Username" 
                name="username" 
                classNames="font-montserrat"
                type="text" 
                onChange={onChange}
              /> 

              <PasswordInput 
                classNames="font-montserrat"
                required={true} 
                label="Password" 
                name="password" 
                onChange={onChange}
              /> 

              <PasswordInput 
                classNames="font-montserrat"
                required={true} 
                label="Confirm Password" 
                name="confirm_password" 
                onChange={onChange} 
              />
  
            </Form>

            <div className="flex justify-end">
              <Button
                padding="px-6" 
                bg="green"
                onClick={handelSetAccount}
                disabled={validation || !isPasswordMatch}
              >
                CONFIRM
              </Button>
            </div>  
            </CardContainer>

            <div className="flex min-h-screen justify-left items-center font-montserrat setaccount-contact"> 
              <ContactUs />
            </div> 
          </>):(
            <SuccessAccount
              title="Account Created!" 
              subtitle="Your account has been created. You can now log in using the username and password you set."
            />
          )

        : (
          <ErrorAccount
            title="Unable to create account " 
            subtitle="The reset link is invalid or expired. Please contact your admin to request new link."
          />  
        )}  
      </CenterContent> 
      
    </>
  );
};
