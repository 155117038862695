export const COLUMNS = [ 
      {
        accessor: "account_number",
        Header: "Account Number",
      },
      {
        accessor: "account_name",
        Header: "Account Name",
      },
      {
        accessor: "points",
        Header: "Points",
      },  
];