import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchContent, TablePagination } from "@components";
import { FaPlus } from "@icons";
import { useEffect, useMemo, useState } from "react";

import { COLUMNS } from "./Table/columns";
import { Button } from "antd";
import { BranchConfigAdd } from "./Add/BranchConfigAdd";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { BranchConfigEdit } from "./Edit/BranchConfigEdit";

export const BranchPage = () => {
  const column = useMemo(() => COLUMNS, []);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const tableName = "branch";

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const branchData = useSelector(
    (state: RootState) => state.Table.tables.branch.data
  );

  const branchId: any = useSelector(
    (state: RootState) => state.UI.forms.editBranch?.id
  );

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const getBranchData = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getBranch({
        table: tableName,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.UI.updateFormId({
      target: "editBranch",
      value: null,
    });
    dispatch.Table.updateKeyword({ table: tableName, value: "" });
    getBranchData();
  }, []);

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getBranchData();
  };

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "branch_code",
      label: "Branch Code",
    },
    {
      value: "branch_name",
      label: "Branch Name",
    },
    {
      value: "company_code",
      label: "Company Code",
    },
    {
      value: "is_active",
      label: "Status",
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Branch Configuration
          </Span>
          <div className="search-bar">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
            />

            {userAccess?.branch_add && (
              <Button
                onClick={() => setOpenModal(true)}
                type="primary"
                size="large"
              >
                <div className="flex pr-2 items-center">
                  <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
                  Add
                </div>
              </Button>
            )}
          </div>
        </GridContainer>

        {branchData?.length > 0 ? (
          <TablePagination
            columns={column}
            data={branchData}
            dataType="branch-config"
          />
        ) : (
          <NoData />
        )}

        {/* Add */}
        <BranchConfigAdd
          openModal={openModal}
          setOpenModal={setOpenModal}
          getData={getBranchData}
        />

        {/* Edit */}
        {branchId && <BranchConfigEdit getData={getBranchData} id={branchId} />}
      </div>
    </>
  );
};
