import { GridContainer, Span } from "@common";
import {
  DeleteModal,
  FormInput,
  FormNumber,
  FormSelect,
  SelectBranchCode,
  SelectCompanyCode,
  SelectContainer,
} from "@components";
import { useEffect, useState } from "react";
import { Button, Drawer, Form, Popconfirm, Select, Space, Switch } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { addPOS, addTiers, deleteTier, updateTiers } from "@api";
import { CONDITION_TYPE, onlyNumberKey } from "@constant";

type Props = {
  id: string;
  getData: Function;
  table: string;
};

export const TiersEdit = ({ id, table, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [tiersDetails, setTiersDetails] = useState({} as any);
  const [isLoading, setisloading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const tiers = useSelector(
    (state: RootState) => state.Table.tables[table].data
  );
  const tiersData = tiers?.filter((d) => d?.tier_id === id)[0];

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(false);
    dispatch.UI.updateFormId({ target: "editTiers", value: null });
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTiersDetails({
      ...tiersDetails,
      [name]: value,
    });
  };

  const handeEditTiers = async () => {
    try {
      setisloading(true);
      const res = await updateTiers(id, { ...tiersDetails });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit tiers!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err.error ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  useEffect(() => {
    const details = {
      tier: tiersData?.tier,
      tier_group_code: tiersData?.tier_group_code,
      tier_group_name: tiersData?.tier_group_name,
      group_sequence: tiersData?.group_sequence,
      multiplier: tiersData?.multiplier,
      condition_amount: tiersData?.condition_amount,
      condition_type: tiersData?.condition_type,
      condition_duration: tiersData?.condition_duration,
    };
    setTiersDetails(details);
    form.setFieldsValue(details);
  }, [id]);

  const handleDelete = async () => {
    setisloading(true);
    try {
      const result = await deleteTier(id);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    setisloading(false);
    setIsModalDelete(false);
    handleClose();
  };

  const readOnly = !userAccess?.tier_update;
  return (
    <>
      <Drawer
        title={
          readOnly
            ? "View Member Tier Configuration"
            : "Edit Member Tier Configuration"
        }
        placement="right"
        width={500}
        onClose={handleClose}
        open={id ? true : false}
        footer={
          readOnly ? null : (
            <div className="flex justify-center">
              <Space>
                <Popconfirm
                  placement="top"
                  title="Discard changes made in member tier configuration?"
                  onConfirm={handleClose}
                  okText="Discard"
                  cancelText="Cancel"
                >
                  <Button loading={isLoading}>Cancel</Button>
                </Popconfirm>

                {userAccess?.tier_update && (
                  <Button
                    onClick={form.submit}
                    type="primary"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                )}
              </Space>
            </div>
          )
        }
      >
        <Form
          onFinish={handeEditTiers}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Tier"
            name="tier"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />

          <FormInput
            label="Tier Group Name"
            name="tier_group_name"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />

          <GridContainer grid="two" gap="gapFour">
            <FormInput
              label="Tier Group Code"
              name="tier_group_code"
              required={true}
              wrapperCol={{ span: 24 }}
              maxLength={4}
              onChange={onChange}
              disabled={readOnly}
            />

            <FormNumber
              label="Group Sequence"
              name="group_sequence"
              required={true}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onChange={(v: number) => {
                setTiersDetails({ ...tiersDetails, group_sequence: v });
              }}
              disabled={readOnly}
            />

            <FormInput
              label="Multiplier"
              name="multiplier"
              required={true}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onKeyPress={onlyNumberKey}
              onChange={onChange}
              disabled={readOnly}
            />

            <FormNumber
              label="Condition Amount"
              name="condition_amount"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={7}
              onChange={(v: number) => {
                setTiersDetails({ ...tiersDetails, condition_amount: v });
              }}
              disabled={readOnly}
            />

            <SelectContainer
              label="Condition Type"
              name="condition_type"
              option={CONDITION_TYPE.map((item) => ({
                value: item?.value,
                label: item?.name,
              }))}
              mode="multiple"
              onChange={(v: any) =>
                setTiersDetails({ ...tiersDetails, condition_type: v })
              }
              disabled={readOnly}
              required={false}
            />

            <FormNumber
              label="Condition Duration"
              name="condition_duration"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onChange={(v: number) => {
                setTiersDetails({ ...tiersDetails, condition_duration: v });
              }}
              disabled={readOnly}
            />
          </GridContainer>

          {userAccess?.tier_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Member Tiers"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={isLoading}
        okLabel="Delete"
        subtitle={tiersDetails?.tier_group_name}
      />
    </>
  );
};
