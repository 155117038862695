import { Separator, Span } from '@common'
import { ReportForm } from '@components'  
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { useHistory } from "react-router-dom";
import { formatDateSlash, generateKeyword } from "@constant";
import { useState } from "react";
 
export const RedeemPointsPage = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const dispatch = useDispatch<Dispatch>();
  const tableName = 'redeem'  
  const history = useHistory(); 
  
  const [reportsDetails, setReportsDetails] = useState({} as any); 
  const handeReport = async () => {
    try { 
        setIsLoading(true); 
        dispatch.Table.updatePageIndex({ table: tableName, value: 1 }); 
        dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
 
        const keyword = generateKeyword(reportsDetails)

        dispatch.Table.updateKeyword({ table: tableName, value: keyword });
        dispatch.Table.getRedeemPoints({
            table: tableName
        }) 
      history.push(`/reports/redeemList`); 
    } catch (error) {
        throw error;
    } finally {
        setIsLoading(false);
    }
};    

  return (  
    <ReportForm 
      title='Redeem Points'
      handleReport={handeReport} 
      setReportsDetails={setReportsDetails} 
      reportsDetails={reportsDetails}
      isLoading={isLoading}
    />  
  )
}


