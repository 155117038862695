import { TreeDataNode } from "antd";

export const treeData: TreeDataNode[] = [
  {
    title: "User Admin",
    key: "0",
    children: [
      {
        title: "View",
        key: "user_administration_view",
      },
      {
        title: "Add",
        key: "user_administration_add",
      },
      {
        title: "Edit",
        key: "user_administration_update",
      },
      {
        title: "Delete",
        key: "user_administration_delete",
      },
    ],
  },
  {
    title: "Role Management",
    key: "1",
    children: [
      {
        title: "View",
        key: "role_view",
      },
      {
        title: "Add",
        key: "role_add",
      },
      {
        title: "Edit",
        key: "role_update",
      },
      {
        title: "Delete",
        key: "role_delete",
      },
    ],
  },
  {
    title: "Business Unit",
    key: "2",
    children: [
      {
        title: "View",
        key: "business_unit_view",
      },
      {
        title: "Add",
        key: "business_unit_add",
      },
      {
        title: "Edit",
        key: "business_unit_update",
      },
      {
        title: "Delete",
        key: "business_unit_delete",
      },
    ],
  },
  {
    title: "Branch Config",
    key: "3",
    children: [
      {
        title: "View",
        key: "branch_view",
      },
      {
        title: "Add",
        key: "branch_add",
      },
      {
        title: "Edit",
        key: "branch_update",
      },
      {
        title: "Delete",
        key: "branch_delete",
      },
    ],
  },
  {
    title: "POS",
    key: "4",
    children: [
      {
        title: "View",
        key: "pos_view",
      },
      {
        title: "Add",
        key: "pos_add",
      },
      {
        title: "Edit",
        key: "pos_update",
      },
      {
        title: "Delete",
        key: "pos_delete",
      },
    ],
  },
  {
    title: "Promo Config",
    key: "5",
    children: [
      {
        title: "View",
        key: "promotion_view",
      },
      {
        title: "Add",
        key: "promotion_add",
      },
      {
        title: "Edit",
        key: "promotion_update",
      },
      {
        title: "Delete",
        key: "promotion_delete",
      },
    ],
  },
  {
    title: "Member Tier Config",
    key: "6",
    children: [
      {
        title: "View",
        key: "tier_view",
      },
      {
        title: "Add",
        key: "tier_add",
      },
      {
        title: "Edit",
        key: "tier_update",
      },
      {
        title: "Delete",
        key: "tier_delete",
      },
    ],
  },
  {
    title: "Member Management",
    key: "7",
    children: [
      {
        title: "View",
        key: "member_view",
      }, 
      {
        title: "Edit",
        key: "member_update",
      }, 
    ],
  },
  {
    title: "Reports",
    key: "8",
    children: [
      {
        title: "View",
        key: "reports_view",
      },
    ],
  },
  {
    title: "Store Transaction",
    key: "9",
    children: [
      {
        title: "View",
        key: "store_transaction_view",
      },
    ],
  },
  {
    title: "Account Transaction",
    key: "10",
    children: [
      {
        title: "View",
        key: "account_transaction_view",
      },
    ],
  },
  {
    title: "Manual Adjustment",
    key: "11",
    children: [
      {
        title: "View",
        key: "manual_adjustment_view",
      },
      {
        title: "Add",
        key: "manual_adjustment_add",
      },
      {
        title: "Edit",
        key: "manual_adjustment_update",
      },
      {
        title: "Delete",
        key: "manual_adjustment_delete",
      },
    ],
  },
  {
    title: "Content Management",
    key: "12",
    children: [
      {
        title: "View",
        key: "content_view",
      },
      {
        title: "Add",
        key: "content_add",
      },
      {
        title: "Edit",
        key: "content_update",
      },
      {
        title: "Delete",
        key: "content_delete",
      },
    ],
  },
];
