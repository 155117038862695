import { get } from "@api";  

export const getStoreTransaction  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/transactions?pageIndex=${pageIndex}&pageSize=${pageSize}&${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getStoreTransactionCSV  = async (keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/transactions?${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getPoints  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/earned-points?pageIndex=${pageIndex}&pageSize=${pageSize}&${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getPointsCSV  = async (keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/earned-points?${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getRedeemPointsData  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/redeem-points?pageIndex=${pageIndex}&pageSize=${pageSize}&${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getRedeemPointsCSV = async (keyword?: any) => { 
  try {   
    let res = await get(`/store/reports/redeem-points?${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getAccountReportsData  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {   
    let res = await get(`/account/reports/member?pageIndex=${pageIndex}&pageSize=${pageSize}&${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getAccountReportsCSV = async (keyword?: any) => { 
  try {   
    let res = await get(`/account/reports/member?${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 