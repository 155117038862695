import * as React from "react";
import classnames from "classnames";
import { Bg, Header, SideBar } from "@components";

type Props = {
  children: React.ReactNode;
  bg?: "white" | "gray" | "transparent" | "lightblue" | "none";
  grid?: "two" | "three" | "four" | "five" | "six";
  height?: "screen";
  margin?: "top" | "bottom";
  gap?: "gapFour" | "gapEight";
  
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "lightblue":
      return "bg-blue-opacity";
    case "transparent":
      return "bg-transparent";
    case "gray":
      return "bg-gray-800";
    case "white":
      return "bg-white";
    case "none":
      return "bg-none";
    default:
      return "bg-gray-light";
  }
};

let getGrid = (grid: Props["grid"]) => {
  switch (grid) {
    case "six":
      return "lg:grid-cols-6 md:grid-cols-2";
    case "five":
      return "lg:grid-cols-5 md:grid-cols-2";
    case "four":
      return "lg:grid-cols-4 md:grid-cols-4";
    case "two":
      return "lg:grid-cols-2 md:grid-cols-1";
    case "three":
        return "lg:grid-cols-3 md:grid-cols-1";
    default:
      return "lg:grid-cols-3 md:grid-cols-1";
  }
};

let getHeight = (height: Props["height"]) => {
  switch (height) {
    case "screen":
      return "h-screen";
    default:
      return "";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "bottom":
      return "mb-8";
    case "top":
      return "mt-8";
    default:
      return "m-0";
  }
};


let getGap = (gap: Props["gap"]) => {
  switch (gap) {
    case "gapEight":
      return "gap-x-8 gap-y-1";
    case "gapFour":
      return "gap-x-4 gap-y-1";
    default:
      return "gap-1";
  }
};

export const MainContainer = ({ children }: Props) => {
  return (
    <>
      <SideBar />
      <div className={classnames("main-content relative")}>
            <Bg />
        <Header />
        <main>
          <div>
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

export const CenterContent = ({ bg, height, margin, children }: Props) => {
  return (
    <div
      className={classnames(
        "flex items-center justify-center",
        getBg(bg),
        getHeight(height),
        getMargin(margin)
      )}
    >
      {children}
    </div>
  );
};

export const CardContent = ({ bg, children }: Props) => {
  return (
    <div
      className={classnames(
        "flex md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden relative z-10",
        getBg(bg)
      )}
    >
      {children}
    </div>
  );
};

export const GridContainer = ({ grid, gap, children }: Props) => {
  return (
    <div
      className={classnames("grid sm:grid-cols-1 w-full", getGrid(grid),
      getGap(gap))}
    >
      {children}
    </div>
  );
};


export const Container = ({ 
  margin,
  children, 
  bg, 
}: Props) => {
  return (
    <div
      className={classnames("text-center", 
        getBg(bg), 
        getMargin(margin),  
      )}
    >
      {children}
    </div>
  );
};

export const CardContainer = ({ children }: Props) => {
  return (
    <div className="min-h-screen overflow-hidden">
      <div className="flex min-h-screen justify-center items-center"> 
        <div className=" font-montserrat setaccount-card">   
          {children}
        </div>
      </div>
    </div>
  );
};


export const SearchContainer = ({ children }: Props) => {
  return (
    <div className="w-3/4 lg:w-2/4 border-[1px] border-gray-400 flex flex-row-reverse justify-start items-center p-1 rounded-sm search_border mr-2"> 
      {children} 
    </div>
  );
};

