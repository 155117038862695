import { Strings } from "@constant";
import { LogoutPage } from "@containers";
import { useEffect } from "react";

export const Logout = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Logout")
  })
  
  return (
    <>
      <LogoutPage />
    </>
  );
};
