import { GridContainer, NoData, Span } from "@common";
import { 
  Loader,
  SearchContent, 
  TablePagination,
} from "@components";
import { COLUMNS } from "./Table/columns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store"; 
import { useHistory } from "react-router-dom";

export const StoreTransactionPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const tableName = "store_transaction";

  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const dataStore: any[] = useSelector(
    (state: RootState) => state.Table.tables[tableName].data
  );

  const getStoreTransactionData = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getStoreTransaction({
        table: tableName,
      });
      dispatch.Table.updatePageIndex({
        table: tableName,
        value: 1,
      });
      dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.Table.updateKeyword({ table: tableName, value: "" });
    getStoreTransactionData();
  }, []);

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getStoreTransactionData();
  };

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "reference_number",
      label: "Reference Number",
    },
    {
      value: "account_number",
      label: "Account Number",
    },
    {
      value: "pos_code",
      label: "POS Code",
    },
    {
      value: "branch_code",
      label: "Branch Code",
    },
    {
      value: "company_code",
      label: "Company Code",
    },
  ];

  useEffect(() => { 
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []); 

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        
      <GridContainer grid="two">
        <Span weight="bold" size="2xl">
          List of Store
        </Span>

        
        <div>
            <div className="search-bar">
                <SearchContent
                searchOptions={searchOptions}
                setFilters={setFilters}
                setSearchData={setSearchData}
                searchData={searchData}
                />
            </div>
          </div>
        </GridContainer> 
        {dataStore?.length > 0 ? (
          <TablePagination
            columns={COLUMNS}
            data={dataStore}
            dataType={tableName}
          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};
