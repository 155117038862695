import {
  BriefcaseIcon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  ComputerDesktopIcon,
  FaTicketAlt,
  FaUserTag,
  GiftIcon,
  PiUserListFill,
  IdentificationIcon,
  MdDiscount,
  RectangleGroupIcon,
  SquaresPlusIcon,
  TicketIcon,
  UserGroupIcon,
  WalletIcon,
  LiaCartArrowDownSolid,
  RiStore3Fill,
  FaRegIdCard,
  GrTransaction,
  RiFileUserFill,
  // RiFileUserFill
} from "@icons";

export const SideBarData = [
  {
    title: "My Profile",
    path: "/profile",
    icon: <IdentificationIcon />,
    type: "nav",
    permission: "",
  },
  {
    title: "User Administration",
    path: "/userprofile",
    icon: <SquaresPlusIcon />,
    type: "nav",
    permission: "user_administration_view",
  },
  {
    title: "Role Management",
    path: "/role",
    icon: <RiFileUserFill className="text-xl" />,
    type: "nav",
    permission: "role_view",
  },
  {
    title: "Business Unit Configuration",
    icon: <ChartPieIcon />,
    type: "list",
    permission: "business_unit_view",
    subNav: [
      {
        title: "Business Unit Enrollment & Configuration",
        path: "/business/enrollment",
        icon: <BriefcaseIcon />,
        type: "nav",
        permission: "business_unit_view",
      },
      {
        title: "Branch Configuration",
        path: "/business/branch",
        icon: <BuildingStorefrontIcon />,
        type: "nav",
        permission: "branch_view",
      },
      {
        title: "Point of Sale Configuration",
        path: "/business/pos",
        icon: <ComputerDesktopIcon />,
        type: "nav",
        permission: "pos_view",
      },
    ],
  },
  {
    title: "Promotion Configuration",
    path: "/promotion",
    icon: <TicketIcon />,
    type: "nav",
    permission: "promotion_view",
  },
  {
    title: "Member Tier Configuration",
    path: "/tiers",
    icon: <RectangleGroupIcon />,
    type: "nav",
    permission: "tier_view",
  },
  {
    title: "Member Management",
    path: "/members",
    icon: <UserGroupIcon />,
    type: "nav",
    permission: "member_view",
  },
  {
    title: "Reports",
    icon: <ClipboardDocumentListIcon />,
    type: "list",
    permission: "reports_view",
    subNav: [
      {
        title: "Store Reports",
        path: "/reports/storetransactions",
        icon: <BuildingStorefrontIcon />,
        type: "nav",
        permission: "",
      },
      {
        title: "Earn Points",
        path: "/reports/earnpoints",
        icon: <WalletIcon />,
        type: "nav",
        permission: "",
      },
      {
        title: "Redeem Points",
        path: "/reports/redeempoints",
        icon: <GiftIcon />,
        type: "nav",
        permission: "",
      },
      {
        title: "Account Reports",
        path: "/reports/account",
        icon: <PiUserListFill className="text-xl" />,
        type: "nav",
      },
    ],
  },
  {
    title: "Transaction",
    icon: <LiaCartArrowDownSolid className="text-2xl" />,
    type: "list",
    permission: "store_transaction_view",
    subNav: [
      {
        title: "Store Transactions",
        path: "/transaction/store",
        icon: <RiStore3Fill className="text-2xl" />,
        type: "nav",
        permission: "store_transaction_view",
      },
      {
        title: "Account Transactions",
        path: "/transaction/account",
        icon: <FaRegIdCard className="text-xl" />,
        type: "nav",
        permission: "account_transaction_view",
      },
      {
        title: "Manual Adjustments",
        path: "/transaction/manual?tab=pendings",
        icon: <GrTransaction className="text-xl" />,
        type: "nav",
        permission: "manual_adjustment_view",
      },
    ],
  },
  {
    title: "Content Management",
    icon: <FaUserTag />,
    type: "list",
    permission: "content_view",
    subNav: [
      {
        title: "Offers",
        path: "/content/offer",
        icon: <MdDiscount />,
        type: "nav",
        permission: "",
      },
      {
        title: "Vouchers",
        path: "/content/voucher",
        icon: <FaTicketAlt />,
        type: "nav",
        permission: "",
      },
    ],
  },
];
