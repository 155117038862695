import { ButtonIcon } from "@components";
import { BiSolidEditAlt, RiDeleteBin7Fill } from "@icons";
import { Dispatch, RootState } from "@store";
import { useDispatch, useSelector } from "react-redux";

export const EditDelete = ({ manualId, reference_number }) => {
  const dispatch = useDispatch<Dispatch>();

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleDelete = () => {
    dispatch.UI.updateState({
      isDeleteTransaction: true,
      manualId: manualId,
      reference_number: reference_number,
    });
  };

  const handleEdit = () => {
    dispatch.UI.updateState({
      isEditTransaction: true,
      manualId: manualId,
      reference_number: reference_number,
    });
  };

  return (
    <>
      <div className="flex items-center justify-center">
        {userAccess?.manual_adjustment_update && (
          <ButtonIcon
            label="Edit"
            icon={<BiSolidEditAlt />}
            bg="green"
            onClick={handleEdit}
          />
        )}

        {userAccess?.manual_adjustment_delete && (
          <div className="ml-2">
            <ButtonIcon
              label="Delete"
              icon={<RiDeleteBin7Fill />}
              bg="red"
              onClick={handleDelete}
            />
          </div>
        )}
      </div>
    </>
  );
};
