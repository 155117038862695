import { get, put, post, remove } from "@api";  
import { IDataMembersDetails } from "@types";
 

export const getMemberData = async (pageIndex: any, pageSize: any, keyword?: any) => {
    try {
        const res = await get(`/members/member?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`); 
        return res.data; 
    } catch (err) {
        throw err;
    }
} 

export const getCitizenship = async () => { 
  try {
    let res = await get(`/members/citizenships`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  
  
export const addMember = async (member: any) => { 
  try {
    let res = await post(`/admin/members/`, member);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   

export const updateMember = async (id, member: IDataMembersDetails) => { 
  try {
    let res = await put(`/members/update-member/${id}`, member);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getMemberById = async (user_id: string) => { 
  try {
    let res = await get(`/members/member/${user_id}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 


export const getProvince = async () => { 
  try {
    let res = await get(`/members/provinces`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getCities = async (provinceId: string) => { 
  try {
    let res = await get(`/members/${provinceId}/cities`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 


export const getProvinceByName = async (provinceName: string) => { 
  try {
    let res = await get(`/members/province/${provinceName}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   

export const deleteBusiness = async (company_code: string) => {
  try{     
    const res = await remove(`/store/delete-company/${company_code}`,); 
    return res.data;
  }catch(err){
    throw err;
  }
}    

export const deleteBranch = async (branch_code: string) => {
  try{     
    const res = await remove(`/store/delete-branch/${branch_code}`); 
    return res.data;
  }catch(err){
    throw err;
  }
}    

export const deletePOS = async (pos_code: string) => {
  try{     
    const res = await remove(`/store/delete-pos/${pos_code}`,); 
    return res.data;
  }catch(err){
    throw err;
  }
}   

export const deletePromo = async (pos_code: string) => {
  try{     
    const res = await remove(`/store/delete-promo/${pos_code}`,); 
    return res.data;
  }catch(err){
    throw err;
  }
}   