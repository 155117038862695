import {
  BiSortDown,
  BiSortUp,
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronLeft,
  CgPushChevronRight,
} from "@icons";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import "./Table.scss";
import { Button, Span } from "@common";
import { Dispatch, RootState } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Checkbox } from "@components";
import _ from "lodash";

type Props = {
  data: any;
  columns: any;
  dataType?: string;
  activeTab?: string;
};

export const Table = ({ columns, data, dataType, activeTab }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns): any => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    setPageSize,
    state,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  const table = dataType === "manual_adjustment" ? "manual_adjustment" : "";

  const {
    totalRecords,
    page: pageindex,
    limit: pagesize,
  } = useSelector((state: RootState) => state.Table.tables[table]) || {};

  useEffect(() => {
    if (["manual_adjustment"].includes(table)) {
      if (activeTab) {
        dispatch.Table.getManualAdjustment({ table, activeTab });
      }
    }
  }, [totalRecords, pageindex, pagesize]);

  useEffect(() => {
    dispatch.Table.updateState({ isLoading: false });
  }, [data]);

  const page_count = Math.ceil(totalRecords / pageSize);
  const page_index = pageindex;

  useEffect(() => {
    if (page_index > page_count) {
      dispatch.Table.updatePageIndex({ table, value: 1 });
    }
  }, [page_count]);

  const disabled = page_count === 0 || page_index === page_count;

  useEffect(() => { 
      const rows = selectedFlatRows.map((row) => ({
        id: row.original?.id,
        reference_number: row.original?.reference_number
      }));
      const rowsReady = _.toPlainObject(rows); 
      dispatch.Table.updateState({ manualId: rows});  
  }, [selectedFlatRows]);

  return (
    <>
      <div className="table-content">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex whitespace-nowrap font-semibold font-bold">
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiSortDown className="text-lg mx-2" />
                          ) : (
                            <BiSortUp className="text-lg mx-2" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const lastRow = row?.cells?.length - 1;
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="text-start text-sm p-4"
                      >
                        <span>{cell.render("Cell")}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-pagination">
        <div className="mx-2 flex rounded-md">
          <Span margin="auto">Items per page:</Span>
          <select
            className="table-rowsperpage border border-grey-4"
            value={pageSize}
            onChange={(e) => {
              dispatch.Table.updatePageLimit({ table, value: e.target.value });
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div className="flex px-2 ">
          <Button
            bg="white"
            color="black"
            margin="mx-2"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: 1,
              })
            }
            disabled={page_index === 1}
          >
            <CgPushChevronLeft size={16} />
          </Button>

          <Button
            bg="white"
            color="black"
            margin="none"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: pageindex === 1 ? 1 : pageindex - 1,
              })
            }
            disabled={page_index === 1}
          >
            <CgChevronLeft size={16} />
          </Button>
          <div className="my-auto mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
            <Span margin="auto">Page </Span>
            <Span margin="auto" padding="px-2">
              {pageindex} - {pageSize}
            </Span>
            <Span margin="auto"> of {page_count}</Span>
          </div>
          <Button
            bg="white"
            margin="none"
            width="none"
            color="black"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: pageindex + 1,
              })
            }
            disabled={disabled}
          >
            <CgChevronRight size={16} />
          </Button>

          <Button
            bg="white"
            color="black"
            margin="mx-2"
            width="none"
            padding="none"
            onClick={() =>
              dispatch.Table.updatePageIndex({
                table,
                value: page_count,
              })
            }
            disabled={page_index === page_count}
          >
            <CgPushChevronRight size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};
