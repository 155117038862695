import { StatusDesign } from "@components";
import { formatDateTime, formatDecimal } from "@constant";

export const COLUMNS = [
  {
    accessor: "reference_number",
    Header: "Reference Number",
  },
  {
    accessor: "action",
    Header: "Type",
    Cell: (data) => {
      const type = data?.row?.original?.action;
      return (
        <>
          <span className="capitalize">{type} </span>
        </>
      );
    },
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "branch",
    Header: "Branch",
  },
  {
    accessor: "transaction_amount",
    Header: "Total Amount",
    Cell: (data) => {
      const amount = data?.row?.original?.transaction_amount;
      return (
        <>
          <span>{formatDecimal(amount)} </span>
        </>
      );
    },
  },  
  {
    accessor: "timestamp",
    Header: " Transaction Date",
    Cell: (data) => {
      const date = data?.row?.original?.timestamp;
      return (
        <>
          <span>{formatDateTime(date)} </span>
        </>
      );
    },
  }, 
  {
    accessor: "status",
    Header: "Status",
    Cell: (data) => {
      const status = data?.row?.original?.status; 
      return <StatusDesign status={status} />;
    },
  }, 
];
