import { get, post } from '@api';
import { UserAccount } from '@types';

export const loginRequest = async ({ username, password }: any) => {
  try {
    let res = await post(`/users/login`, {
      username,
      password
    });

    return res.data;
  } catch (err) {
    throw err;
  }
}; 


export const getUserById = async (id) => {
  try{     
    const res = await get(`/users/user/${id}`);
    return res.data;
  }catch(err){
    throw err;
  }
}


export const postSetAccount = async (userAccount: UserAccount) => {
  try{     
      const res = await post(`/users/set-account`, userAccount); 
      return res.data;
  }catch(err){
      throw err;
  }
}


export const postAccessValidation = async (accessToken: string) => {
  try{     
      const res = await post(`/users/check-token`, {"accessToken": accessToken}); 
      return res.data;
  }catch(err){
      throw err;
  }
}
