import { Span } from "@common";

export const ReportLabel = ({ title, label }: any) => {
    return (
      <>
        <div className="m-auto">
          <Span weight="semi" size="lg" color="gray-primary">
            {title}:
          </Span>
          <Span weight="semi" size="xl">
            &nbsp; {label}
          </Span>
        </div>
      </>
    );
  };
  