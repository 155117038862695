import classnames from 'classnames' 

export const Separator = (props: any) => {
    const {orientation} = props;
  return (
    <div
        className={classnames(
        "shrink-0 bg-gray-200",
        orientation === "horizontal" ? "h-[1px] w-full my-4" : "h-full w-[1px] mx-4", 
        )}
    {...props}
    ></div>
  )
}
