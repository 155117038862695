import { Strings } from "@constant";
import { StoreTransactionPage } from "@containers";
import { useEffect } from "react";

export const StoreTransaction = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Store Transaction")
  })
  
  return (
    <>
      <StoreTransactionPage />
    </>
  );
};
