import { formatDecimal } from "@constant";

export const COLUMNS = [
    {
      accessor: "transaction_account_number",
      Header: "Account Number",
    }, 
    {
      accessor: "total_redeem_points",
      Header: "Total Redeem Points",  
      Cell: (data) => {
        const points = data?.row?.original?.total_redeem_points; 
        return (<>
         <span>{formatDecimal(points)} </span>
        </>) 
      },
    },
    {
      accessor: "transaction_count",
      Header: "Transaction Count",
    }, 
];


export const HEADERS_REDEEM_POINTS = [
  { label: "Account Number", key: "transaction_account_number" }, 
  { label: "Total Redeem Points", key: "total_redeem_points" },
  { label: "Transaction Count", key: "transaction_count" }, 
];