import { GridContainer, Span } from "@common";
import {
  DeleteModal,
  FormDatePicker,
  FormInput,
  FormNumber,
  FormSelect,
  FormSwitch,
  SelectBranch,
  SelectBusinessUnit,
  SelectContainer,
  SelectTier,
} from "@components";
import { useEffect, useState } from "react";
import {
  DATE_FORMAT,
  FREQUENCY,
  HHMMA,
  PROMO_CONDITION_TYPE,
  PROMO_TYPE,
  formatDateDash,
  formatTimeOnly,
  onlyNumberKey,
} from "@constant";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Popconfirm,
  Select,
  Space,
} from "antd";
import dayjs, { Dayjs } from "dayjs";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { deletePromo, getPromoDetails, updatePromo } from "@api";
import moment from "moment";

type Props = {
  promoCode: string;
  getData: Function;
  table: string;
  promoModal: boolean;
  setIsLoading: any;
};

export const PromoEdit = ({
  promoModal,
  promoCode,
  table,
  getData,
  setIsLoading,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const hhmmA = "hh:mm A";

  const [promoDetails, setPromoDetails] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const promo = useSelector(
    (state: RootState) => state.Table.tables[table].data
  );

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const promoData = promo?.filter((d) => d?.promo_code === promoCode)[0];

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPromoDetails({
      ...promoDetails,
      [name]: value,
    });
  };

  const handleClose = () => {
    setLoading(false);
    dispatch.UI.updateState({ promoCode: "", promoModal: false });
    getData();
  };

  const handePromo = async () => {
    try {
      setLoading(true);
      const res = await updatePromo(promoCode, {
        ...promoDetails,
        start_date: formatDateDash(promoDetails?.start_date),
        end_date: formatDateDash(promoDetails?.end_date),
        start_time: promoDetails.start_time
          ? formatTimeOnly(promoDetails.start_time)
          : null,
        end_time: promoDetails.end_time
          ? formatTimeOnly(promoDetails.end_time)
          : null,
      });

      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  };

  const getPromoByPromoCode = async () => {
    try {
      const res = await getPromoDetails(promoCode);
      const sDate = new Date(res?.promo?.start_date);
      const eDate = new Date(res?.promo?.end_date);
      const details = {
        title: res?.promo?.title,
        promo_code: res?.promo?.promo_code,
        voucher_code: res?.promo?.voucher_code,
        company_code:
          res?.store?.isAllCompany === "all"
            ? "all"
            : res?.store?.company_code[0],
        branch_code:
          res?.store?.isAllBranch === "all" ? "all" : res?.store?.branch_code,
        tiers: res?.promo?.tiers,
        frequency: res?.promo?.frequency,
        start_date: moment(sDate) && dayjs(sDate),
        end_date: moment(eDate) && dayjs(eDate),
        start_time:
          res?.promo?.start_time && moment(res?.promo.start_time, hhmmA),
        end_time: res?.promo?.end_time && moment(res?.promo?.end_time, hhmmA),
        additional_points: res?.promo?.additional_points,
        multiplier: res?.promo?.multiplier,
        is_active: res?.promo?.is_active,
        condition_value: res?.promo?.condition_value,
        condition: res?.promo?.condition,
        sku: res?.promo?.sku,
        type: res?.promo?.type,
      };
      setPromoDetails(details);

      form.setFieldsValue(details);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPromoByPromoCode();
  }, [promoCode]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const result = await deletePromo(promoCode);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    setIsLoading(false);
    setIsModalDelete(false);
    handleClose();
  };

  const handleBusiness = (v: any) => {
    if (v === "all") {
      form.setFieldsValue({ branch_code: "all" });
      setPromoDetails({ ...promoDetails, company_code: v, branch_code: "all" });
    } else {
      form.setFieldsValue({ branch_code: [] });
      setPromoDetails({ ...promoDetails, company_code: v, branch_code: "" });
    }
  };

  const handleBranch = (v: any) => {
    const include = v.includes("all");
    if (include) {
      form.setFieldsValue({ branch_code: "all" });
      setPromoDetails({ ...promoDetails, branch_code: "all" });
    } else {
      setPromoDetails({ ...promoDetails, branch_code: v });
    }
  };

  const readOnly = !userAccess?.promotion_update;

  return (
    <>
      <Drawer
        title={
          readOnly
            ? "View Promotions Configuration"
            : "Edit Promotions Configuration"
        }
        placement="right"
        width={500}
        onClose={handleClose}
        open={promoModal ? true : false}
        footer={
          readOnly ? null : (
            <div className="flex justify-center">
              <Space>
                <Popconfirm
                  placement="top"
                  title="Discard changes made in promotions configuration?"
                  onConfirm={handleClose}
                  okText="Discard"
                  cancelText="Cancel"
                >
                  <Button loading={loading}>Cancel</Button>
                </Popconfirm>
                {userAccess?.promotion_update && (
                  <Button
                    onClick={form.submit}
                    type="primary"
                    loading={loading}
                  >
                    Update
                  </Button>
                )}
              </Space>
            </div>
          )
        }
      >
        <Form
          onFinish={handePromo}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          <FormInput
            label="Title"
            name="title"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />

          <GridContainer grid="two" gap="gapFour">
            <FormInput
              label="Promo Code"
              name="promo_code"
              required={true}
              wrapperCol={{ span: 24 }}
              disabled
            />
            <FormInput
              label="Voucher Code"
              name="voucher_code"
              required={false}
              wrapperCol={{ span: 24 }}
              onChange={onChange}
              disabled={readOnly}
            />
          </GridContainer>

          {/* company_code */}
          <SelectBusinessUnit
            onChange={(v: any) => handleBusiness(v)}
            all={true}
            required={true}
            disabled={readOnly}
          />

          {/* branch_code  */}
          <SelectBranch
            onChange={(v: any) => handleBranch(v)}
            companyCode={promoDetails?.company_code}
            disabled={readOnly || promoDetails?.company_code === "all"} 
          />

          <SelectTier
            onChange={(v: any) =>
              setPromoDetails({ ...promoDetails, tiers: v })
            }
            mode="multiple"
            name="tiers"
            disabled={readOnly}
          />

          <SelectContainer
            label="Frequency"
            name="frequency"
            option={FREQUENCY.map((item) => ({
              value: item?.value,
              label: item?.name,
            }))}
            mode="multiple"
            onChange={(v: any) =>
              setPromoDetails({ ...promoDetails, frequency: v })
            }
            required={true}
            disabled={readOnly}
          />

          <SelectContainer
            label="Condition"
            name="condition"
            option={PROMO_CONDITION_TYPE.map((item) => ({
              value: item?.value,
              label: item?.name,
            }))}
            onChange={(v: any) =>
              setPromoDetails({ ...promoDetails, condition: v })
            }
            disabled={readOnly}
            required={false}
          />

          <FormInput
            label="Condition Value"
            name="condition_value"
            required={promoDetails.condition === "none" ? false : true}
            wrapperCol={{ span: 24 }}
            maxLength={6}
            onKeyPress={onlyNumberKey}
            onChange={onChange}
            disabled={readOnly}
          />

          <Form.Item label="Type" name="type" rules={[{ required: false }]}>
            <Select
              className="w-full"
              placeholder="Type"
              onChange={(v: any) =>
                setPromoDetails({ ...promoDetails, type: v })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={readOnly}
            >
              {PROMO_TYPE.map((d, index) => (
                <Select.Option key={index} value={d.value}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <FormInput
            label="SKU"
            name="sku"
            required={false}
            onChange={onChange}
            disabled={readOnly}
          />

          <GridContainer grid="two" gap="gapFour">
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[{ required: true, message: `Start Date is required` }]}
            >
              <DatePicker
                inputReadOnly={true}
                className="w-full"
                format={DATE_FORMAT}
                onChange={(value: Dayjs | null, dateString?: string) => {
                  const selectedDate = dayjs(dateString);
                  setPromoDetails({
                    ...promoDetails,
                    start_date: selectedDate,
                  });
                }} 
            disabled={readOnly}
              />
            </Form.Item>

            <Form.Item
              name="end_date"
              label="End Date"
              rules={[{ required: true, message: `End Date is required` }]}
            >
              <DatePicker
                inputReadOnly={true}
                className="w-full"
                format={DATE_FORMAT}
                onChange={(value: Dayjs | null, dateString: string | null) => {
                  const selectedDate = dayjs(dateString);
                  setPromoDetails({ ...promoDetails, end_date: selectedDate });
                }}
                value={promoDetails?.end_date}
                disabled={readOnly}
              />
            </Form.Item>

            <FormDatePicker
              label="Start Time"
              name="start_time"
              required={true}
              picker="time"
              format={HHMMA}
              onChange={(e: any) =>
                setPromoDetails({ ...promoDetails, start_time: e })
              }
              disabled={readOnly}
            />

            <FormDatePicker
              label="End Time"
              name="end_time"
              required={true}
              picker="time"
              format={HHMMA}
              onChange={(v: any) =>
                setPromoDetails({ ...promoDetails, end_time: v })
              }
              disabled={readOnly}
            />

            <FormNumber
              label="Additional Points"
              name="additional_points"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onChange={(v: number) => {
                setPromoDetails({ ...promoDetails, additional_points: v });
              }}
              disabled={readOnly}
            />

            <FormInput
              label="Multiplier"
              name="multiplier"
              required={false}
              wrapperCol={{ span: 24 }}
              maxLength={3}
              onKeyPress={onlyNumberKey}
              onChange={onChange}
              disabled={readOnly}
            />
          </GridContainer>

          <FormSwitch
            label="Promo Status"
            value={promoData?.is_active}
            name="is_active"
            checkName="Active"
            uncheckName="Inactive"
            onChange={(v) =>
              setPromoDetails({
                ...promoDetails,
                is_active: v,
              })
            }
            disabled={readOnly}
          />

          {userAccess?.promotion_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <DeleteModal
        title="Promotion"
        modalOpen={isModalDelete}
        cancelEvent={() => setIsModalDelete(false)}
        clickEvent={handleDelete}
        loading={loading}
        okLabel="Delete"
        subtitle={promoDetails?.title}
      />
    </>
  );
};
