import { FormInput } from "@components";
import { useState } from "react";
import { Button, Drawer, Form, Input, Popconfirm, Select, Space } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { addManualTransaction } from "@api";
import { REMARKS } from "@constant";
import { IManualAdjustmentInfo } from "@types";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
  adjustmentDetails: IManualAdjustmentInfo;
  accountNumber: string;
};

export const AddTransaction = ({
  openModal,
  setOpenModal,
  getData,
  adjustmentDetails,
  accountNumber,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    type: "",
    points: "",
    remarks: "",
  };
 
  const [pendingsDetails, setPendingsDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);

  const adminId = useSelector((state: RootState) => state.User.userInfo.id);

  const handleClose = () => {
    setOpenModal(false);
    setisloading(false);
    setPendingsDetails(initialState);
    form.resetFields();
    getData();
  };

  const handeAddTransaction = async () => {
    try {
      setisloading(true);
      const res = await addManualTransaction({
        adminId: adminId,
        reference_number: adjustmentDetails?.reference_number,
        account_number: accountNumber,
        pos_code: adjustmentDetails?.pos_code,
        adjustments: {
          type: pendingsDetails?.type,
          points: pendingsDetails?.points,
          remarks: pendingsDetails?.remarks,
        },
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new tiers!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new transaction!",
        type: "Error",
      });
    }
    handleClose();
  };

  return (
    <Drawer
      title="Add Transaction"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in member tier configuration?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Submit
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeAddTransaction();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <Form.Item label="Type" name="type" rules={[{ required: false }]}>
          <Select
            className="w-full"
            placeholder="Condition Type" 
            onChange={(e: any) => {
              const value = e;
              form.setFieldsValue({ points: "" });
              setPendingsDetails({ ...pendingsDetails, type: value, points: "" });
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Select.Option value="earn">Earn</Select.Option>
            <Select.Option value="redeem">Redeem</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Points"
          name="points"
          rules={[
            {
              required: true,
              message: `Points is required`,
            },
            {
              validator: async (_, value) => {
                if (pendingsDetails?.type === "earn" && value < 0) {
                  return Promise.reject(
                    new Error("The 'earn' type does not accept negative numbers!")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input 
            placeholder="Points"
            name="points" 
            type="number"
            onChange={(v: any) => {
              setPendingsDetails({
                ...pendingsDetails,
                points: v.target.value,
              });
            }}
          />
        </Form.Item>

        <Form.Item label="Remarks" name="remarks" rules={[{ required: false }]}>
          <Select
            className="w-full"
            placeholder="Condition Type"
            onChange={(v: any) =>
              setPendingsDetails({ ...pendingsDetails, remarks: v })
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {REMARKS.map((d, index) => (
              <Select.Option key={index} value={d.value}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
