import { get, put, post } from "@api"; 
import { UserAccount, UserInfo } from "@types";

export const getUserAdministration = async (pageIndex: any, pageSize: any, keyword?: any) => {
  try{     
    const res = await get(`/users/all?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`); 
    return res.data;
  }catch(err){
    throw err;
  }
}

export const addUserAdmin = async (data: any) => {
  try{     
    const res = await post(`/users/create`, data);
    return res.data;
  }catch(err){
    throw err;
  }
}

export const updateUserProfile = async (id: string, userDetails: UserInfo) => {
  try{     
    const res = await put(`/users/update/${id}`, userDetails);
    return res.data;
  }catch(err){
    throw err;
  }
}  
 
export const getUserDetails = async (userId: string) => {
  try{     
    const res = await get(`/users/user/${userId}`);
    return res.data;
  }catch(err){
    throw err;
  }
}


export const resetPassword = async (email: string) => {
  try{     
    const res = await post(`/users/send-password-email`, {"email": email}); 
    return res.data;
  }catch(err){
    throw err;
  }
}

export const resendEmail = async (email: string) => {
  try{     
    const res = await post(`/users/resend-setup-email`, {"email": email}); 
    return res.data;
  }catch(err){
    throw err;
  }
}

export const changePassword = async (userInfo: UserAccount) => {
  try{     
    const res = await post(`/users/change-password`, userInfo);  
    return res.data;
  }catch(err){ 
    throw err;
  }
}


export const deleteAccount = async (id: string, password: string) => {
  try{     
    const res = await post(`/users/delete-user/${id}`, {"password": password}); 
    return res.data;
  }catch(err){
    throw err;
  }
}  

export const postEmailValidation = async (accessToken: string) => {
  try{      
    const res = await post(`/members/verify-button`, {"accessToken": accessToken}); 
    return res.data;
  }catch(err){
    throw err;
  }
}