import { CenterContent } from "@common";
import { BRAND_IMAGES } from "@constant";
import ReactLoading from "react-loading";

export const Loading = () => {
  return (
    <CenterContent bg="gray" height="screen">
      <div>
        <img
          src={BRAND_IMAGES["BRAND_LOGO"]}
          alt="AllRewards Logo"
          className="my-3 w-56"
        />
        <div className="flex justify-center">
          <ReactLoading
            type="bubbles"
            color="#4f46e5"
            height={97}
            width={105}
          />
        </div>
      </div>
    </CenterContent>
  );
};
