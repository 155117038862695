import { Button, CardContainer, CenterContent, Span } from "@common";
import { BRAND_IMAGES } from "@constant";
import { Dispatch } from "@store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; 
import './setaccount.scss'; 
import { Form } from "antd";
import { ContactUs, ErrorAccount, Loader, PasswordInput, SuccessAccount } from "@components";
import { changePassword, postAccessValidation } from "@api"; 

export const ResetPasswordPage = () => {
  const dispatch = useDispatch<Dispatch>(); 
  const [form]: any = Form.useForm();   
  
  var t = window?.location?.search?.match(/(\?|&)accessToken=([^&]*)/) ?? [];
  var access_token = t[2];  

  const initialState = {   
    password: "",
    confirm_password: "",
    accessToken: access_token
  }

  const [accountDetails, setAccountDetails] = useState(initialState);
  const [accessValidation, setAccessValidation] = useState(false); 
  const [success, setSuccess] = useState(false);  
  
  const [isLoading, setIsLoading] = useState(false);  
  const [error, setError] = useState("");

  const getAccess = async () => {
    setIsLoading(true);
    try {
      const res = await postAccessValidation(access_token);
      if (!res) {
        setAccessValidation(false);
      }
    } catch {
      setAccessValidation(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccess();
  }, []);

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setAccountDetails({
    ...accountDetails,
    [name]: value,
    });
  };     

  let handelResetPassword = async () => {
    setIsLoading(true);
    try { 
      const result = await changePassword({...accountDetails});   
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully set new account!",
          type: "Success",
        });
        setSuccess(true)
      }
    } catch (err: any) {   
      setError(err ? err : "Invalid credentials!");
    }
    setIsLoading(false);
  }; 

  
  const isWithValue = !accountDetails?.password.length || !accountDetails?.confirm_password.length;
  const isPasswordMatch = isWithValue || accountDetails?.password === accountDetails?.confirm_password;
  const validation = !accountDetails?.password || !accountDetails?.confirm_password; 

  return (
    <> 
      <Loader isLoading={isLoading} />
      <CenterContent height="screen" bg="white">
        {!accessValidation ? 
           !success ? (<>
        <CardContainer>      
          <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="setaccount-logo"/>
          
          <p className="text-center my-6">
          <Span size="3xl" weight="bold" font="montserrat" color="green">
            Reset AllRewards Admin Password
          </Span>
          </p> 

          {error && <> 
            <p className="text-center my-4">
              <Span size="base" color="red">{error}</Span> 
            </p>
          </>}

          {!isPasswordMatch && 
            <p className="text-center my-4">
              <Span size="base" color="red"><>Mismatch Password!</></Span> 
            </p>
          }

          <Form 
            wrapperCol={{ span: 24 }}
            layout="vertical"
            form={form} 
            >   

            <PasswordInput 
              classNames="font-montserrat"
              required={true} 
              label="Password" 
              name="password" 
              onChange={onChange}
            /> 

            <PasswordInput 
              classNames="font-montserrat"
              required={true} 
              label="Confirm Password" 
              name="confirm_password" 
              onChange={onChange} 
            />
 
          </Form>

          <div className="flex justify-end">
            <Button
              padding="px-6" 
              bg="green" 
              onClick={handelResetPassword}
              disabled={validation || !isPasswordMatch}
            >
              CONFIRM
            </Button>
          </div>  
        </CardContainer>

        <div className="flex min-h-screen justify-left items-center font-montserrat setaccount-contact"> 
          <ContactUs />
        </div> 
        </>):(
            <SuccessAccount
              title="Password Changed" 
              subtitle="Your password change successfully. You can now log in using the new password."
            />
        ) : ( 
          <ErrorAccount
            title="Unable to reset password" 
            subtitle="The reset link is invalid or expired. Try requesting a new one."
          />
        )}
  
        </CenterContent> 
        
      </>
    );
  };
  
