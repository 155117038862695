import { FormInput } from "@components";
import { useEffect, useState } from "react";
import { Button, Drawer, Form, Popconfirm, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { getTransactionByReference, updateTransaction } from "@api";
import { REMARKS } from "@constant";

type Props = {
  openModal: boolean;
  getPendingData: Function;
  isLoading: boolean;
  setIsLoading: Function;
};

export const EditTransaction = ({ openModal, getPendingData, isLoading, setIsLoading }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const [pendingsDetails, setPendingsDetails] = useState({} as any); 

  const adminId = useSelector((state: RootState) => state.User.userInfo.id);

  const { manualId, reference_number } = useSelector((state: RootState) => state.UI);

  const handleClose = async () => {
    setIsLoading(false);
    form.resetFields();
    dispatch.UI.updateState({
      isEditTransaction: false,
      manualId: "",
    });
    await getPendingData()
  };

  const getTransactionData = async () => {
    setIsLoading(true);
    try {
      const res = await getTransactionByReference(manualId); 
      const details = {
        type: res?.type,
        points: res?.points,
        remarks: res?.remarks,
      };
      setPendingsDetails(details);
      form.setFieldsValue(details);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTransactionData();
  }, [manualId]);

  const handeUpdateTransaction = async () => {
    try {
      setIsLoading(true);
      const res = await updateTransaction(manualId, {
        adminId: adminId,
        reference_number: reference_number,
        adjustments: {
          type: pendingsDetails?.type,
          points: pendingsDetails?.points,
          remarks: pendingsDetails?.remarks,
        },
      }); 
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit!",
        type: "Error",
      });
    }
    handleClose();
  }; 

  return (
    <Drawer
      title="Edit Transaction"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in member tier configuration?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Submit
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeUpdateTransaction();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <Form.Item label="Type" name="type" rules={[{ required: false }]}>
          <Select
            className="w-full"
            placeholder="Condition Type"
            onChange={(v: any) =>
              setPendingsDetails({ ...pendingsDetails, type: v })
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Select.Option value="earn">Earn</Select.Option>
            <Select.Option value="redeem">Redeem</Select.Option>
          </Select>
        </Form.Item>

        <FormInput
          label="Points"
          name="points"
          required={true}
          onChange={(v) => {
            setPendingsDetails({ ...pendingsDetails, points: v.target.value });
          }}
        />

        <Form.Item label="Remarks" name="remarks" rules={[{ required: false }]}>
          <Select
            className="w-full"
            placeholder="Condition Type"
            onChange={(v: any) =>
              setPendingsDetails({ ...pendingsDetails, remarks: v })
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {REMARKS.map((d, index) => (
              <Select.Option key={index} value={d.value}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
