import { Tree } from "antd";
import { treeData } from "./TreeData";
import { Span } from "@common";

type Props = {
  onCheck: any;
  checkedKeys: any;
  disabled?: boolean;
};

export const Trees = (props: Props) => {
  return (
    <div className="my-4">
      <Span weight="semi">PERMISSIONS</Span>
      <Tree
        checkable
        onCheck={props.onCheck}
        checkedKeys={props.checkedKeys}
        treeData={treeData}
        disabled={props.disabled}
      />
    </div>
  );
};
