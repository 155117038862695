import {
  deleteTransaction,
  filterAdjustment,
  getAdjustmentPending,
  getViewAdjustment,
} from "@api";
import {
  Button,
  GridContainer,
  Separator,
  Span,
  CenterContent,
  NoData,
} from "@common";
import {
  ButtonIcon,
  DeleteModal,
  FormInput,
  Loader,
  ModalContent,
  NoPaginationTable, 
  TransactionLabel,
} from "@components";
import { IManualAdjustmentInfo } from "@types";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";
import { FaPlus, MdViewList } from "@icons";
import { COLUMNS_TRANSACTION } from "./Table/transactionColumns";
import { COLUMNS_PENDING } from "./Table/pendingColumns";
import { AddTransaction } from "./Action/AddTransaction";
import { EditTransaction } from "./Action/EditTransaction";

export const ManualDetailsPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const column_pending = useMemo(() => COLUMNS_PENDING, []);
  const column_transaction = useMemo(() => COLUMNS_TRANSACTION, []);

  const [isViewDetails, setIsViewDetails] = useState(false);
  const [adjustmentDetails, setAdjustmentDetails] = useState(
    {} as IManualAdjustmentInfo
  );
  const adminId = useSelector((state: RootState) => state.User.userInfo.id);
  const { isEditTransaction, isDeleteTransaction, manualId, reference_number } =
    useSelector((state: RootState) => state.UI);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const [adjustInfo, setAdjustInfo] = useState({} as any);
  const [pendingDetails, setPendingDetails] = useState({} as any);
  const [viewDetails, setViewDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [addTransactionModal, setAddTransactionModal] =
    useState<boolean>(false);

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setAdjustmentDetails({
      ...adjustmentDetails,
      [name]: value,
    });
  };
  const getPendingData = async () => {
    setIsLoading(true);
    try {
      const pendingInfo = await getAdjustmentPending(adjustmentDetails);
      setPendingDetails(pendingInfo?.result);
    } catch (error: any) {
      dispatch.UI.setAlert({
        message: error ?? "Unable to fetch pending data!",
        type: "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransaction = async () => {
    setIsLoading(true);
    try {
      const result = await filterAdjustment(adjustmentDetails);
      if (result) {
        setAdjustInfo(result);
        const viewInfo = await getViewAdjustment(adjustmentDetails);
        setViewDetails(viewInfo);
        if (viewInfo) {
          await getPendingData(); // Ensure this completes before setting isLoading to false
        }
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to approve!",
        type: "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const result = await deleteTransaction(manualId, {
        adminId: adminId,
        reference_number: reference_number,
      });
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    } finally {
      setIsLoading(false);
      dispatch.UI.updateState({
        isDeleteTransaction: false,
        manualId: "",
        reference_number: "",
      });
      await getPendingData();
    }
  };

  useEffect(() => {
    dispatch.UI.updateState({
      isDeleteTransaction: false,
      isEditTransaction: false,
      manualId: "",
      reference_number: "",
    });
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Manual Adjustment
          </Span>
        </GridContainer>

        <div className="mt-4">
          <GridContainer gap="gapFour">
            <FormInput
              label="POS Code"
              name="pos_code"
              required={true}
              onChange={onChange}
            />
            <FormInput
              label="Reference Number"
              name="reference_number"
              required={true}
              onChange={onChange}
            />
            <div>
              <Button
                padding="p-1"
                radius="md"
                margin="none"
                onClick={handleTransaction}
              >
                <Span size="base" color="white">
                  Show Transaction
                </Span>
              </Button>
            </div>
          </GridContainer>

          {adjustInfo?.account_number && (
            <>
              <CenterContent bg="lightblue" margin="bottom">
                <div className="p-6 w-full">
                  <GridContainer grid="two">
                    <Span weight="bold" size="base">
                      Account Details
                    </Span>
                    <div className="content-end">
                      <ButtonIcon
                        label="View"
                        icon={<MdViewList />}
                        bg="green"
                        onClick={() => setIsViewDetails(true)}
                      />
                    </div>
                  </GridContainer>
                  <Separator orientation="horizontal" />
                  <GridContainer grid="six">
                    <TransactionLabel
                      title="Account Name"
                      label={adjustInfo?.account?.account_name}
                    />
                    <TransactionLabel
                      title="Account Number"
                      label={adjustInfo?.account?.account_number}
                    />
                    <TransactionLabel
                      title="Earned"
                      label={viewDetails?.posted_points?.earn ?? 0}
                    />
                    <TransactionLabel
                      title="Redeem"
                      label={viewDetails?.posted_points?.redeem ?? 0}
                    />
                    <TransactionLabel
                      title="Refund"
                      label={viewDetails?.posted_points?.refund ?? 0}
                    />
                    <TransactionLabel
                      title="Adjustment"
                      label={viewDetails?.posted_points?.adjustment ?? 0}
                    />
                  </GridContainer>
                </div>
              </CenterContent>

              {userAccess?.manual_adjustment_add && (
                <div className="content-end my-4">
                  <ButtonIcon
                    label="Add Transaction"
                    icon={<FaPlus />}
                    onClick={() => setAddTransactionModal(true)}
                  />
                </div>
              )}
            </>
          )}

          {pendingDetails?.length > 0 ? (
            <>
              <div className="mb-6">
                <NoPaginationTable
                  data={pendingDetails}
                  columns={column_pending}
                />
              </div>
            </>
          ) : (
            <NoData />
          )}
        </div>
      </div>

      <ModalContent
        title="Account Transaction Table"
        modalOpen={isViewDetails}
        cancelEvent={() => setIsViewDetails(false)}
      >
        {viewDetails?.transactions?.length > 0 ? (
          <NoPaginationTable
            data={viewDetails?.transactions}
            columns={column_transaction}
          />
        ) : (
          <NoData />
        )}
      </ModalContent>

      <AddTransaction
        openModal={addTransactionModal}
        setOpenModal={setAddTransactionModal}
        getData={getPendingData}
        adjustmentDetails={adjustmentDetails}
        accountNumber={adjustInfo?.account_number}
      />

      {isDeleteTransaction && (
        <DeleteModal
          title="Transaction"
          modalOpen={isDeleteTransaction}
          cancelEvent={() =>
            dispatch.UI.updateState({ isDeleteTransaction: false })
          }
          clickEvent={handleDelete}
          loading={isLoading}
          okLabel="Delete"
          subtitle=""
        />
      )}

      {isEditTransaction && (
        <EditTransaction
          openModal={isEditTransaction}
          getPendingData={getPendingData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};
