import { get, put, post, remove } from "@api";
import {
  IManualAdjustment,
  IManualAdjustmentInfo,
  ITransactionAdjustment,
  TransactionInfo,
} from "@types";

export const getAccountTransactionData = async (
  pageIndex: any,
  pageSize: any,
  keyword?: any
) => {
  try {
    const res = await get(
      `/account/transactions?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getListTransactionByAccounts = async (id: string) => {
  try {
    let res = await get(`/account/transactions/${id}?pageIndex=1&pageSize=10`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getListTransactionByAccountNumber = async (
  id: string,
  pageIndex: any,
  pageSize: any,
  keyword?: any
) => {
  try {
    let res = await get(
      `/account/transactions/${id}?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getTransactionById = async (type: string, id: string) => {
  try {
    let res = await get(`/store/account/transaction/${type}/${id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getStoreReportsData = async (
  pageIndex?: any,
  pageSize?: any,
  keyword?: any
) => {
  try {
    let res = await get(
      `/store/transactions?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getStoreById = async (id: string) => {
  try {
    let res = await get(`/store/transactions/${id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getManualAdjustmentData = async (
  activeTab: string,
  pageIndex?: any,
  pageSize?: any,
  keyword?: any
) => {
  try {
    let res = await get(
      `/store/manual-adjustment/${activeTab}?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const approveManual = async (manual: TransactionInfo) => {
  try {
    let res = await post(`/store/manual-adjustment/approve`, manual);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const declineManual = async (manual: TransactionInfo) => {
  try {
    let res = await post(`/store/manual-adjustment/decline`, manual);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getTransactionByReference = async (manual_id: string) => {
  try {
    let res = await get(`/store/manual-adjustment/${manual_id}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const filterAdjustment = async (adjustment: IManualAdjustmentInfo) => {
  try {
    let res = await get(
      `/store/manual-adjustment/${adjustment?.pos_code}/${adjustment?.reference_number}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAdjustmentPending = async (
  adjustment: IManualAdjustmentInfo
) => {
  try {
    let res = await get(
      `/store/manual-adjustment/${adjustment?.pos_code}/${adjustment?.reference_number}/pendings`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getViewAdjustment = async (adjustment: IManualAdjustmentInfo) => {
  try {
    let res = await get(
      `/account/view-transactions/${adjustment?.reference_number}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const addManualTransaction = async (adjust: IManualAdjustment) => {
  try {
    let res = await post(`/store/manual-adjustment/create`, adjust);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteTransaction = async (
  manualId: string, body: any
) => { 
  try {
    const res = await post(
      `/store/manual-adjustment/pending/${manualId}/delete`,
      body
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};


export const updateTransaction = async (manualId: string, transaction: ITransactionAdjustment) => { 
  try {
    let res = await put(`/store/manual-adjustment/pending/${manualId}`, transaction);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  