import { ReportList } from "@components";  
import { COLUMNS, HEADERS_EARN_POINTS } from "./Table/columns";  
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";  
import { getPointsCSV } from "@api";
import { formatDecimal } from "@constant";
import { useSelector } from "react-redux"; 
import { RootState } from "@store";
  
export const EarnPointsListPage = () => {  
    const column = useMemo(() => COLUMNS, []);   
    const tableName = 'points'  
    const history = useHistory();  
    const [csvDetails, setCSVDetails] = useState([] as any);    
    const [isModalCSV, setIsModalCSV] = useState(false);   
  
    const handleBack = () => { 
        history.push(`/reports/earnpoints`);  
    }   

    const keyword = useSelector(
        (state: RootState) => state.Table.tables[tableName].keyword
    ); 

    const handleOpenCSV = () => { 
        setIsModalCSV(true) 
        getCSV();
    }      

    const getCSV = async () => {
        try { 
        const res = await getPointsCSV(keyword);   
        const newData = res.results?.map((data) => {
          return {
            ...data,
            transaction_account_number: `${data?.transaction_account_number}`,
            total_earned_points: `${formatDecimal(data?.total_earned_points)}`, 
            transaction_count: `${data?.transaction_count}`, 
          };
        });
        setCSVDetails(newData);
        
        } catch (error) {
        throw error;
        }
    }; 

    return (<> 
        <ReportList 
            tableName={tableName} 
            handleBack={handleBack} 
            columns={column}  
            title="Earn Points Report"
            headers={HEADERS_EARN_POINTS} 
            csvDetails={csvDetails} 
            handleOpenCSV={handleOpenCSV} 
            isModalCSV={isModalCSV}
            setIsModalCSV={setIsModalCSV}
        />
    </>);
}