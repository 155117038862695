type Props = {
  is_active?: string;
  status?: string;
};

export const StatusDesign = ({ is_active, status }: Props) => {
  const renderDesign = () => {
    switch (status) {
      case 'pending':
        return (
          <div className="bg-[#fffbe6] border-[#ffe58f] status-style">
            <span className="text-[#d48806] status-label">
              {status}
            </span>
          </div>
        );
      case 'done':
        return (
          <div className="bg-[#f6ffed] border-[#b7eb8f] status-style">
            <span className="text-[#389e0d] status-label">
              {status}
            </span>
          </div>
        );
      case 'cancelled':
        return (
          <div className="bg-[#fff1f0] border-[#ffa39e] status-style">
            <span className="text-[#cf1322] status-label">
              {status}
            </span>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {status ? (
        
       renderDesign()
      ) : is_active ? (
        <div className="bg-[#f6ffed] border-[#b7eb8f] status-style">
          <span className="text-[#389e0d] status-label">Active</span>
        </div>
      ) : (
        <div className="bg-[#fff1f0] border-[#ffa39e] status-style">
          <span className="text-[#cf1322] status-label">Inactive</span>
        </div>
      )}
    </>
  );
};
