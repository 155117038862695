import { Inputs, Span } from "@common";
type Props = {
  value?: string;
  placeholder?: string;
  type?: string;
  onChange?: any;
  name?: string;
  label?: string;
  required?: boolean;
  classNames?: string;
  disabled?: boolean; 
};

export const InputContent = ({
  value,
  placeholder,
  type,
  onChange,
  name,
  label,
  required, 
  disabled, 
  classNames
}: Props) => {
  return (
    <>
      <div className={`my-4 ${classNames}`}>
        <Span size="base">{label}</Span>
        {required && <Span size="base" color="red"> *</Span>}
        <Inputs
          padding="px-2"
          name={name}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};
