import { Strings } from "@constant";
import { SetAccountPage } from "@containers";
import { useEffect } from "react";

export const SetAccount = () => { 

  useEffect(() => {
    document.title = Strings.pageTitle("Set Account")
  })
  
  return (
    <>
      <SetAccountPage />
    </>
  );
};
