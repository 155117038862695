export const COLUMNS = [
  {
    accessor: "role_name",
    Header: "Role Name",
  }, 
  {
    accessor: "role_description",
    Header: "Role Description",
  },
  {
    accessor: "createdBy",
    Header: "Created by",
  }, 
  {
    accessor: "role_status",
    Header: "Status",
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original; 
      return (<>
        {d.role_status ? 
          <div className='bg-[#f6ffed] border-[1px] border-[#b7eb8f] w-[100px] rounded-sm flex items-center justify-center'>
            <span className='text-xs text-[#389e0d] p-1'>Active</span>
          </div> 
          : 
          <div className='bg-[#fff1f0] border-[1px] border-[#ffa39e] w-[100px] rounded-sm flex items-center justify-center'>
            <span className='text-xs text-[#cf1322] p-1'>Inactive</span>
          </div>
          }
      </>) 
    },
  },
];
