import { getBranchesByCompany } from "@api";
import { SelectContainer } from "@components";
import { useEffect, useState } from "react";

type Props = {
  onChange?: any;
  companyCode: string;
  mode?: "multiple" | "tags";
  disabled?: boolean;
};

export const SelectBranchCode = (props: Props) => {
  const [branchCodeDetails, setBranchCodeDetails] = useState([] as any);
  const getBranchCodeData = async () => {
    try {
      const res = await getBranchesByCompany(props?.companyCode);
      setBranchCodeDetails(res?.results);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (props?.companyCode) {
      getBranchCodeData();
    }
  }, [props?.companyCode]);

  return (
    <SelectContainer
      label="Branch Code"
      name="branch_code"
      mode={props?.mode}
      onChange={props?.onChange}
      option={branchCodeDetails.map((item) => ({
        value: item?.branch_code,
        label: `${item?.branch_code} - ${item?.branch_name}`,
      }))}
      disabled={props?.disabled}
      required={true}
    />
  );
};
