import { StatusDesign } from "@components";

export const COLUMNS = [
  {
    accessor: "branch_code",
    Header: "Branch Code",
  },
  {
    accessor: "branch_name",
    Header: "Branch Name",
  },
  {
    accessor: "company_code",
    Header: "Company Code",
  },
  {
    accessor: "address",
    Header: "Address",
  },
  {
    accessor: "is_active",
    Header: "Active",
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original; 
      return <StatusDesign is_active={d?.is_active} />;
    },
  },
];

