import { GridContainer, NoData, Span } from "@common";
import { Loader, SearchInput, TablePagination } from "@components";
import { COLUMNS } from "./Table/columns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store"; 

export const ListAccountPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const tableName = "account_transaction";

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(""); 

  const dataAccount: any[] = useSelector(
    (state: RootState) => state.Table.tables[tableName].data
  );
 
  const getAccountTransactionData = async () => {
    try {
      setIsLoading(true);
      dispatch.Table.getAccountTransaction({
        table: tableName,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch.Table.updateKeyword({ table: tableName, value: "" });   
    dispatch.Table.setTableData({
      table: 'listtransaction',
      data: [],
      totalRecords: 0,
    });
    getAccountTransactionData();
  }, []);

  const handleSearch = () => {
    const keyvalue = search === "" ? "" : `&keyword=${search}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    getAccountTransactionData();
  };

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearch(""); 
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            List of Account
          </Span>
          <div>
            <div className="search-bar">
              <SearchInput
                placeholder="Account Number or Name"
                value={search}
                setSearch={setSearch}
                handleSearch={handleSearch}
              />
            </div>
          </div>
        </GridContainer>

        {dataAccount?.length > 0 ? (
          <TablePagination
            columns={COLUMNS}
            data={dataAccount}
            dataType={tableName}
          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};
