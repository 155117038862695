import { GridContainer, Span, Button, NoData } from "@common";
import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";
import { formatDateSlash, useQuery } from "@constant";
import {
  Loader,
  SearchContent,
  TablePagination,
  Table,
  ApprovedModal,
  DeclinedModal,
} from "@components";

import { COLUMNS } from "./Table/columns";
import { COLUMNS_APPROVED } from "./Table/approvedColumns";
import { MdOutlineCheck, MdOutlineClose } from "@icons";
import { approveManual, declineManual } from "@api";
import { COLUMNS_DECLINED } from "./Table/declinedcolumn";

export const ListManualPage = () => {
  const { TabPane } = Tabs;
  const query = useQuery();
  const tab: any = query.get("tab");
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);
  const tableName = "manual_adjustment";
  const [isLoading, setIsLoading] = useState(false);
  const [isModalApproved, setIsModalApproved] = useState(false);
  const [isModalDeclined, setIsModalDeclined] = useState(false);

  const [searchData, setSearchData] = useState({
    filter: "keyword",
    value: "",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const data: any[] = useSelector(
    (state: RootState) => state.Table.tables[tableName].data
  );

  const manualId = useSelector((state: RootState) => state.Table.manualId);
  const adminId = useSelector((state: RootState) => state.User.userInfo.id);

  const getManualAdjustmentList = async () => {
    try {
      setIsLoading(true);

      dispatch.Table.updatePageIndex({
        table: tableName,
        value: 1,
      });
      dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
      dispatch.Table.getManualAdjustment({
        table: tableName,
        activeTab: activeTab,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const setFilters = () => {
    setIsLoading(true);
    const keyvalue =
      `${searchData?.filter}` === "date"
        ? `&start_date=${formatDateSlash(startDate)}&end_date=${formatDateSlash(
            endDate
          )}`
        : searchData?.value === ""
        ? ""
        : `&${searchData?.filter}=${searchData?.value}`;
    dispatch.Table.updateKeyword({ table: tableName, value: keyvalue });
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setIsLoading(false);
    getManualAdjustmentList();
  };

  useEffect(() => {
    dispatch.Table.updatePageIndex({
      table: tableName,
      value: 1,
    });
    setSearchData({ filter: "keyword", value: "" });
  }, []);

  useEffect(() => {
    if (activeTab === "pendings") {
      history.push("/transaction/manual?tab=pendings");
      setActiveTab("pendings"); 
      dispatch.Table.updatePageIndex({
        table: tableName,
        value: 1,
      });
      dispatch.Table.updateKeyword({ table: tableName, value: "" });
      setSearchData({ filter: "keyword", value: "" });
      getManualAdjustmentList();
    }

    if (activeTab === "approved") {
      history.push("/transaction/manual?tab=approved");
      setActiveTab("approved"); 
      dispatch.Table.updatePageIndex({
        table: tableName,
        value: 1,
      });
      dispatch.Table.updateKeyword({ table: tableName, value: "" });
      setSearchData({ filter: "keyword", value: "" });
      getManualAdjustmentList();
    }
    if (activeTab === "declined") {
      history.push("/transaction/manual?tab=declined");
      setActiveTab("declined");  
      dispatch.Table.updatePageIndex({
        table: tableName,
        value: 1,
      });
      dispatch.Table.updateKeyword({ table: tableName, value: "" });
      setSearchData({ filter: "keyword", value: "" });
      getManualAdjustmentList();
    }
  }, [activeTab]);

  const searchOptions = [
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "reference_number",
      label: "Reference Number",
    },
    {
      value: "account_number",
      label: "Account Number",
    },
    {
      value: "date",
      label: "Date",
    },
  ];

  const handleApproved = async () => {
    setIsLoading(true);
    try {
      const result = await approveManual({
        adminId: adminId,
        manual_id: manualId,
      });
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully approved!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to approve!",
        type: "Error",
      });
    }
    setIsLoading(false);
    setIsModalApproved(false);
    getManualAdjustmentList();
  };

  const handleDeclined = async () => {
    setIsLoading(true);
    try {
      const result = await declineManual({
        adminId: adminId,
        manual_id: manualId,
      });
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully approved!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to approve!",
        type: "Error",
      });
    }
    setIsLoading(false);
    setIsModalDeclined(false);
    getManualAdjustmentList();
  }; 

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            List of Manual Adjustment
          </Span>
          <div className="content-end">
            <Button
              width="none"
              padding="p-1"
              radius="md"
              margin="none"
              onClick={() => history.push(`/transaction/adjustment`)}
            >
              <div className="flex align-center mx-2">
                <Span size="base" color="white">
                  Manual Adjustment
                </Span>
              </div>
            </Button>
          </div>
        </GridContainer>

        <div>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="Pending" key="pendings" />
            <TabPane tab="Approved" key="approved" />
            <TabPane tab="Decline" key="declined" />
          </Tabs>
        </div>

        <div className="flex justify-between">
          <div className="flex justify-start w-4/6">
            <SearchContent
              searchOptions={searchOptions}
              setFilters={setFilters}
              setSearchData={setSearchData}
              searchData={searchData}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>

          {activeTab === "pendings" && (
            <div className="flex">
              <Button
                width="none"
                padding="p-1"
                radius="md"
                bg="green"
                margin="none"
                disabled={manualId?.length === 0}
                onClick={() => setIsModalApproved(true)}
              >
                <div className="flex align-center">
                  <MdOutlineCheck className="my-auto mx-2 text-xl items-center inline-flex" />
                  <Span size="base" color="white" padding="pr-2">
                    Approve
                  </Span>
                </div>
              </Button>

              <Button
                width="none"
                padding="p-1"
                radius="md"
                bg="red"
                margin="mx-2"
                disabled={manualId?.length === 0}
                onClick={() => setIsModalDeclined(true)}
              >
                <div className="flex align-center">
                  <MdOutlineClose className="my-auto mx-2 text-xl items-center inline-flex" />
                  <Span size="base" color="white" padding="pr-2">
                    Decline
                  </Span>
                </div>
              </Button>
            </div>
          )}
        </div>

        {data?.length > 0 ? (
          activeTab === "pendings" ? (
            <Table
              columns={COLUMNS}
              data={data}
              dataType={tableName}
              activeTab={activeTab}
            />
          ) : (
            <TablePagination
              columns={
                activeTab === "approved" ? COLUMNS_APPROVED : COLUMNS_DECLINED
              }
              data={data}
              dataType={tableName}
              activeTab={activeTab}
            />
          )
        ) : (
          <NoData />
        )}
      </div>

      <ApprovedModal
        modalOpen={isModalApproved}
        cancelEvent={() => setIsModalApproved(false)}
        clickEvent={handleApproved}
        loading={isLoading}
      />

      <DeclinedModal
        modalOpen={isModalDeclined}
        cancelEvent={() => setIsModalDeclined(false)}
        clickEvent={handleDeclined}
        loading={isLoading}
      />
    </>
  );
};
