import { Strings } from "@constant";
import { StoreListPage } from "@containers";
import { useEffect } from "react";

export const StoreList = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Store Transactions");
  });

  return (
    <>
      <StoreListPage />
    </>
  );
};
