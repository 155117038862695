import "./App.css"; 
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthRoutes, LoginRoute, Routes } from "@helpers";
import { Login } from "@pages";
import AuthRoute from "./helpers/AuthRoute";  

function App() {  
  return (  
    <BrowserRouter>
      <Switch>  
        <LoginRoute path="/" exact component={Login} /> 
          {Routes.map((route) => (
            <Route exact
              path={route.path}
              component={route.component} 
            />
          ))} 

          {AuthRoutes.map((app) => (
            <AuthRoute
              path={app.path}
              component={app.component} 
            />
          ))} 
      </Switch>
    </BrowserRouter>
  );
}

export default App;