import { StatusDesign } from "@components";

export const COLUMNS = [
  {
    accessor: "company_code",
    Header: "Company Code",  
    width: 60,
  },
  {
    accessor: "company_name",
    Header: "Company Name",  
    width: 100,
  },
  {
    accessor: "company_address",
    Header: "Address", 
    Cell: (data) => {
      const d = data?.row?.original;
      const company_address = d?.company_address;
      return (
        <div className="truncate max-w-[200px]">
          <span>{company_address}</span>
        </div>
      );
    },
  },
  {
    accessor: "contact_email",
    Header: "Email",
    width: 100,
  }, 
  {
    accessor: "contact_number",
    Header: "Contact Number",
  },
  {
    accessor: "is_active",
    Header: "Status",
    width: 100,
    Cell: (data) => {
      const d = data?.row?.original; 
      return <StatusDesign is_active={d?.is_active} />;
    },
  },
];
