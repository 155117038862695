import { get, put, post } from "@api"; 
import { BranchInfo, BusinessInfo, POSInfo } from "@types";

export const getCompanies = async () => { 
  try {
    let res = await get(`/store/companies`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 


export const getCompany = async (pageIndex: any, pageSize: any, keyword?: any) => { 
  try {
    let res = await get(`/store/companies?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 


export const getBranches = async (pageIndex: any, pageSize: any, keyword?:any) => { 
  try {
    let res = await get(`/store/branches?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getBranchesByCompany = async (companyCode: string) => { 
  try {
    let res = await get(`/store/branches/company/${companyCode}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getCompanyCode = async () => { 
  try {
    let res = await get(`/companies/selectionList`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const addBusiness = async (business: BusinessInfo) => { 
  try {
    let res = await post(`/store/create-company`, business);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   

export const updateBusiness = async (company_code, business: BusinessInfo) => { 
  try {
    let res = await put(`/store/update-company/${company_code}`, business);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getBusinessById = async (companyCode: string) => { 
  try {
    let res = await get(`/store/company/${companyCode}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const addBranches = async (branch: BranchInfo) => { 
  try {
    let res = await post(`/store/create-branch`, branch);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   
  
export const updateBranch = async (branch_code:string , branch: BranchInfo) => { 
  try {
    let res = await put(`/store/update-branch/${branch_code}`, branch);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};    

export const getBranchById = async (id: string) => { 
  try {
    let res = await get(`/store/branch/${id}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 
  
export const getPointOfSale = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {
    let res = await get(`/store/pos?pageIndex=${pageIndex}&pageSize=${pageSize}${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const addPOS = async (pos: POSInfo) => { 
  try {
    let res = await post(`/store/create-pos`, pos);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   


export const updatePOS = async (pos_code, pos: POSInfo) => { 
  try {
    let res = await put(`/store/update-pos/${pos_code}`, pos);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  


export const getPOSById = async (id: string) => { 
  try {
    let res = await get(`/store/pos/${id}`);     
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 