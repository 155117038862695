import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { RootState } from "@store";
import { useSelector } from "react-redux";
import { MainContainer } from "@common";

interface MyRouteProps extends RouteProps {
  component: any;
  rest?: any;
  path: any;
}

const AuthRoute: React.FC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => {

  const {shouldRedirectToLogin, isUserLoggedIn} = useSelector(
    (state: RootState) => state.User
  );

  return (
    <MainContainer>
      <Route
        {...rest}
        render={(props) =>
          isUserLoggedIn ? (
            <Component {...props} />
          ) : (<>
            {shouldRedirectToLogin? <Redirect
              to={{
                pathname: "/logout",
                state: { from: props.location },
              }}
            />: null }
            </>
          )
        }
      />
    </MainContainer>
  );
};

export default AuthRoute;
