import { GridContainer } from "@common";
import { FormInput, FormNumber } from "@components";
import { useState } from "react";
import { Button, Drawer, Form, Popconfirm, Select, Space } from "antd";

import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import { addTiers } from "@api";
import { CONDITION_TYPE, onlyNumberKey } from "@constant";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const TiersAdd = ({ openModal, setOpenModal, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const initialState = {
    tier: "",
    tier_group_code: "",
    tier_group_name: "",
    group_sequence: 0,
    multiplier: 0,
    condition_amount: 0,
    condition_type: "",
    condition_duration: 0,
  };

  const [tiersDetails, setTiersDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setisloading(false);
    setTiersDetails(initialState);
    form.resetFields();
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTiersDetails({
      ...tiersDetails,
      [name]: value,
    });
  };

  const handeAddTiers = async () => {
    try {
      setisloading(true);
      const res = await addTiers({ ...tiersDetails });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new tiers!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.message ?? "Unable to add new tiers!",
        type: "Error",
      });
    }
    handleClose();
  };

  return (
    <Drawer
      title="Add Member Tier Configuration"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in member tier configuration?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          handeAddTiers();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <FormInput
          label="Tier"
          name="tier"
          required={true}
          onChange={onChange}
        />
        <FormInput
          label="Tier Group Name"
          name="tier_group_name"
          required={false}
          onChange={onChange}
        />

        <GridContainer grid="two" gap="gapFour">
          <FormInput
            label="Tier Group Code"
            name="tier_group_code"
            required={true}
            wrapperCol={{ span: 24 }}
            maxLength={4}
            onChange={onChange}
          />

          <FormNumber
            label="Group Sequence"
            name="group_sequence"
            required={true}
            wrapperCol={{ span: 24 }}
            maxLength={3}
            onChange={(v: number) => {
              setTiersDetails({ ...tiersDetails, group_sequence: v });
            }}
          />

          <FormNumber
            label="Multiplier"
            name="multiplier"
            required={true}
            wrapperCol={{ span: 24 }}
            maxLength={3}
            onChange={(v: number) => {
              setTiersDetails({ ...tiersDetails, multiplier: v });
            }}
          />

          <FormNumber
            label="Condition Amount"
            name="condition_amount"
            required={false}
            wrapperCol={{ span: 24 }}
            maxLength={7}
            onChange={(v: number) => {
              setTiersDetails({ ...tiersDetails, condition_amount: v });
            }}
          />

          <Form.Item
            label="Condition Type"
            name="condition_type"
            rules={[{ required: false }]}
          >
            <Select
              className="w-full"
              placeholder="Condition Type"
              onChange={(v: any) =>
                setTiersDetails({ ...tiersDetails, condition_type: v })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {CONDITION_TYPE.map((d, index) => (
                <Select.Option key={index} value={d.value}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <FormNumber
            label="Condition Duration"
            name="condition_duration"
            required={false}
            wrapperCol={{ span: 24 }}
            maxLength={3}
            onChange={(v: number) => {
              setTiersDetails({ ...tiersDetails, condition_duration: v });
            }}
          />
        </GridContainer>
      </Form>
    </Drawer>
  );
};
