import { getUserRole } from "@api";
import { SelectContainer } from "@components";
import { useEffect, useState } from "react";

type Props = {
  onChange?: any;
  disabled?: boolean;
};

export const SelectRole = (props : Props) => {
  const [roleDetails, setRoleDetails] = useState([] as any);
  const getRoleData = async () => {
    try {
      const res = await getUserRole(); 
      setRoleDetails(res?.results);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getRoleData();
  }, []); 

  return (
    <> 
      <SelectContainer
        label="Role"
        name="role_name"
        onChange={props?.onChange}
        option={roleDetails.map((item) => ({
          value: item?.role_name,
          label: item?.role_name,
        }))} 
        disabled={props?.disabled}
        required={true}
      />
    </>
  );
};
