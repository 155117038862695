import { get, post, put } from "@api";
import { RoleInfo } from "@types";

export const getRole  = async (pageIndex?: any, pageSize?: any, keyword?: any) => { 
  try {   
    let res = await get(`/users/role?pageIndex=${pageIndex}&pageSize=${pageSize}&${keyword}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getUserRole  = async () => { 
  try {   
    let res = await get(`/users/roles`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getRoleById  = async (id: string) => { 
  try {   
    let res = await get(`/users/role/${id}`);      
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 


export const addRole = async (role: RoleInfo) => { 
  try {   
    let res = await post(`/users/role/create`, role);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const updateRole = async (id, role: RoleInfo) => { 
  try {
    let res = await put(`/users/role/update/${id}`, role);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};   

export const deleteRole = async (id, adminId: string) => { 
  try {
    let res = await post(`/users/role/delete/${id}`, {"adminId": adminId});      
    return res.data; 
  } catch (err) {
    throw err;
  }
};  
