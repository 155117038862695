export const COLUMNS = [
  {
    accessor: "tier",
    Header: "Tier",
  },
  {
    accessor: "tier_group_code",
    Header: "Tier Group Code",
  },
  {
    accessor: "tier_group_name",
    Header: "Tier Group Name",
  },
  {
    accessor: "group_sequence",
    Header: "Group Sequence",
  },
];
