import { BG } from "@constant";

export const Bg = () => {
  return (
    <>
      <img
        src={BG["BG_CIRCLE_RED"]}
        alt="bg circle red"
        className="circle-red"
      />
      <img
        src={BG["BG_CIRCLE_GREEN"]}
        alt="bg circle green"
        className="circle-green"
      />
    </>
  );
};
