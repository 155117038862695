import { GridContainer, Span } from "@common";
import { FormInput } from "@components";
import { useState } from "react";
import { Button, Drawer, Form, Popconfirm, Space, Switch } from "antd";
 
import { onlyNumberKey } from "@constant"; 
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { addBusiness } from "@api";

type Props = { 
    openModal: boolean;
    setOpenModal: Function;
    getData: Function;
  };
  
export const BusinessUnitAdd = ({openModal, setOpenModal, getData}: Props) => {  
    const dispatch = useDispatch<Dispatch>();
    const [form]: any = Form.useForm(); 
    
    const initialState = {
        company_code: "",
        company_name: "",
        company_address: "",
        contact_email: "",
        contact_number: "",
        rate: 0,
        threshold: 0,
        is_active: true,   
    };

    const [businessDetails, setBusinessDetails] = useState(initialState);
    const [isLoading, setisloading] = useState(false);
        
    const handleClose = () => { 
        setOpenModal(false)  
        setisloading(false); 
        setBusinessDetails(initialState); 
        getData();
    }

    const onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
    
        setBusinessDetails({
          ...businessDetails,
          [name]: value,
        });
    };
    
    const handeAddBusiness = async () => {
        try {
          setisloading(true); 
          const res = await addBusiness({...businessDetails,
            contact_number: `+63${businessDetails?.contact_number}`
          });  
          if (res.success) { 
            dispatch.UI.setAlert({
              message: res?.message ?? "Successfully added new business!",
              type: "Success",
            }); 
          }
        } catch (err: any) { 
          dispatch.UI.setAlert({
            message: err ?? "Unable to add new business!",
            type: "Error",
          });  
        }
        handleClose()
    }; 
  
    return ( 
   
        <Drawer
            title="New Business Unit"
            placement="right"
            width={500}
            onClose={handleClose} 
            open={openModal}
            footer={
            <div className="flex justify-center">
                <Space>
                  <Popconfirm
                      placement="top"
                      title="Discard changes made in new business unit?"
                      onConfirm={handleClose}
                      okText="Discard"
                      cancelText="Cancel"
                  >
                      <Button loading={isLoading}>
                      Cancel
                      </Button>
                  </Popconfirm>
                  <Button 
                    onClick={form.submit} 
                    type="primary" 
                    loading={isLoading}
                  >
                      Add
                  </Button>
                </Space>
            </div>
            }
        >
        <Form 
          onFinish={() => {
            form.resetFields();
            handeAddBusiness();
          }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form} 
        >  
          <FormInput label="Code" name="company_code" required={true} maxLength={10} onChange={onChange} /> 
          <FormInput label="Name" name="company_name" required={true} onChange={onChange} /> 
          {/* Email */}
          <FormInput label="Email" name="contact_email" required={true} type="email" onChange={onChange} /> 
          <FormInput 
            label="Contact Number" 
            name="contact_number" 
            required={false} 
            addonBefore="+63" 
            maxLength={10} 
            onKeyPress={onlyNumberKey} 
            onChange={onChange} 
          />  
          <FormInput label="Address" name="company_address" required={false} onChange={onChange} />  
            
          <GridContainer grid="two" gap="gapFour">
            <FormInput 
              label="Rate" 
              name="rate" 
              required={false} 
              wrapperCol={{ span: 24 }} 
              maxLength={3} 
              onKeyPress={onlyNumberKey} 
              onChange={onChange}
            />

            <FormInput 
              label="Threshold" 
              name="threshold" 
              required={false} 
              wrapperCol={{ span: 24 }} 
              maxLength={7} 
              onKeyPress={onlyNumberKey} 
              onChange={onChange} 
            />
          </GridContainer>

          <div className='flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded'>
            <Span weight='semi'>Status</Span>

            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(v) =>
                setBusinessDetails({ ...businessDetails, is_active: v })
              }
            />
          </div>
        </Form>
      </Drawer> 
    );
  };