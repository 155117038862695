import { CSVModal, ReportList } from "@components";  
import { COLUMNS, HEADERS_STORE } from "./Table/columns";  
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";  
import { getStoreTransactionCSV } from "@api";
import { formatDecimal } from "@constant";
import { useSelector } from "react-redux"; 
import { RootState } from "@store";
  
export const StoreListPage = () => {  
    const column = useMemo(() => COLUMNS, []);   
    const tableName = 'store'  
    const history = useHistory();   
    const [csvDetails, setCSVDetails] = useState([] as any);     
    const [isModalCSV, setIsModalCSV] = useState(false); 
  
    const handleBack = () => { 
        history.push(`/reports/storetransactions`);  
    }      

    const handleOpenCSV = () => { 
        setIsModalCSV(true) 
        getCSV();
    }       

    const keyword = useSelector(
        (state: RootState) => state.Table.tables[tableName].keyword
    ); 

    const getCSV = async () => {
        try {    
            const res = await getStoreTransactionCSV(keyword);   
            const newData = res.results?.map((data) => {
                return {
                  ...data,
                  transaction_account_number: `${data?.transaction_account_number}`,
                  total_amount: `${formatDecimal(data?.total_amount)}`, 
                  average_transaction_amount: `${formatDecimal(data?.average_transaction_amount)}`, 
                  transaction_count: `${data?.transaction_count}`, 
                };
              });
            setCSVDetails(newData);
        } catch (error) {
            throw error;
        } 
    }; 

    return (<> 
        <ReportList 
            tableName={tableName} 
            handleBack={handleBack} 
            columns={column}  
            title="Store Transactions Report"
            headers={HEADERS_STORE} 
            csvDetails={csvDetails}  
            handleOpenCSV={handleOpenCSV} 
            isModalCSV={isModalCSV}
            setIsModalCSV={setIsModalCSV}
        /> 
    </>);
}