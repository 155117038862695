import { onlyNumberKey } from "@constant";
import { Form, InputNumber } from "antd";

type Props = { 
  onChange?: any;
  name?: string;
  label?: string;
  required?: boolean;   
  wrapperCol?: any;
  maxLength?: any; 
  disabled?: boolean
};

export const FormNumber = (props: Props) => { 
    
  return (
    <>
    <Form.Item 
      label={props?.label} 
      name={props?.name} 
      rules={[{ 
        required: props?.required,
        message: `${props?.label} is required`,
      }]}
      wrapperCol={props?.wrapperCol} 
    >
      <InputNumber  
        onKeyPress={onlyNumberKey}
        placeholder={props?.label} 
        name={props?.name} 
        onChange={props?.onChange}  
        maxLength={props?.maxLength} 
        disabled={props?.disabled} 
      />
    </Form.Item></>
  );
};