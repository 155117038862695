import { GridContainer, Span } from "@common";
import { Button } from "antd";
import { FaPlus } from "@icons";
import { Loader } from "@components";
import { useEffect, useState } from "react";
import { getContentData } from "@api";
import { OfferAdd } from "./Add/OfferAdd";
import { ContentCard } from "./Card/ContentCard";
import { useSelector } from "react-redux";
import { RootState } from "@store";

export const OfferPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const bannerType = "offers";

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const showDrawer = () => {
    setOpenModal(true);
  };

  const getOffer = async () => {
    try {
      setIsLoading(true);
      const res = await getContentData(bannerType);
      setOfferData(res?.results);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOffer();
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Offer
          </Span>
          {userAccess?.content_add && (
            <div className="search-bar">
              <Button onClick={showDrawer} type="primary" size="large">
                <div className="flex pr-2 items-center">
                  <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
                  Add Offer
                </div>
              </Button>
            </div>
          )}
        </GridContainer>

        <GridContainer gap="gapFour">
          {offerData?.map((data) => (
            <ContentCard
              data={data}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              getData={getOffer}
            />
          ))}
        </GridContainer>

        {/* Add */}
        <OfferAdd
          openModal={openModal}
          setOpenModal={setOpenModal}
          getData={getOffer}
        />
      </div>
    </>
  );
};
