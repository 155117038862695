import { Span } from "@common";
import { Button, Modal } from "antd"; 

type Props = {
    title: string
    modalOpen: boolean
    clickEvent?: () => void
    cancelEvent?: () => void
    loading?: boolean
    okLabel?: string  
    subtitle: string
}

export const DeleteModal = (props: Props) => {

  return (  
    <Modal 
      title={`Delete ${props.title}`}
      open={props.modalOpen}
      onCancel={props.cancelEvent} 
      footer={[<>
        <Button onClick={props.cancelEvent} loading={props.loading}>
          Close
        </Button>

        <Button type="primary" danger onClick={props.clickEvent} loading={props.loading}>
          Delete
        </Button>
      </>]}  
    > 
      <p><Span>Are you sure you want to delete <b>{props.subtitle}</b> ?</Span></p>     
    </Modal>
  );
}