import { ListTransactionDetailsPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";
import { useParams } from "react-router-dom";

export const ListTransactionDetails = () => {
  let params: any = useParams();

  useEffect(() => {
    document.title = Strings.pageTitle("Transaction Details");
  });

  return (
    <>
      <ListTransactionDetailsPage id={params.id} />
    </>
  );
};
