import { Container, Span } from "@common"
import './account.scss'; 

export const ContactUs = () => {   

    return (<>      
        <div>
          <Container bg="none" margin="bottom">      
            <Span size="xl" weight="bold" color="green">
              Contact Us
            </Span>
            <p>
              <Span size="base" color="gray">Need assistance? We’re here to help!</Span>
            </p> 
          </Container>
  
          <p className="account-title-label">
          <Span size="base" weight="bold">Address</Span>
          </p>
          
          <Span size="base" color="gray">
              AllDay Convenience Store Inc.
              <br />
              3rd Floor, Starmall Alabang, Muntinlupa City
          </Span>

          <p className="account-title-label">
          <Span size="base" weight="bold">Telephone Number</Span>
          </p>
          
          <Span size="base" color="gray">
          (02) 836 0519 loc 118
          </Span>

          <p className="account-title-label">
          <Span size="base" weight="bold">Mobile Hotline</Span>
          </p>
          
          <Span size="base" color="gray">
          0918-991-5555
          </Span>
        
          <p className="account-title-label">
          <Span size="base" weight="bold">Email</Span>
          </p>
          
          <Span size="base" color="gray">
          allrewardsmarketing@gmail.com
          <br />
          customercare@allrewards.ph
          </Span>
      </div>
    </>)
}