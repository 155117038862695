import { Strings } from "@constant";
import { RedeemPointsListPage } from "@containers";
import { useEffect } from "react";

export const RedeemPointsList = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Redeem Points");
  });

  return (
    <>
      <RedeemPointsListPage />
    </>
  );
};
