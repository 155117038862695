import { Button, Span } from "@common";

type Props = {
  onClick?: any;
  label?: string;
  icon?: any;
  bg?: any;
};

export const ButtonIcon = ( props : Props) => {
  return (
    <>
      <Button onClick={props.onClick}
      width="none"
      padding="p-1"
      radius="md"
      bg={props.bg ? props.bg : "default"}
      margin="none"
      >
        <div className="flex items-center justify-center">
          <span className="mx-2">{props.icon}</span>
          <Span size="base" color="white" padding="pr-2">
            {props.label}
          </Span>
        </div>
      </Button>
    </>
  );
};
