import { Span } from "@common";
import { FormInput, PasswordInput, SelectRole } from "@components";
import { Button, Drawer, Form, Popconfirm, Space, Switch, Modal } from "antd";
import { Dispatch, RootState } from "@store";
import { deleteAccount, getUserDetails, updateUserProfile } from "@api";
import "../index.scss";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

type Props = {
  getData: Function;
  id: string;
};

export const UserAdminEdit = ({ getData, id }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const [form]: any = Form.useForm();

  const [userDetails, setUserDetails] = useState({} as any);
  const [IsUsername, setIsUsername] = useState(false);
  const [isLoading, setisloading] = useState(false);
  const [password, setPassword] = useState("");
  const [isModalDelete, setIsModalDelete] = useState(false);

  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleClose = () => {
    setisloading(false);
    history.push(`/userprofile`);
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handeEditUserAdmin = async () => {
    try {
      setisloading(true);
      const res = await updateUserProfile(id, {
        ...userDetails,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully edit!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to edit user!",
        type: "Error",
      });
    }
    setIsModalDelete(false);
    handleClose();
  };

  const getUserData = async () => {
    setisloading(true);
    try {
      const res = await getUserDetails(id);
      const itemdetails = {
        first_name: res?.first_name,
        last_name: res?.last_name,
        is_active: res?.is_active,
        role_name: res?.role_name?.role_name,
      };

      setUserDetails(itemdetails);
      setIsUsername(res?.username ? true : false);

      form.setFieldsValue({
        ...itemdetails,
        username: res?.username,
        email: res?.email,
      });
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getUserData();
  }, [id]);

  const handleDelete = async () => {
    try {
      const result = await deleteAccount(id, password);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delet!",
        type: "Error",
      });
    }
    handleClose();
  };

  const valid = IsUsername && userAccess?.user_administration_update;
  const readOnly = !userAccess?.user_administration_update;

  return (
    <>
      <Drawer
        title={valid ? "Edit User Administration" : "View User Administration"}
        placement="right"
        width={500}
        onClose={handleClose}
        open={id ? true : false}
        footer={
          !readOnly ? (
            <div className="flex justify-center">
              <Space>
                <Popconfirm
                  placement="top"
                  title="Discard changes made in user administration?"
                  onConfirm={handleClose}
                  okText="Discard"
                  cancelText="Cancel"
                >
                  <Button loading={isLoading}>Cancel</Button>
                </Popconfirm>
                {valid && (
                  <Button
                    onClick={form.submit}
                    type="primary"
                    loading={isLoading}
                  >
                    Update
                  </Button>
                )}
              </Space>
            </div>
          ) : null
        }
      >
        <Form
          onFinish={handeEditUserAdmin}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
        >
          {valid && (
            <FormInput
              label="Username"
              name="username"
              required={true}
              onChange={onChange}
              disabled={true}
            />
          )}

          <FormInput
            label="Email"
            name="email"
            required={true}
            type="email"
            onChange={onChange}
            disabled={true}
          />

          <FormInput
            label="First Name"
            name="first_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />
          <FormInput
            label="Last Name"
            name="last_name"
            required={true}
            onChange={onChange}
            disabled={readOnly}
          />

          <SelectRole
            onChange={(v: any) =>
              setUserDetails({ ...userDetails, role_name: v })
            }
            disabled={readOnly}
          />

          <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
            <Span weight="semi" margin="y-auto">
              User Status
            </Span>
            <Form.Item
              style={{ margin: 0 }}
              name="is_active"
              valuePropName={userDetails?.is_active ? "checked" : ""}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={(v) =>
                  setUserDetails({
                    ...userDetails,
                    is_active: v,
                  })
                }
                disabled={readOnly}
              />
            </Form.Item>
          </div>

          {userAccess?.user_administration_delete && (
            <div className="flex justify-start my-4">
              <Button
                type="primary"
                danger
                onClick={() => setIsModalDelete(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </Form>
      </Drawer>

      <Modal
        title="Delete Account"
        open={isModalDelete}
        onCancel={() => setIsModalDelete(false)}
        footer={[
          <>
            <Button onClick={() => setIsModalDelete(false)}>Close</Button>

            <Button
              type="primary"
              danger
              onClick={handleDelete}
              disabled={!password}
            >
              Delete
            </Button>
          </>,
        ]}
      >
        <p>
          <Span>Are you sure you want to delete this account?</Span>
        </p>

        <PasswordInput
          required={true}
          label="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </>
  );
};
