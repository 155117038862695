import { Span } from "@common";
import { FormInput, Trees } from "@components";
import {
  Button,
  Drawer,
  Form,
  Popconfirm,
  Space,
  Switch,
  TreeProps,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "@store";
import { addRole } from "@api";
import { ArrayToObject, initialPermissions } from "@constant";
import { Permission } from "@types";

type Props = {
  openModal: boolean;
  setOpenModal: Function;
  getData: Function;
};

export const RoleAdd = ({ openModal, setOpenModal, getData }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const adminId = useSelector((state: RootState) => state.User.userInfo.id);
  const initialState = {
    role_name: "",
    role_description: "",
    role_status: false,
    adminId: adminId,
    access: initialPermissions,
  };

  const [permissions, setPermissions] =
    useState<Permission[]>(initialPermissions);
  const [roleDetails, setRoleDetails] = useState(initialState);
  const [isLoading, setisloading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

  const handleClose = () => {
    setisloading(false);
    setRoleDetails(initialState);
    setCheckedKeys([]);
    setOpenModal(false);
    getData();
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setRoleDetails({
      ...roleDetails,
      [name]: value,
    });
  };

  const handeAddRole = async () => {
    try {
      setisloading(true);
      const permissionsObject = ArrayToObject(permissions);
      const res = await addRole({
        ...roleDetails,
        access: permissionsObject,
      });
      if (res.success) {
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully added new user!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to add new user!",
        type: "Error",
      });
      setisloading(false);
    }
    handleClose();
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue as React.Key[]);
  };

  useEffect(() => {
    const updatedPermissions = initialPermissions.map((permission) => {
      const key = Object.keys(permission)[0];
      return { [key]: checkedKeys.includes(key) };
    });

    setPermissions(updatedPermissions);
  }, [checkedKeys]);

  return (
    <Drawer
      title="Add Role"
      placement="right"
      width={500}
      onClose={handleClose}
      open={openModal}
      footer={
        <div className="flex justify-center">
          <Space>
            <Popconfirm
              placement="top"
              title="Discard changes made in user administration?"
              onConfirm={handleClose}
              okText="Discard"
              cancelText="Cancel"
            >
              <Button loading={isLoading}>Cancel</Button>
            </Popconfirm>
            <Button onClick={form.submit} type="primary" loading={isLoading}>
              Add
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        onFinish={() => {
          form.resetFields();
          setCheckedKeys([]);
          handeAddRole();
        }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
      >
        <FormInput
          label="Role Name"
          name="role_name"
          required={true}
          onChange={onChange}
        />
        <FormInput
          label="Description"
          name="role_description"
          required={true}
          onChange={onChange}
        />

        <div className="flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded">
          <Span weight="semi">Status</Span>

          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked={roleDetails.role_status}
            onChange={(v) => setRoleDetails({ ...roleDetails, role_status: v })}
          />
        </div>

        <div className="my-4">
          <Span weight="semi">PERMISSIONS</Span>

          <Trees onCheck={onCheck} checkedKeys={checkedKeys} />
        </div>
      </Form>
    </Drawer>
  );
};