import { VoucherPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";

export const Voucher = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Voucher");
  });

  return (
    <>
      <VoucherPage />
    </>
  );
};
