import { RiImageEditFill, RiDeleteBin5Fill, MdOutlineZoomOutMap } from "@icons";
import "./voucher.scss";
import { DeleteModal } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch, RootState } from "@store";
import { deleteContent } from "@api";
import { VoucherEdit } from "../Edit/VoucherEdit"; 

type Props = {
  data: any;
  isLoading: boolean;
  setIsLoading: Function;
  getData: Function;
};

export const CardVoucher = ({
  data,
  isLoading,
  setIsLoading,
  getData,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalVoucherEdit, setIsModalVoucherEdit] = useState(false);
 
  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const result = await deleteContent(data?.content_id);
      if (result.success) {
        dispatch.UI.setAlert({
          message: result?.message ?? "Successfully deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err ?? "Unable to delete!",
        type: "Error",
      });
    }
    handleClose();
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsModalDelete(false);
    getData();
  };

  return (
    <>
      <div className="voucher-card">
        <img
          src={data?.image_path}
          alt={data?.filename}
          className="voucher-img"
        />
        <div className="voucher-menu">
          <div
            className="voucher-menu-container"
            onClick={() => setIsModalVoucherEdit(true)}
          >
            <RiImageEditFill className="voucher-icons" />
          </div>
          <div
            className="voucher-menu-container"
            onClick={() =>  userAccess?.content_delete ? setIsModalDelete(true) : ""}
          >
            <RiDeleteBin5Fill className={userAccess?.content_delete ? "voucher-icons" : "voucher-icons-disable"} />
          </div>
          <a href={data?.image_path} target="_blank">
            <div className="voucher-menu-container">
              <MdOutlineZoomOutMap className="voucher-icons" />
            </div>
          </a>
        </div>

        <DeleteModal
          title="Voucher"
          modalOpen={isModalDelete}
          cancelEvent={() => setIsModalDelete(false)}
          clickEvent={handleDelete}
          loading={isLoading}
          okLabel="Delete"
          subtitle={data?.title}
        />

        {isModalVoucherEdit && (
          <VoucherEdit
            getData={getData}
            content_id={data?.content_id}
            setIsModalVoucherEdit={setIsModalVoucherEdit}
            isModalVoucherEdit={isModalVoucherEdit}
          />
        )}
      </div>
    </>
  );
};
