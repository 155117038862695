import { CardContent, CenterContent, Span } from "@common";
import { BRAND_IMAGES } from "@constant";
import { Dispatch } from "@store";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const LogoutPage = () => {
  const [timer, setTimer] = useState(5);
  const idRef = useRef<NodeJS.Timeout | null>(null);
  const history = useHistory(); 
  
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    idRef.current = setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);

    return () => {
      if (idRef.current) {
        clearInterval(idRef.current);
      }
    };
  }, []);

  const logout = () => {
      dispatch.User.logout(); 
      history.push(`/`); 
  }

  useEffect(() => {
    if (timer === 0 && idRef.current) {
      clearInterval(idRef.current); 
      logout()
    }
  }, [timer, history]);


  return (
    <>
      <CenterContent height="screen">
        <CardContent bg="white">
          <div className="px-20 py-16">
            <img
              src={BRAND_IMAGES["BRAND_LOGO"]}
              alt="AllRewards Logo"
              className="mt-3 w-40 mb-8 m-auto"
            />
            <div>
              <Span size="3xl" weight="extrabold">
                You have signed out!
              </Span>

              <div className="text-center">
                {timer === 0 ? (
                  <Span size="base">You are now being redirected!</Span>
                ) : (
                  <Span size="base">Redirecting in {timer} seconds</Span>
                )}
              </div>
            </div>

            <div className="items-center text-center mt-8">
              <span className="items-center text-center">Go to </span>
              <span className="text-blue-primary cursor-pointer" onClick={logout}>
                Login
              </span>
            </div>
          </div>
        </CardContent>
      </CenterContent>
    </>
  );
};
