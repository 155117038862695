import { getTiersSelect } from "@api"; 
import { Form, Select } from "antd"; 
import { useEffect, useState } from "react"; 
 
type Props = { 
  onChange?: any; 
  name: string; 
  mode?: "multiple" | "tags";
  disabled?: boolean;
};  

export const SelectTier = (props: Props) => {   
    
  const [tierDetails, setTierDetails] = useState([] as any); 
    const getTiersData = async () => {
        try { 
            const res = await getTiersSelect();    
            setTierDetails(res?.results)
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => { 
        getTiersData();
    }, []);     
    return (
        <Form.Item
            label="Tier" 
            name={props?.name}
            rules={[{ required: true, message: `Tier is required` }]}
        >
            <Select
                className="w-full" 
                placeholder="Tier" 
                onChange={props?.onChange} 
                mode={props?.mode}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={props?.disabled}
            >
                {tierDetails.map((type, index) => (
                <Select.Option key={index} value={type.tier}>
                    {type.tier}
                </Select.Option>
                ))}
            </Select> 
        </Form.Item>
    );
};