import { ReportForm } from '@components'  
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { useHistory } from "react-router-dom";
import { formatDateTime, generateKeyword } from "@constant";
import { useState } from "react";
 
export const AccountReportsPage = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const dispatch = useDispatch<Dispatch>();
  const tableName = 'account_reports'  
  const history = useHistory(); 
  
  const [reportsDetails, setReportsDetails] = useState({} as any); 
  const handeReport = async () => { 
    try {  
        setIsLoading(true); 
        dispatch.Table.updatePageIndex({ table: tableName, value: 1 }); 
        dispatch.Table.updatePageLimit({ table: tableName, value: 10 });
  
        const keyword = generateKeyword(reportsDetails)

        dispatch.Table.updateKeyword({ table: tableName, value: keyword });
        dispatch.Table.getAccountReports({
            table: tableName
        }) 
      history.push(`/reports/accountList`); 
    } catch (error) {
        throw error;
    } finally {
        setIsLoading(false);
    }
};     

  return (  
    <ReportForm 
      title='Account'
      handleReport={handeReport} 
      setReportsDetails={setReportsDetails} 
      reportsDetails={reportsDetails}
      isLoading={isLoading}
      IsAccountNumber={true}
    />  
  )
}


