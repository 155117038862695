import { GridContainer, Span } from "@common";
import { Button } from "antd";
import { FaPlus } from "@icons"; 
import { useEffect, useState } from "react";
import { getContentData } from "@api";
import { CardVoucher } from "./Card/CardVoucher";
import { VoucherAdd } from "./Add/VoucherAdd";
import { useSelector } from "react-redux";
import { RootState } from "@store";

export const VoucherPage = () => {
  const [isLoading, setIsLoading] = useState(false); 
  const [voucherData, setVoucherData] = useState([]);   
  const [openModal, setOpenModal] = useState<boolean>(false)
  const bannerType = 'vouchers'   
  
  const userAccess = useSelector(
    (state: RootState) => state.User.userInfo?.role_name?.access_group_id
  );

  const showDrawer = () => {
    setOpenModal(true);
  }; 

  const getVoucher = async () => {
    try {
      setIsLoading(true); 
      const res = await getContentData(bannerType);  
      setVoucherData(res?.results)
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVoucher();
  }, []);  

  return (
    <>
      <div className="main">
        <GridContainer grid="two">
          <Span weight="bold" size="2xl">
            Vouchers
          </Span>
          {userAccess?.content_add && (
          <div className="search-bar">
            <Button
              onClick={showDrawer}
              type="primary"
              size="large"
            >
              <div className="flex pr-2 items-center">
                <FaPlus className="my-auto mx-2 text-md items-center inline-flex" />
                Add Voucher
              </div>
            </Button>
          </div>)}
        </GridContainer>

        <GridContainer grid="five" gap="gapFour">
          {voucherData?.map((data) => (
            <CardVoucher data={data} isLoading={isLoading} setIsLoading={setIsLoading} getData={getVoucher} />
          ))}
        </GridContainer> 
        
        {/* Add */}
        <VoucherAdd openModal={openModal} setOpenModal={setOpenModal} getData={getVoucher}/>
      </div>
    </>
  );
};
