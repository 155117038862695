import { Branch, Business, EarnPoints, Login, Logout, MemberManagement, PointOfSale, Profile, Promotion, RedeemPoints, StoreTransactions, Tiers } from "@pages"

class Routes {
   login: AppRoute = {
    component: Login,
    exact: true,
    key: 'login',
    name: 'Login',
    path: '/login'
   }

   logout: AppRoute = {
    component: Logout,
    exact: true,
    key: 'logout',
    name: 'Logout',
    path: '/logout'
   }

   profile: AppRoute = {
    component: Profile,
    exact: true,
    key: 'profile',
    name: 'Profile',
    path: '/profile'
   }

   branch: AppRoute = {
    component: Branch,
    exact: true,
    key: 'business-branch',
    name: 'Branch',
    path: '/business/branch'
   }

   enrollment: AppRoute = {
    component: Business,
    exact: true,
    key: 'business-enrollment',
    name: 'Enrollment',
    path: '/business/enrollment'
   }

   pos: AppRoute = {
    component: PointOfSale,
    exact: true,
    key: 'business-pos',
    name: 'POS',
    path: '/business/pos'
   }

   promotion: AppRoute = {
    component: Promotion,
    exact: true,
    key: 'promotion',
    name: 'Promotion',
    path: '/promotion'
   }

   tiers: AppRoute = {
    component: Tiers,
    exact: true,
    key: 'tiers',
    name: 'Tiers',
    path: '/tiers'
   }

   members: AppRoute = {
    component: MemberManagement,
    exact: true,
    key: 'members',
    name: 'Members',
    path: '/members'
   }

   storeTransaction: AppRoute = {
    component: StoreTransactions,
    exact: true,
    key: 'reports-store-transactions',
    name: 'Store Transaction',
    path: '/reports/storetransactions'
   }

   earnPoints: AppRoute = {
    component: EarnPoints,
    exact: true,
    key: 'reports-earn-transactions',
    name: 'Earn Points',
    path: '/reports/earnpoints'
   }

   redeemPoints: AppRoute = {
    component: RedeemPoints,
    exact: true,
    key: 'reports-redeem-points',
    name: 'Redeem Points',
    path: '/reports/redeempoints'
   }

   authorize: AppRoute[] = [
    this.profile,
    this.branch,
    this.enrollment,
    this.pos,
    this.promotion,
    this.tiers,
    this.members,
    this.storeTransaction,
    this.earnPoints,
    this.redeemPoints
   ]

   unAuthorize: AppRoute[] = [
    this.login,
    this.logout
   ]

};

interface AppRoute {
    path: string
    exact: boolean
    component: any
    name: string
    key: string
}

export const AppRoutes = new Routes();
 