import { formatDecimal } from "@constant";

export const COLUMNS = [
    {
      accessor: "transaction_account_number",
      Header: "Account Number",
    },
    {
      accessor: "total_amount",
      Header: "Total Amount",
      Cell: (data) => {
        const total_amount = data?.row?.original?.total_amount; 
        return (<>
         <span>{formatDecimal(total_amount)}</span>
        </>) 
      },
    },
    {
      accessor: "average_transaction_amount",
      Header: "Average Transaction Amount",  
      Cell: (data) => {
        const average = data?.row?.original?.average_transaction_amount; 
        return (<>
         <span>{formatDecimal(average)} </span>
        </>) 
      },
    },
    {
      accessor: "transaction_count",
      Header: "Transaction Count",
    }, 
];


export const HEADERS_STORE = [
  { label: "Account Number", key: "transaction_account_number" },
  { label: "Total Amount", key: "total_amount" },
  { label: "Average Transaction Amount", key: "average_transaction_amount" },
  { label: "Transaction Count", key: "transaction_count" }, 
];