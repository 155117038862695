import { StoreDetailsPage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";
import { useParams } from "react-router-dom";

export const StoreDetails = () => {
  let params: any = useParams();

  useEffect(() => {
    document.title = Strings.pageTitle("Store Details");
  });

  return (
    <>
      <StoreDetailsPage id={params.id} />
    </>
  );
};
