import { UserProfilePage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";
import { useParams } from "react-router-dom";

export const UserProfile = () => {
  let params: any = useParams();

  useEffect(() => {
    document.title = Strings.pageTitle("User Admin");
  });

  return (
    <>
      <UserProfilePage id={params.id} />
    </>
  );
};
