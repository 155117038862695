import {Span } from "@common";
import { FormInput, SelectBranchCode, SelectCompanyCode } from "@components";
import { useState } from "react";
import { Button, Drawer, Form, Popconfirm, Space, Switch } from "antd";
  
import { useDispatch } from "react-redux"; 
import { Dispatch } from "@store";
import { addPOS } from "@api";

type Props = { 
    openModal: boolean;
    setOpenModal: Function;
    getData: Function;
  };
  
export const POSAdd = ({openModal, setOpenModal, getData}: Props) => {
    const dispatch = useDispatch<Dispatch>();
    const [form]: any = Form.useForm(); 
    
    const initialState = {
        pos_code: "",
        branch_code: "",
        company_code: "", 
        pos_name: "",
        is_active: true,   
    };

    const [posDetails, setPOSDetails] = useState(initialState);
    const [isLoading, setisloading] = useState(false);

    const handleClose = () => { 
        setOpenModal(false)  
        setisloading(false); 
        setPOSDetails(initialState); 
        getData();
    }
    
    const onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
    
        setPOSDetails({
          ...posDetails,
          [name]: value,
        });
    };

    const handeAddPOS = async () => {
        try {
          setisloading(true); 
          const res = await addPOS({...posDetails});  
          if (res.success) { 
            dispatch.UI.setAlert({
              message: res?.message ?? "Successfully added new business!",
              type: "Success",
            }); 
          }
        } catch (err: any) { 
          dispatch.UI.setAlert({
            message: err ?? "Unable to add new business!",
            type: "Error",
          });  
        }
        handleClose()
    };  
 
    return ( 
      <Drawer
        title="Add Point of Sales"
        placement="right"
        width={500}
        onClose={handleClose} 
        open={openModal}
        footer={
          <div className="flex justify-center">
            <Space>
              <Popconfirm
                placement="top"
                title="Discard changes made in point of sales?"
                onConfirm={handleClose}
                okText="Discard"
                cancelText="Cancel"
              >
                <Button loading={isLoading}
                >
                Cancel
                </Button>
              </Popconfirm>
              <Button 
                onClick={form.submit}  
                type="primary"
                loading={isLoading}
              >
                Add
              </Button>
              </Space>
          </div>
        }
      >
        <Form
          onFinish={() => {
            form.resetFields();
            handeAddPOS();
          }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form} 
        >  
          <FormInput label="Code" name="pos_code" required={true} onChange={onChange}/> 
          <FormInput label="Name" name="pos_name" required={false} onChange={onChange}/>   
          
          <SelectCompanyCode onChange={(v: any) => setPOSDetails({ ...posDetails, company_code: v })} />  
          <SelectBranchCode onChange={(v: any) => setPOSDetails({ ...posDetails, branch_code: v })} companyCode={posDetails?.company_code}/>  
          <div className='flex justify-between mb-2 p-4 border-2 border-solid border-slate-100 rounded'>
            <Span weight='semi'>Status</Span>

            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked={posDetails.is_active}
              onChange={(v) =>
                setPOSDetails({ ...posDetails, is_active: v })
              }
            />
          </div>
        </Form>
      </Drawer>  
    )
}