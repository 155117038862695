import { ProfilePage } from "@containers";
import { useEffect } from "react";
import { Strings } from "@constant";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

export const Profile = () => {
  useEffect(() => {
    document.title = Strings.pageTitle("Profile");
  });

  const dispatch = useDispatch<Dispatch>();
  const userInfo = useSelector((state: RootState) => state.User.userInfo);
  const isNewLogin = useSelector((state: RootState) => state.UI.isNewLogin);

  useEffect(() => {
    if (isNewLogin) {
      dispatch.UI.setAlert({
        message: `Successfully login, ${userInfo?.username}!`,
        type: "Success",
      });
      dispatch.UI.updateState({ isNewLogin: false });
    }
  }, [isNewLogin]);

  return (
    <>
      <ProfilePage />
    </>
  );
};
